import { Div, Text, Input } from "atomize";
import React from "react";
import COLORS from "../../Themes/Colors";
import "./style.css";

export const InputText = ({
  type,
  id,
  placeholder,
  onChange,
  caption,
  value,
  error,
  disabled = false,
}) => {
  return (
    <Div m={{ y: "1.5rem" }}>
      <Div className="input-container">
        <Text className="input-label" textSize="caption">
          {placeholder}
        </Text>
        <Input
          value={value || ""}
          className="input-field"
          type={type}
          id={id}
          placeholder={placeholder}
          onChange={onChange}
          disabled={disabled}
        />
      </Div>
      <Text textSize="tiny" textColor={COLORS.servicePartnerPrimary}>
        {caption}
      </Text>
      <Text textSize="tiny" textColor="danger800">
        {error}
      </Text>
    </Div>
  );
};

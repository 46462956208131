import React from "react";
import { Button as ButtonAtom, Image, Div } from "atomize";
import { Label } from "../../components";
import "./Button.css";
import Colors from "../../Themes/Colors";
const customStyle = {
  primary: {
    bg: Colors.primaryColor,
    hoverBg: "#a4c5f4",
    rounded: "sm",
  },
  secondary: {
    bg: Colors.white,
    border: "1px solid",
    borderColor: Colors.textMainColor,
    hoverBg: "info200",
    rounded: "sm",
  },
};

const SecondaryText = ({ label, label2, textType, buttonType }) => (
  <Div d="flex" align="center">
    <span
      className="b-2-l-1"
      style={{
        color: textType === "menu" ? Colors.primaryColor : Colors.textMainColor,
      }}
    >
      {label} <span className="b-2-l-2">{label2}</span>
    </span>
    {buttonType && (
      <Image
        src={require("../../assets/images/sort.png")}
        maxW="13px"
        maxH="13px"
        m={{ l: "5px" }}
      />
    )}
  </Div>
);
const PrimaryText = ({ label, textType }) => (
  <Label
    type={textType === "bold" ? "buttonText" : "buttonText2"}
    label={label}
    style={{ textColor: Colors.white, className: "primary" }}
  />
);
const Button = ({
  type,
  label,
  label2,
  textType,
  style,
  onPress,
  buttonType,
  disabled = false
}) => {
  return (
    <ButtonAtom w="100%" {...customStyle[type]} {...style} onClick={onPress} disabled={disabled}>
      {type === "primary" ? (
        <PrimaryText {...{ label, textType }} />
      ) : (
        <SecondaryText {...{ label, label2, textType, buttonType }} />
      )}
    </ButtonAtom>
  );
};

export default Button;

export const signupFormValidate = values => {
    let numbers = /^[0-9]+$/;
    const errors = {};
    if (!values.userName) {
        errors.userName = 'Required';
    }
    if (!values.phoneNumber.match(numbers) || values.phoneNumber.length !== 10) {
        errors.phoneNumber = 'Please enter your 10 digit mobile number.'
    }
    if (values.password.length < 8) {
        errors.password = 'Password requires minimum 8 characters';
    }
    if (values.confirmPassword.length < 8) {
        errors.confirmPassword = 'Password requires minimum 8 characters';
    } else if (values.password !== values.confirmPassword) {
        errors.confirmPassword = 'Passwords do not match';
    }
    return errors;
};

export const validateEmail = (email) => {
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (!pattern.test(email)) {
      console.log("invalid email");
      return false;
    } else {
      console.log("valid email");
      return true;
    }
  };
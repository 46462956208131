import React from "react";
import { Div, Row, Col, Text, Image, Modal, Icon } from "atomize";
import { LinearProgress } from "@material-ui/core";

import { PersonalDetailsBlock } from "./Detail Blocks/PersonalDetails";
import Colors from "../../Themes/Colors";
import { NSButton } from "../../components/ServicePartner/Button";
import { AddressDetails } from "./Detail Blocks/AddressDetails";
import { ContactDetails } from "./Detail Blocks/ContactDetails";
import { KycDetails } from "./Detail Blocks/KycDetails";
import { AgencyLicenceDetails } from "./Detail Blocks/AgencyLicence";
import { ServiceProfileDetails } from "./Detail Blocks/ServiceProfileDetails";
import {
  BASE_URL,
  getPreviewDetailsAsync,
  sendForVerification,
} from "./Api/api";
import { useHistory } from "react-router-dom";
import { store } from "./context/store";
import { ArrowLeftOutlined } from "@ant-design/icons";
export const PreviewDetails = () => {
  const [previewDetails, setPreviewDetails] = React.useState(() => undefined);
  const [fetchDetailsApiStatus, setFetchDetailsApiStatus] = React.useState(
    () => ({
      error: "",
      loading: false,
    })
  );
  const [showModal, setShowModal] = React.useState(() => false);
  const [sendForVerifyStatus, setSendForVerifyStatus] = React.useState(() => ({
    error: "",
    loading: false,
  }));

  const history = useHistory();
  const { state, dispatch } = React.useContext(store);

  React.useEffect(() => {
    setFetchDetailsApiStatus({ error: "", loading: true });
    if (state.partyID) {
      async function getPreviewDetails() {
        const getPreviewDetails = await getPreviewDetailsAsync(state.partyID);
        if (getPreviewDetails.isSuccess && getPreviewDetails.data) {
          setPreviewDetails(getPreviewDetails.data);
          setFetchDetailsApiStatus({
            error: "",
            loading: false,
            message: "",
          });
        } else {
          setFetchDetailsApiStatus({
            error: "Unable to fetch details. Try again",
            loading: false,
          });
        }
      }
      getPreviewDetails();
    }
  }, [state.partyID]);
  if (fetchDetailsApiStatus.loading) {
    return <LinearProgress />;
  }
  if (fetchDetailsApiStatus.error || !previewDetails) {
    return (
      <Text
        textAlign="center"
        textSize="title"
        textColor={"red"}
        m={{ t: "0.8em" }}
      >
        {fetchDetailsApiStatus.error || "Unable to fetch details"}
      </Text>
    );
  }

  const onClickSubmit = async () => {
    setSendForVerifyStatus({ error: "", loading: true, message: "" });
    const payload = {
      party: state.partyID,
    };
    const sendForVerifictionStatus = await sendForVerification(payload);
    if (sendForVerifictionStatus.isSuccess) {
      setSendForVerifyStatus({
        error: "",
        loading: false,
        message: sendForVerifictionStatus.data,
      });
      setShowModal(true);
    } else {
      setSendForVerifyStatus({
        error: "Unable to submit. Try again",
        loading: false,
        message: "",
      });
    }
  };
  const onCloseModal = () => {
    history.push("/servicepartner");
  };
  const onClickBack = () => {
    dispatch({
      type: "setLastStep",
      payload: 0,
    });
    history.push("/servicepartner/form");
  };

  const getPersonalDetails = () => {
    return {
      partyID: previewDetails.code,
      firstName:
        previewDetails.personalDetails &&
        previewDetails.personalDetails.first_name,
      lastName:
        previewDetails.personalDetails &&
        previewDetails.personalDetails.last_name,
      serviceCategory:
        previewDetails.serviceCategory && previewDetails.serviceCategory,
      enrolDate:
        previewDetails.personalDetails &&
        previewDetails.personalDetails.created_at,
    };
  };
  const getAddressDetails = () => {
    return {
      country:
        previewDetails.address.length &&
        previewDetails.address[0].city__state__country__name,
      pincode:
        previewDetails.address.length &&
        previewDetails.address[0].city__pincode,
      buildingNo:
        previewDetails.address.length && previewDetails.address[0].building_no,
      street: previewDetails.address.length && previewDetails.address[0].street,
      city:
        previewDetails.address.length && previewDetails.address[0].city__name,
      state:
        previewDetails.address.length &&
        previewDetails.address[0].city__state__name,
    };
  };
  const getServiceDetails = () => {
    return {
      serviceCategory:
        previewDetails.serviceCategory.length &&
        previewDetails.serviceCategory[0],
      area:
        previewDetails.serviceProfile.length &&
        previewDetails.serviceProfile[0].city__name,
      lob: (state.serviceCategory === 2) ? previewDetails.insureLobs : previewDetails.claimLobs,
      companies: (state.serviceCategory === 2) ? previewDetails.comapaniesRepresent : previewDetails.servicesProvided,
    };
  };

  const getContactDetails = () => {
    return {
      email: previewDetails.email.length && previewDetails.email[0],
      whatsApp: previewDetails.whatsapp.length && previewDetails.whatsapp[0],
      phonenumber: previewDetails.phone.length && previewDetails.phone[0],
    };
  };
  const getAgencyDetails = () => {
    return {
      licenceNumber:
        previewDetails.agencyLicence &&
        previewDetails.agencyLicence.licence_number,
      regAuthority:
        previewDetails.agencyLicence &&
        previewDetails.agencyLicence.reg_authority,
      issueDate:
        previewDetails.agencyLicence &&
        previewDetails.agencyLicence.date_of_issue,
      expDate:
        previewDetails.agencyLicence &&
        previewDetails.agencyLicence.date_of_expiry,
    };
  };
  const getKycDetails = () => {
    return {
      documentNumber: previewDetails.kyc.length && previewDetails.kyc[0].number,
      documentType:
        previewDetails.kyc.length && previewDetails.kyc[0].document_type__name,
    };
  };
  const getProfilePicture = () => {
    if (
      previewDetails.personalDetails &&
      previewDetails.personalDetails.photo
    ) {
      return `${BASE_URL}${previewDetails.personalDetails.photo}`;
    }
    return require("../../assets/images/avatar.png");
  };
  const getLicenceFile = () => {
    const agencyDetails = previewDetails.agencyLicence;
    if (agencyDetails && agencyDetails.licence_copy) {
      return (
        <Div className="ns-uploaded-file" m={{ t: "2em" }}>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={`${BASE_URL}${agencyDetails.licence_copy}`}
          >
            <img
              src={require("../../assets/images/document-preview.png")}
              alt="licence-file"
            />
            Licence
          </a>
        </Div>
      );
    }
    return "";
  };
  const getKYCFile = () => {
    const kycDetails = previewDetails.kyc;
    if (kycDetails.length && kycDetails[0].name) {
      return (
        <Div className="ns-uploaded-file" m={{ t: "1em" }}>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={`${BASE_URL}${kycDetails[0].name}`}
          >
            <img
              src={require("../../assets/images/document-preview.png")}
              alt="licence-file"
            />
            {kycDetails[0].document_type__name || "KYC Document"}
          </a>
        </Div>
      );
    }
    return "";
  };

  return (
    <Row>
      <Col size={12} textAlign="center" p={{ b: "2em" }}>
        <Text
          textSize={{ xs: "heading", lg: "heading" }}
          textColor={Colors.servicePartnerPrimary}
        >
          Your profile details
        </Text>
      </Col>
      <Col size={12}>
        <Row justify="space-between">
          <Col size={8}>
            <PersonalDetailsBlock {...getPersonalDetails()} />
            <AddressDetails {...getAddressDetails()} />
            <ContactDetails {...getContactDetails()} />
            <ServiceProfileDetails {...getServiceDetails()} />
            {state.serviceCategory === 2 && (
              <AgencyLicenceDetails {...getAgencyDetails()} />
            )}
            <KycDetails {...getKycDetails()} />
          </Col>
          <Col size={3}>
            <Image maxW={"200px"} p={10} src={getProfilePicture()} />
            {getLicenceFile()}
            {getKYCFile()}
          </Col>
          <Col size={12} p={{ t: "2em" }}>
            <Row justify="center" style={{ display: "flex", justifyContent: "space-evenly" }}>
              <Col size={3}>
                <NSButton
                  btntype="secondary"
                  iconposition="left"
                  minW="100%"
                  onClick={onClickBack}
                >
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div style={{ background: "#d5d8dc", padding: "7px", height: "40px", textAlign: "center", width: "40%", marginLeft: "-16px", borderRadius: "3px" }}> <ArrowLeftOutlined /> </div>
                    <div style={{ marginRight: "15px" }}>
                      Back</div>
                  </div>
                </NSButton>
              </Col>
              <Col size={3}>
                <NSButton
                  loading={sendForVerifyStatus.loading}
                  btntype="primary"
                  minW="100%"
                  onClick={onClickSubmit}
                >
                  Submit
                </NSButton>
              </Col>
            </Row>
            <Text
              textSize="caption"
              textAlign="center"
              textColor={"red"}
              m={{ t: "0.8em" }}
            >
              {sendForVerifyStatus.error}
            </Text>
            <Modal
              isOpen={showModal}
              onClose={onCloseModal}
              align="center"
              rounded="md"
            >
              <Div d="flex" m={{ b: "4rem" }}>
                <Icon
                  name="Success"
                  color="success700"
                  m={{ t: "0.35rem", r: "0.5rem" }}
                />
                <Text p={{ l: "0.5rem", t: "0.25rem" }} textSize="subheader">
                  {sendForVerifyStatus.message}
                </Text>
              </Div>
              <Div d="flex" justify="flex-start">
                <NSButton onClick={onCloseModal} btntype="primary">
                  Go to home
                </NSButton>
              </Div>
            </Modal>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

import React from "react";
import { Div, Row, Col, Text, Image, Label, Radiobox } from "atomize";
import { Formik } from "formik";
//import { useHistory } from "react-router";
import LinearProgress from "@material-ui/core/LinearProgress";

import { NSButton } from "../../components/ServicePartner/Button";
import { InputText } from "../../components/ServicePartner/InputText";
import Colors from "../../Themes/Colors";
import { NSBanner } from "../../components/ServicePartner/Banner";
import { store } from "./context/store";
import { MEDIA_URL, createProfileAsync } from "./Api/api";
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";
export const PersonalDetails = (props) => {
  const [profileImage, setProfileImage] = React.useState(() => undefined);
  const [profileData, setProfileData] = React.useState(() => { });
  const [errorMessage, setErrorMessage] = React.useState(undefined);
  const [apiFailed, setApiFailed] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const { state } = React.useContext(store);

  React.useEffect(() => {
    if (state.partyID) {
      const payload = new FormData();
      payload.append("party", state.partyID);
      async function getProfile() {
        const getProfileDetails = await createProfileAsync(payload);
        if (getProfileDetails.isSuccess) {
          if (getProfileDetails.data.first_name) {
            setProfileData(getProfileDetails.data);
          } else {
            payload.append("first_name", state.fullName);
            getProfile();
          }
          if (getProfileDetails.data.photo) {
            setProfileImage(`${MEDIA_URL}${getProfileDetails.data.photo}`);
          }
        } else {
          setApiFailed(true);
          setProfileData({});
        }
      }
      getProfile();
    } else {
      setApiFailed(true);
    }
  }, [state.fullName, state.partyID]);

  const onChangeProfileUpload = (e) => {
    setProfileImage(e.currentTarget.files[0]);
  };
  const onClickBack = () => {
    props.onClickBack();
  };

  const onSubmit = async (values, actions) => {
    setLoading(true);
    let validationError = false;
    if (!values.first_name) {
      validationError = true;
      setErrorMessage("Please enter a valid first name");
    } else if (!values.last_name) {
      validationError = true;
      setErrorMessage("Please enter a valid last name");
    } else if (!values.gender) {
      validationError = true;
      setErrorMessage("Please select a gender");
    } else if (!profileImage) {
      validationError = true;
      setErrorMessage("Please upload a profile image");
    }
    if (!validationError) {
      const payload = new FormData();
      payload.append("first_name", values.first_name);
      payload.append("last_name", values.last_name);
      payload.append("middle_name", values.middle_name);
      payload.append("gender", values.gender);
      typeof profileImage === "object" && payload.append("photo", profileImage);
      payload.append("party", state.partyID);
      const getProfileDetails = await createProfileAsync(payload);
      setLoading(false);
      if (getProfileDetails.isSuccess) {
        props.onClickNext();
      } else {
        setErrorMessage("Failed to update profile. Try again");
      }
    } else {
      setLoading(false);
    }
  };

  const getProfilePicture = () => {
    switch (typeof profileImage) {
      case "string":
        return profileImage;
      case "object":
        return URL.createObjectURL(profileImage);
      default:
        return require("../../assets/images/avatar.png");
    }
  };
  if (apiFailed) {
    return (
      <Text
        textAlign="center"
        textSize="title"
        textColor={"red"}
        m={{ t: "0.8em" }}
      >
        Failed to fetch data. Try again.
      </Text>
    );
  }
  if (!profileData) {
    return <LinearProgress />;
  }

  return (
    <Row>
      <Col size={12}>
        <NSBanner
          partyID={state.partyID}
          serviceCategory={state.serviceCategory}
        />
      </Col>
      <Col size={{ xs: 12, lg: 6 }} order={{ xs: 2, lg: 1 }}>
        <Div p={{ x: "1rem" }}>
          <Text
            textSize={{ xs: "heading", lg: "heading" }}
            textColor={Colors.servicePartnerText}
          >
            Personal Details
          </Text>
          <Formik initialValues={profileData} onSubmit={onSubmit}>
            {(props) => (
              <form onSubmit={props.handleSubmit}>
                <Row>
                  <Col size={12}>
                    <Row>
                      <Col size={{ xs: 12, lg: 7 }} order={{ xs: 2, lg: 1 }}>
                        <Div>
                          <InputText
                            value={props.values?.first_name}
                            id="first_name"
                            placeholder="Enter first name"
                            onChange={props.handleChange}
                            error={
                              props.touched.first_name &&
                              props.errors.first_name
                            }
                          />
                          <InputText
                            value={
                              (typeof (props.values.middle_name) === 'undefined' || props.values.middle_name === 'null')
                                ? ''
                                : props.values.middle_name
                            }
                            id="middle_name"
                            placeholder="Enter middle name"
                            onChange={props.handleChange}
                            error={
                              props.touched.middle_name &&
                              props.errors.middle_name
                            }
                          />
                          <InputText
                            value={
                              (typeof (props.values.last_name) === 'undefined' || props.values.last_name === 'null')
                                ? ''
                                : props.values.last_name
                            }
                            id="last_name"
                            placeholder="Enter last name"
                            onChange={props.handleChange}
                            error={
                              props.touched.last_name && props.errors.last_name
                            }
                          />
                          <Text
                            textSize={"subheader"}
                            textColor={Colors.servicePartnerText}
                            m={{ b: "1em" }}
                          >
                            Gender
                          </Text>
                          <Div d="flex" m={{ b: "3em" }}>
                            <Label
                              align="center"
                              textWeight="400"
                              m={{ r: "2em" }}
                            >
                              <Radiobox
                                value="M"
                                name="gender"
                                onChange={props.handleChange}
                                checked={props.values?.gender === "M"}
                              />
                              Male
                            </Label>
                            <Label
                              align="center"
                              textWeight="400"
                              m={{ r: "0.5rem" }}
                            >
                              <Radiobox
                                value="F"
                                name="gender"
                                onChange={props.handleChange}
                                checked={props.values.gender === "F"}
                              />
                              Female
                            </Label>
                          </Div>
                        </Div>
                      </Col>
                      <Col
                        size={{ xs: 12, lg: 4 }}
                        order={{ xs: 1, lg: 2 }}
                        p={{ xs: "0", lg: "2em 0em 2em 2em" }}
                      >
                        <input
                          accept="image/*"
                          className="d-none"
                          id="contained-button-file"
                          multiple
                          type="file"
                          onChange={onChangeProfileUpload}
                        />
                        <NSButton
                          btntype="secondary"
                          h={"auto"}
                          className="ns-upload-button p-0 overflow-hidden"
                        >
                          <label htmlFor="contained-button-file">
                            <div
                              className={
                                profileImage ? "" : "ns-upload-btn-image"
                              }
                            >
                              <Image p={10} src={getProfilePicture()} />
                            </div>
                            Upload profile picture
                          </label>
                        </NSButton>
                      </Col>
                    </Row>
                  </Col>
                  <Col size={6}>
                    <NSButton
                      btntype="secondary"
                      iconposition="left"
                      style={{ width: "100%", borderRadius: "3px" }}
                      // icon={<Icon name="LongLeft" size="16px" color="black" />}
                      // minW="50%"
                      onClick={onClickBack}
                    >
                      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <div style={{ background: "#d5d8dc", padding: "7px", height: "40px", textAlign: "center", width: "33%", marginLeft: "-16px", borderRadius: "3px" }}> <ArrowLeftOutlined /> </div>
                        <div style={{ marginRight: "20px" }}>
                          Back</div>
                      </div>
                    </NSButton>
                  </Col>
                  <Col size={6}>
                    <NSButton
                      style={{ width: "100%", borderRadius: "3px" }}
                      loading={loading}
                      btntype="submit"
                      // icon={<Icon name="LongRight" size="16px" color="white" />}
                      minW="50%"
                    //   onClick={onClickNext}
                    >
                      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <div style={{ marginLeft: "20px" }}>
                          {" "}
                          Next{" "}</div> <div style={{ background: "#3193ff", padding: "7px", height: "40px", textAlign: "center", width: "33%", marginRight: "-16px", borderRadius: "3px" }}> <ArrowRightOutlined /> </div>
                      </div>
                    </NSButton>
                  </Col>
                  <Text textSize="caption" textColor={"red"} m={{ t: "0.8em" }}>
                    {errorMessage}
                  </Text>
                </Row>
              </form>
            )}
          </Formik>
        </Div>
      </Col>
      <Col
        m={{ xs: 0, lg: { t: "-10em" } }}
        size={{ xs: 12, lg: 6 }}
        order={{ xs: 1, lg: 2 }}
      >
        <Div p="1rem">
          <Image src={require("../../assets/images/Frame63.png")} />
        </Div>
      </Col>
    </Row>
  );
};

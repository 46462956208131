import styled from 'styled-components';
import React from 'react'
import { DatePicker } from 'antd';
import { Text, Div } from "atomize";


export const CustomDatePicker = styled(props => (<DatePicker {...props} />))`
.ant-picker-input {
    margin-top: 7px;
}
`;

export const NSDatePicker = ({ label, onChange, value }) => {
    return (
        <Div className="input-container"m={{ y: '1.5rem' }}>
            <Text className="input-label" textSize="caption">
                {label}
            </Text>
            <CustomDatePicker onChange={onChange} value={value} placeholder="" style={{ width: '100%' }} />
        </Div>
    )
}
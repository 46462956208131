import React, { Fragment } from "react";
import { Div, Row, Col, Text, Image } from "atomize";
import { LinearProgress } from "@material-ui/core";
import { MenuItem, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { NSButton } from "../../components/ServicePartner/Button";
import { InputText } from "../../components/ServicePartner/InputText";
import { InputSelect } from "../../components/ServicePartner/InputSelect";
import Colors from "../../Themes/Colors";
import { NSBanner } from "../../components/ServicePartner/Banner";
import { store } from "./context/store";
import {
  getOrSaveCommunicationDetails,
  getServiceStatesAsync,
  //getServiceCitiesAsync,
  getCitiesFromState,
  getPincodeAsync,
} from "./Api/api";
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";
export const AddressDetails = (props) => {
  const [communicationDetails, setCommunicationDetails] = React.useState(() => {
    return {
      building_no: "",
      street: "",
      city: undefined,
    };
  });
  const [serviceCities, setServiceCities] = React.useState(() => ({
    data: [],
    loading: false,
    error: "",
  }));
  const [serviceStates, setServiceStatesList] = React.useState(() => ({
    data: [],
    loading: false,
    error: "",
  }));
  const [selectedState, setSelectedState] = React.useState(() => undefined);
  const [selectedPincode, setSelectedPincode] = React.useState(() => ({
    data: "",
    loading: false,
    error: "",
  }));
  const [fetchDetailsApiStatus, setFetchDetailsApiStatus] = React.useState(
    () => ({
      error: "",
      loading: false,
    })
  );
  const [saveDetailsApiStatus, setSaveDetailsApiStatus] = React.useState(
    () => ({
      error: "",
      loading: false,
    })
  );

  const [inputValue, setInputValue] = React.useState('');
  const [err, seterr] = React.useState("")

  const { state } = React.useContext(store);

  React.useEffect(() => {
    setFetchDetailsApiStatus({ error: "", loading: true });
    const payload = {
      party: state.partyID,
    };
    const getStatePayload = {
      country_id: 1,
    };
    function getCommunicationDetails() {
      const getCommunicationDetails = getOrSaveCommunicationDetails(payload);
      setServiceStatesList((prevState) => ({ ...prevState, loading: true }));
      const getServiceStatesPromise = getServiceStatesAsync(getStatePayload);
      Promise.all([getServiceStatesPromise, getCommunicationDetails]).then(
        (responses) => {
          if (responses[0].isSuccess) {
            setServiceStatesList({
              data: responses[0].data,
              error: "",
              loading: false,
            });
          } else {
            setServiceStatesList({
              data: [],
              error: "Unable to fetch data",
              loading: false,
            });
          }
          if (responses[1].isSuccess) {
            setCommunicationDetails(responses[1].data);
            setSelectedState(responses[1].data.state);
            setFetchDetailsApiStatus({ error: "", loading: false });
          } else {
            setFetchDetailsApiStatus({
              error: "Unable to fetch details. Try again",
              loading: false,
            });
          }
        }
      );
    }
    getCommunicationDetails();
  }, [state.partyID]);

  // React.useEffect(() => {
  //   if (getCityChange) {
  //     const getCities = async () => {
  //       setServiceCities({ data: [], error: "", loading: true });
  //       const payload = {
  //         state_id: selectedState,
  //       };
  //       try {
  //         const citiesResponse = await getServiceCitiesAsync(payload);
  //         if (citiesResponse.isSuccess) {
  //           setServiceCities({
  //             data: citiesResponse.data,
  //             error: "",
  //             loading: false,
  //           });
  //         } else {
  //           setServiceCities({
  //             data: [],
  //             error: "Failed to fetch cities",
  //             loading: false,
  //           });
  //         }
  //       } catch {
  //         setServiceCities({
  //           data: [],
  //           error: "Failed to fetch cities",
  //           loading: false,
  //         });
  //       }
  //     };
  //     getCities();
  //   }
  // }, [getCityChange]);

  React.useEffect(() => {
    if (communicationDetails.city) {
      const getPincode = async () => {
        const payload = {
          city_id: communicationDetails.city,
        };
        setSelectedPincode({ data: "", error: "", loading: true });
        try {
          const pincodeResponse = await getPincodeAsync(payload);
          if (pincodeResponse.isSuccess) {
            setSelectedPincode({
              data:
                pincodeResponse.data.length > 0
                  ? pincodeResponse.data[0].pincode
                  : "",
              error: "",
              loading: false,
            });
            setInputValue(pincodeResponse.data[0].name + ', ' + pincodeResponse.data[0].taluk + ', ' + pincodeResponse.data[0].district + ', ' + pincodeResponse.data[0].pincode)
          } else {
            setSelectedPincode({
              data: "",
              error: "Failed to fetch cities",
              loading: false,
            });
          }
        } catch {
          setSelectedPincode({
            data: [],
            error: "Failed to fetch cities",
            loading: false,
          });
        }
      };
      getPincode();
    }
  }, [communicationDetails.city]);

  const onClickBack = () => {
    props.onClickBack();
  };

  const onChangeBuilding = (e) => {
    setCommunicationDetails({
      ...communicationDetails,
      building_no: e.currentTarget.value,
    });
  };
  const onChangeStreet = (e) => {
    setCommunicationDetails({
      ...communicationDetails,
      street: e.currentTarget.value,
    });
  };

  // const onChangeCity = async (e) => {
  //   setCommunicationDetails({
  //     ...communicationDetails,
  //     city: e.target.value,
  //   });
  //   if (e.target.value) {
  //   }
  // };

  const onChangeCity = (e, value, reason) => {
    if (reason === "select-option") {
      setCommunicationDetails({
        ...communicationDetails,
        city: value.id,
      });
      setInputValue(value.name + ', ' + value.taluk + ', ' + value.district + ', ' + value.pincode)
    }
    if (reason === "clear") {
      setCommunicationDetails({
        ...communicationDetails,
        city: "",
      });
      setInputValue('')
    }
  };

  const onChangeState = async (e) => {
    setSelectedState(e.target.value);
    setServiceCities({
      data: [],
      loading: false,
      error: "",
    })
    setCommunicationDetails({
      ...communicationDetails,
      city: "",
    });
    setInputValue('')
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!selectedState) {
      setSaveDetailsApiStatus({
        error: "Select a state",
        loading: false,
      });
      return;
    }
    if (!communicationDetails.city) {
      setSaveDetailsApiStatus({
        error: "Select a city",
        loading: false,
      });
      return;
    }
    if (!communicationDetails.street) {
      setSaveDetailsApiStatus({
        error: "Please fill street address",
        loading: false,
      });
      return;
    }
    if (!communicationDetails.building_no) {
      setSaveDetailsApiStatus({
        error: "Please fill building number",
        loading: false,
      });
      return;
    }
    if (!selectedPincode.data) {
      setSaveDetailsApiStatus({
        error: "Please fill pincode / Select a state",
        loading: false,
      });
      return;
    }

    setSaveDetailsApiStatus({ error: "", loading: true });
    const communicationPayload = {
      party: state.partyID,
      city: communicationDetails.city,
      building_no: communicationDetails.building_no,
      street: communicationDetails.street,
    };
    const saveCommunicationDetails =
      getOrSaveCommunicationDetails(communicationPayload);
    Promise.all([saveCommunicationDetails]).then((responses) => {
      if (responses[0].isSuccess) {
        setSaveDetailsApiStatus({ error: "", loading: false });
        props.onClickNext();
      } else {
        setSaveDetailsApiStatus({
          error: "Unable to update details. Try again",
          loading: false,
        });
      }
    });
  };

  const getStateOptions = () => {
    return serviceStates.data.map((aState) => {
      return (
        <MenuItem key={`state-options-${aState.id}`} value={aState.id}>
          {aState.name}
        </MenuItem>
      );
    });
  };

  // const getCitiesOptions = () => {
  //   return serviceCities.data.map((aCity) => {
  //     return (
  //       <MenuItem key={`state-options-${aCity.id}`} value={aCity.id}>
  //         {aCity.name}
  //       </MenuItem>
  //     );
  //   });
  // };


  const onSearchLocation = (e, value, reason) => {
    if (selectedState) {
      seterr("")
      if (reason === "input") {
        e.preventDefault()
        setInputValue(value);
        if (value.length >= 2) {
          const getCities = async () => {
            setServiceCities({ data: [], error: "", loading: true });
            const payload = {
              state_id: selectedState,
              name: value
            };
            try {
              const citiesResponse = await getCitiesFromState(payload);
              if (citiesResponse.isSuccess) {
                setServiceCities({
                  data: citiesResponse.data,
                  error: "",
                  loading: false,
                });
              } else {
                setServiceCities({
                  data: [],
                  error: "Failed to fetch cities",
                  loading: false,
                });
              }
            } catch {
              setServiceCities({
                data: [],
                error: "Failed to fetch cities",
                loading: false,
              });
            }
          };
          getCities()
        }
      }
    } else {
      seterr("Please select a state")
    }
  };

  if (fetchDetailsApiStatus.error) {
    return (
      <Text
        textAlign="center"
        textSize="title"
        textColor={"red"}
        m={{ t: "0.8em" }}
      >
        {fetchDetailsApiStatus.error}
      </Text>
    );
  }

  if (fetchDetailsApiStatus.loading) {
    return <LinearProgress />;
  }

  return (
    <Row>
      <Col size={12}>
        <NSBanner />
      </Col>
      <Col size={{ xs: 12, lg: 6 }} order={{ xs: 2, lg: 1 }}>
        <Div p={{ x: "1rem" }}>
          <Text
            textSize={{ xs: "heading", lg: "heading" }}
            textColor={Colors.servicePartnerText}
          >
            Address Details
          </Text>
          <form onSubmit={onSubmit}>
            <Row>
              <Col size={12} p={{ b: "2em" }}>
                <InputText
                  value={"India"}
                  placeholder="Country"
                  disabled={true}
                />
                {serviceStates.loading ? (
                  <LinearProgress />
                ) : (
                  <InputSelect
                    value={selectedState}
                    id="state"
                    placeholder="State"
                    options={getStateOptions()}
                    onChange={onChangeState}
                  />
                )}
                {/* {serviceCities.loading ? (
                  <LinearProgress />
                ) : ( */}
                <Autocomplete
                  value={communicationDetails.city}
                  onChange={onChangeCity}
                  inputValue={inputValue}
                  onInputChange={onSearchLocation}
                  id="middleName"
                  placeholder="Search City"
                  size="small"
                  clearText={() => setInputValue('')}
                  options={serviceCities.data}
                  renderOption={option => <Fragment>{option.name}, {option.taluk}, {option.district}, {option.pincode}</Fragment>}
                  getOptionLabel={option => option.name || ""}
                  renderInput={(params) => <TextField {...params} variant="outlined" fullWidth label="Search City" />}
                />
                <Text textSize="caption" textColor={"red"} m={{ t: "0.8em" }}>
                  {err && err}
                </Text>
                {/* )} */}
                <InputText
                  value={communicationDetails.street}
                  placeholder="Street Address"
                  onChange={onChangeStreet}
                />
                <InputText
                  value={communicationDetails.building_no}
                  placeholder="Building / Door Number"
                  onChange={onChangeBuilding}
                />
                {selectedPincode.loading ? (
                  <LinearProgress />
                ) : (
                  <InputText
                    inputProps={{ readOnly: true }}
                    value={selectedPincode.data}
                    placeholder="Postal / Zip Code"
                  />
                )}
              </Col>
              <Col size={6} p={{ l: "0", r: "1em" }}>
                <NSButton
                  style={{ width: "100%", borderRadius: "3px" }}
                  btntype="secondary"
                  iconposition="left"
                  // icon={<Icon name="LongLeft" size="16px" color="black" />}
                  minW="100%"
                  onClick={onClickBack}
                >
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div style={{ background: "#d5d8dc", padding: "7px", height: "40px", textAlign: "center", width: "33%", marginLeft: "-16px", borderRadius: "3px" }}> <ArrowLeftOutlined /> </div>
                    <div style={{ marginRight: "20px" }}>
                      Back</div>
                  </div>
                </NSButton>
              </Col>
              <Col size={6} p={{ l: "1em", r: "0em" }}>
                <NSButton
                  style={{ width: "100%", borderRadius: "3px" }}
                  btntype="submit"
                  // icon={<Icon name="LongRight" size="16px" color="white" />}
                  minW="100%"
                  loading={saveDetailsApiStatus.loading}
                >
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div style={{ marginLeft: "20px" }}>
                      {" "}
                      Next{" "}</div> <div style={{ background: "#3193ff", padding: "7px", height: "40px", textAlign: "center", width: "33%", marginRight: "-16px", borderRadius: "3px" }}> <ArrowRightOutlined /> </div>
                  </div>
                </NSButton>
              </Col>
              <Text textSize="caption" textColor={"red"} m={{ t: "0.8em" }}>
                {saveDetailsApiStatus.error}
              </Text>
            </Row>
          </form>
        </Div>
      </Col>
      <Col
        m={{ xs: 0, lg: { t: "-10em" } }}
        size={{ xs: 12, lg: 6 }}
        order={{ xs: 1, lg: 2 }}
      >
        <Div p="1rem">
          <Image src={require("../../assets/images/Frame65.png")} />
        </Div>
      </Col>
    </Row>
  );
};

import React from "react";
import { Div } from "atomize";
import { Route, Switch, Redirect } from "react-router-dom";

import { Landing } from "./Landing";
// import './style.css'
import { SignUp } from "./SignUp";
import { BaseForm } from "./BaseForm";
import { Login } from "./Login";
import { ServicePartnerStateProvider } from "./context/store";
import { store } from "./context/store";
import { PreviewDetails } from "./PreviewDetails";
import ToolbarForForm from "../../components/Toolbar/ToolbarForForm";
import ToolbarForm from "../../components/Toolbar/ToolbarForm"

const ProtectedRoute = ({ component, ...rest }) => {
  const globalState = React.useContext(store);

  //if (!globalState.state.isLoggedIn) {
  if (!globalState.state.partyID) {
    if (!globalState.state.isLoggedIn) {
      return <Redirect to="/servicepartner" />;
    }
  }
  return <Route {...rest} component={component} />;
};

const ServicePartner = () => {
  return (
    <ServicePartnerStateProvider className="container">
      {/* <header>
        <nav>
          <Div className="screen-padding" shadow="3" p={{ y: "1em" }}>
            <div>
              <a href="">
                <Image
                  src={require("../../assets/images/logo.png")}
                  maxW="120px"
                />
              </a>
            </div>
          </Div>
        </nav>
      </header> */}
      {/* <ToolbarForForm/> */}
      {/* <Div className="screen-padding container" p={{ y: { xs: "1.5rem", lg: "4rem" } }}> */}
      <Switch>
        <Route path="/servicepartner/signup" render={() => {
          return (<>
            <ToolbarForm />
            <Div className="screen-padding container" p={{ y: { xs: "1.5rem", lg: "4rem" } }}>
              <Route path="/servicepartner/signup" component={SignUp} />
            </Div>
          </>)
        }
        } />
        <ProtectedRoute path="/servicepartner/form" render={() => {
          return (<>
            <ToolbarForm />
            <Div className="screen-padding container" p={{ y: { xs: "1.5rem", lg: "4rem" } }}>
              <Route path="/servicepartner/form" component={BaseForm} />
            </Div>
          </>)
        }
        } />
        <ProtectedRoute path="/servicepartner/preview" render={() => {
          return (<>
            <ToolbarForm />
            <Div className="screen-padding container" p={{ y: { xs: "1.5rem", lg: "4rem" } }}>
              <Route path="/servicepartner/preview" component={PreviewDetails} />
            </Div>
          </>)
        }
        } />

        <Route path="/servicepartner/login" render={() => {
          return (<>
            <ToolbarForm />
            <Div className="screen-padding container" p={{ y: { xs: "1.5rem", lg: "4rem" } }}>
              <Route path="/servicepartner/login" component={Login} />
            </Div>
          </>)
        }
        } />
        <Route path="/servicepartner" render={() => {
          return (<>
            <ToolbarForForm />
            <Div className="screen-padding container" p={{ y: { xs: "1.5rem", lg: "4rem" } }}>
              <Route path="/servicepartner" component={Landing} />
            </Div>
          </>)
        }
        } />
      </Switch>
      {/* </Div> */}
    </ServicePartnerStateProvider>
  );
};

export default ServicePartner;

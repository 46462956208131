import React from "react";
import { Div, Radiobox } from "atomize";
import { Label } from "..";
import Colors from "../../Themes/Colors";

const RadioBoxItem = ({ label, type, checkStatus, id, onClick, index = -1 }) => {
  const borderColor = type === "contact" ? Colors.white : Colors.primaryColor;
  const inactiveColor =
    type === "contact" ? Colors.primaryColor : Colors.textSecondaryColor;
  const labelType = type === "contact" ? "bodyText3" : "bodyText3";
  const textColor =
    type === "contact" ? Colors.primaryColor : Colors.textMainColor;
  const x = type === "contact" ? "0px" : "10px";
  const maxH = type === "contact" ? "22px" : "45px";
  const minH = type === "contact" ? "22px" : "40px";
  return (
    <Div
      p={{ x, y: "3px" }}
      d="flex"
      border="1px solid"
      borderColor={borderColor}
      rounded="sm"
      cursor="pointer"
      align="center"
      maxH={maxH}
      minH={minH}
      m={{ y: "7.5px" }}
      onClick={() => onClick(id, label, index)}
      id={id}
    >
      <Radiobox
        checked={checkStatus}
        inactiveColor={inactiveColor}
        activeColor={Colors.primaryColor}
        size="20px"
        id={id}
        onChange={() => { }}
      />
      <Label
        label={label}
        type={labelType}
        style={{ m: { l: "5px" }, textColor }}
        id={id}
      // onChange={onClick}
      />
    </Div>
  );
};

export default RadioBoxItem;

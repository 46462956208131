import React from "react";
import { Row, Col, Text } from "atomize";
import { LinearProgress } from "@material-ui/core";

import COLORS from "../../Themes/Colors";
import { NSStepper } from "../../components/ServicePartner/Stepper";
import { ServiceCategory } from "./ServiceCategory";
import { PersonalDetails } from "./PersonalDetails";
import { ServiceProfile } from "./ServiceProfile";
import { AgencyLicence } from "./AgencyLicenceDetails";
import { AddressDetails } from "./AddressDetails";
import { ContactDetails } from "./ContactDetails";
import { KYCDetails } from "./KYCDetails";
//import { PreviewDetails } from "./PreviewDetails";
import { store } from "./context/store";
import {
  generatePartyAsync,
  getOrSavePhoneNumberAsync,
  //getPhoneNumberAsync,
} from "./Api/api";
import { useHistory } from "react-router-dom";

export const BaseForm = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [fetchDetailsApiStatus, setFetchDetailsApiStatus] = React.useState(
    () => ({
      error: "",
      loading: true,
    })
  );
  const { state, dispatch } = React.useContext(store);
  const history = useHistory();

  React.useEffect(() => {
    if (state.partyID) {
      setFetchDetailsApiStatus({ error: "", loading: false });
      setActiveStep(Number(state.lastStep) || 0);
    } else {
      setFetchDetailsApiStatus({ error: "", loading: true });
      async function generateParty() {
        const payload = {
          role_id: 2,
        };
        const generatePartyIDRequest = await generatePartyAsync(payload);
        if (generatePartyIDRequest.isSuccess) {
          dispatch({
            type: "setPartyID",
            payload: generatePartyIDRequest.data.party_id,
          });
          const phoneNumberPayload = {
            party: generatePartyIDRequest.data.party_id,
            phone: state.phoneNumber,
            country: 1,
          };
          const addPhoneNumber = await getOrSavePhoneNumberAsync(
            phoneNumberPayload
          );
          if (addPhoneNumber.isSuccess) {
            setFetchDetailsApiStatus({ error: "", loading: false });
          } else {
            setFetchDetailsApiStatus({
              error: "Unable to fetch detatils",
              loading: false,
            });
          }
        } else {
          setFetchDetailsApiStatus({
            error: "Unable to fetch detatils",
            loading: false,
          });
        }
      }
      generateParty();
    }
  }, [dispatch, state.partyID, state.lastStep, state.phoneNumber]);

  const stepsForClaimAdvisor = [
    "Service Category",
    "Personal Details",
    "Service Profile",
    "Address Details",
    "Contact Details",
    "KYC Details",
  ];

  const stepsForInsuranceAgent = [
    "Service Category",
    "Personal Details",
    "Service Profile",
    "Agency Licence Details",
    "Address Details",
    "Contact Details",
    "KYC Details",
  ];

  const stepComponentForClaimAdvisor = () => {
    switch (activeStep) {
      case 0:
        return (
          <ServiceCategory
            onClickNext={onClickNextStep}
            onClickBack={onClickBackStep}
          />
        );
      case 1:
        return (
          <PersonalDetails
            onClickNext={onClickNextStep}
            onClickBack={onClickBackStep}
          />
        );
      case 2:
        return (
          <ServiceProfile
            onClickNext={onClickNextStep}
            onClickBack={onClickBackStep}
          />
        );

      case 3:
        return (
          <AddressDetails
            onClickNext={onClickNextStep}
            onClickBack={onClickBackStep}
          />
        );
      case 4:
        return (
          <ContactDetails
            onClickNext={onClickNextStep}
            onClickBack={onClickBackStep}
          />
        );
      case 5:
        return (
          <KYCDetails
            onClickNext={onClickNextStep}
            onClickBack={onClickBackStep}
          />
        );
      case 6:
        history.push("/servicepartner/preview");
        break;
      // return (
      //   <PreviewDetails
      //     onClickNext={onClickNextStep}
      //     onClickBack={onClickBackStep}
      //   />
      // );

      default:
        history.push("/servicepartner/preview");
        return "Coming soon!";
    }
  };

  const stepComponentForInsuranceAgent = () => {
    switch (activeStep) {
      case 0:
        return (
          <ServiceCategory
            onClickNext={onClickNextStep}
            onClickBack={onClickBackStep}
          />
        );
      case 1:
        return (
          <PersonalDetails
            onClickNext={onClickNextStep}
            onClickBack={onClickBackStep}
          />
        );
      case 2:
        return (
          <ServiceProfile
            onClickNext={onClickNextStep}
            onClickBack={onClickBackStep}
          />
        );
      case 3:
        return (
          <AgencyLicence
            onClickNext={onClickNextStep}
            onClickBack={onClickBackStep}
          />
        );
      case 4:
        return (
          <AddressDetails
            onClickNext={onClickNextStep}
            onClickBack={onClickBackStep}
          />
        );
      case 5:
        return (
          <ContactDetails
            onClickNext={onClickNextStep}
            onClickBack={onClickBackStep}
          />
        );
      case 6:
        return (
          <KYCDetails
            onClickNext={onClickNextStep}
            onClickBack={onClickBackStep}
          />
        );
      case 7:
        history.push("/servicepartner/preview");
        break;
      // return (
      //   <PreviewDetails
      //     onClickNext={onClickNextStep}
      //     onClickBack={onClickBackStep}
      //   />
      // );

      default:
        history.push("/servicepartner/preview");
        return "Coming soon!";
    }
  };

  const onClickNextStep = () => {
    localStorage.setItem("lastStep", activeStep + 1);
    setActiveStep(activeStep + 1);
  };
  const onClickBackStep = () => {
    setActiveStep(activeStep - 1);
  };

  if (fetchDetailsApiStatus.error) {
    return (
      <Text
        textAlign="center"
        textSize="title"
        textColor={"red"}
        m={{ t: "0.8em" }}
      >
        {fetchDetailsApiStatus.error}
      </Text>
    );
  }
  if (fetchDetailsApiStatus.loading) {
    return <LinearProgress />;
  }
  return (
    <Row p={{ xs: "1em", lg: "2em" }} shadow="0 0 5px #ccc" rounded="md">
      <Col size="12">
        <Text
          tag="h1"
          textSize={{ xs: "display1", lg: "display3" }}
          textColor={COLORS.servicePartnerPrimary}
        >
          <NSStepper
            activeStep={activeStep}
            steps={
              state.serviceCategory === 2
                ? stepsForInsuranceAgent
                : stepsForClaimAdvisor
            }
          />
        </Text>
      </Col>
      <Col size="12">
        {state.serviceCategory === 2
          ? stepComponentForInsuranceAgent()
          : stepComponentForClaimAdvisor()}
      </Col>
    </Row>
  );
};

import React, { useState, useContext, useCallback } from "react";
import { Div, Modal, Image } from "atomize";
import { Label, Button } from "..";
import Colors from "../../Themes/Colors";
import TextBox from "../TextBox/TextBox";
import CheckBoxItem from "../CheckBox/CheckBox";
import axios from "../../utils/axios";
import Requests from "../../utils/requests";
import { store } from "../../context/store";
import paths from "../../constants/others.json";
import serviceValues from "../../constants/others.json";
import constValues from "../../constants/others.json";
import errorMessages from "../../constants/messages.json";
import Timer from "../../utils/otpTimer";
import firebase from "../../utils/firebase";

//Work flow
//1. Customer registration
//2. Enquiry (User information to backend)
//3. Validate otp if user party is not present
//4. Provider contact details
//5. Disable asking form submission if the user is already logged in

const ContactModal = ({
  numbers = /^[0-9]+$/,
  isOpen,
  onClose,
  redirectToDetail,
  getContact,
  currentParty,
}) => {
  const [fullName, setFullName] = useState("");
  //const [modalCalled, setModalCalled] = useState(0);
  // setModalCalled(modalCalled + 1);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState();
  const [agree, setAgree] = useState(false);
  const [shareContact, setShareContact] = useState(false);
  const [shareService, setShareService] = useState(false);
  const [registration, setRegistration] = useState();
  //const [otpResponse, setOtpResponse] = useState(0);
  const [enquiryId, setEnquiryId] = useState(0);
  const [userParty, setUserParty] = useState(null);
  const [claimAsset, setClaimAsset] = useState();
  const [showOtpBox, setshowOtpBox] = useState(false);
  const [existingEmail, setExistingEmail] = useState(false);
  const [otpError, setOtpError] = useState(false);
  const [validForm, setValidForm] = useState(true);
  const [timerEnable, setTimerEnable] = useState(false);
  // eslint-disable-next-line
  const [showModal, setShowModal] = useState(false);
  const globalState = useContext(store);
  const { dispatch } = globalState;

  React.useEffect(() => {
    const claimAsset = globalState.state.claimTypeAssetsArray.map((item) => {
      return {
        claim_types: item.claimtype.id,
        assets: item.asset.id,
      };
    });
    setClaimAsset([...claimAsset]);
  }, [
    globalState.state.claimTypeAssetsArray,
    globalState.state.selectedClaims,
  ]);

  React.useEffect(() => { }, [registration, userParty]);

  const trackValue = React.useCallback((field, value) => {
    switch (field) {
      case "fullName":
        setFullName(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "phone":
        setPhone(value);
        break;
      case "otp":
        setOtp(value);
        break;
      default:
    }
  }, []);

  const formAgree = (field, value) => {
    switch (field) {
      case "agree":
        setAgree(!agree);
        break;
      case "shareContact":
        setShareContact(!shareContact);
        break;
      case "shareService":
        setShareService(!shareService);
        break;
      default:
    }
  };

  const ValidateEmail = (email) => {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email
      )
    ) {
      return true;
    }
    return false;
  };

  const inputValidation = () => {
    if (
      fullName &&
      ValidateEmail(email) &&
      (phone.length === constValues.PHONE_NUMBER_LENGHT)
    ) {
      setValidForm(true);
      return true;
    }
    setValidForm(false);
    return false;
  };

  //1. User contact information to backend
  const postDetails = async () => {
    if (inputValidation()) {
      await axios
        .post(Requests.customerRegistration, {
          fullname: fullName,
          email: email,
          mobile: phone,
          city: globalState.state.selectedLocationId,
          receive_future_updates: agree,
        })
        .then((response) => {
          // userAgreements();
          setRegistration(response.data.data.registration.id);
          //setOtpResponse(response.data.data.registration.otp);
          setExistingEmail(false);
          postUserData(response.data.data.registration.id);
          setUserParty(response.data.data.party);
          existingUser(response.data.data.party);
        })
        .catch((e) => {
          setExistingEmail(true);
        });
    }
  };

  // Post user agreements

  // const userAgreements = async () => {
  //   await axios
  //     .post(Requests.postCustomerAgreement, {
  //       // fullname: fullName,
  //       // email: email,
  //       // mobile: phone,
  //       // city: globalState.state.selectedLocationId,
  //       receive_future_updates:`${agree}`
  //     })
  //     .then((response) => {
  //       console.log(response);
  //     })
  //     .catch((e) => console.log(e));
  // };
  //5. Provider contact details
  const providerDetails = useCallback(async () => {
    await axios
      .post(Requests.getServiceProvider, {
        party: currentParty,
      })
      .then((response) => {
        dispatch({ type: "providerDetails", payload: response.data.data });
        redirectToDetail(currentParty);
      })
      .catch((e) => {
        // console.log(e)
      });
  }, [currentParty, dispatch, redirectToDetail]);
  //2. Post user activity data to backend based on policy type or claim
  const postUserData = useCallback(async (registratinId) => {
    const path = window.location.pathname.slice(0, 20);
    const iterateClaimAsset = () => {
      const filtered = claimAsset.filter((el) => {
        return globalState.state.selectedClaimAssets.indexOf(el.asset) === -1;
      });

      return filtered;
    };
    if (
      !(
        window.location.pathname === paths.CLAIM_ADVISOR_PATH ||
        path === paths.CLAIM_ADVISOR_PATH
      )
    ) {
      // console.log("here1");
      if (globalState.state.selectedAsset) {
        await axios
          .post(Requests.enquiryInsurancePost, {
            registration: registratinId,
            city: globalState.state.selectedLocationId,
            service: serviceValues.INSURANCE_SERVICE_VALUE,
            service_providers: [currentParty],
            policy_types: [globalState.state.selectedPolicy],
            policytype_assets_arr: [
              {
                policy_types: globalState.state.selectedPolicy,
                assets: globalState.state.selectedAsset,
              },
            ],
            selected_companies: globalState.state.selecedCompanies,
          })
          .then((response) => {
            setEnquiryId(response.data.data.id);
            // setUserParty(response.data.data.party);
            // existingUser(response.data.data.party, response.data.data.id);
          })
          .catch((e) => {
            // console.log(e)
          });
      } else {
        await axios
          .post(Requests.enquiryInsurancePost, {
            registration: registratinId,
            city: globalState.state.selectedLocationId,
            service: serviceValues.INSURANCE_SERVICE_VALUE,
            service_providers: [currentParty],
            policy_types: [globalState.state.selectedPolicy],
            policytype_assets_arr: [],
            selected_companies: globalState.state.selecedCompanies,
          })
          .then((response) => {
            setEnquiryId(response.data.data.id);
            // setUserParty(response.data.data.party);
            // existingUser(response.data.data.party, response.data.data.id);
          })
          .catch((e) => {
            // console.log(e);
          });
      }
    } else {
      // console.log("here4");
      if (globalState.state.selectedClaimAssets.length > 0) {
        // console.log("here5");
        const claimTypeAssetArray = await iterateClaimAsset();
        // console.log("filtered asset array", claimTypeAssetArray);
        await axios
          .post(Requests.enquiryClaimPost, {
            registration: registratinId,
            city: globalState.state.selectedLocationId,
            service: serviceValues.CLAIM_SERVICE_VALUE,
            service_providers: [currentParty],
            claim_types: [...globalState.state.selectedClaims],
            claimtype_assets_arr: [...claimTypeAssetArray],
            selected_services: [...globalState.state.selectedHelps],
          })
          .then((response) => {
            setEnquiryId(response.data.data.id);
            // setRegistration(response.data.data.registration.id);
            // setUserParty(response.data.data.party);
            // existingUser(response.data.data.party, response.data.data.id);
          })
          .catch((e) => {
          });
      } else {
        await axios
          .post(Requests.enquiryClaimPost, {
            registration: registratinId,
            city: globalState.state.selectedLocationId,
            service: serviceValues.CLAIM_SERVICE_VALUE,
            service_providers: [currentParty],
            claim_types: globalState.state.selectedClaims,
            claimtype_assets_arr: [],
            selected_services: globalState.state.selectedServices,
          })
          .then((response) => {
            setEnquiryId(response.data.data.id);
            // setRegistration(response.data.data.registration.id);
            // setUserParty(response.data.data.party);
            // existingUser(response.data.data.party, response.data.data.id);
          })
          .catch((e) => {
          });
      }
    }
  }, [currentParty, globalState.state.selecedCompanies, globalState.state.selectedAsset, claimAsset,
    globalState.state.selectedClaimAssets, globalState.state.selectedClaims, globalState.state.selectedHelps,
    globalState.state.selectedLocationId, globalState.state.selectedPolicy, globalState.state.selectedServices]);

  // Disable the form if the user is already existing
  React.useEffect(() => {
    if (isOpen) {
      if (registration && !(userParty === null || userParty === "null")) {
        postUserData(registration);
        providerDetails();
      } else {
        //setShowModal(true);
      }
    } else {
      //setShowModal(false);
      //setTimerEnable(false);
    }
  }, [isOpen, currentParty, registration, userParty, postUserData, providerDetails]);

  React.useEffect(() => {
    //console.log(timerEnable);
  }, [timerEnable]);
  //3. Skip otp if the user is already existing
  const existingUser = async (userPartyId, enguiryId) => {
    if (userPartyId !== "null" && userPartyId !== null) {
      onClose();
      setExistingEmail(false);
      setshowOtpBox(false);
      setTimerEnable(false);
      providerDetails();
    } else {
      setshowOtpBox(true);
      onSignInSubmit();
      Timer(constValues.OTP_TIMER_LIMIT, "timer");
      setTimerEnable(true);
    }
  };

  //nineesh
  // const setUpRecaptcha = () => {
  //   window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
  //     'size': 'invisible',
  //     'callback': (response) => {
  //       // reCAPTCHA solved, allow signInWithPhoneNumber.
  //       onSignInSubmit();
  //     }
  //   });
  // };

  const onSignInSubmit = () => {
    //event.preventDefault()
    //setUpRecaptcha();
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      'size': 'invisible',
      'callback': (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        //onSignInSubmit();
      }
    });
    const phoneNumber = "+91" + phone;
    const appVerifier = window.recaptchaVerifier;
    firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
      }).catch((error) => {
        // Error; SMS not sent
        console.log(error)
      });
  };


  //4. Verify OTP
  const verifyOtp = async () => {

    if (userParty !== "null" && userParty !== null) {
      onClose();
      setshowOtpBox(false);
      setOtpError(false);
      providerDetails();
    } else if (otp?.length >= constValues.OTP_LENGTH && window.confirmationResult) {
      window.confirmationResult.confirm(otp).then((result) => {
        // User signed in successfully.
        //const user = result.user;
        //send to api for marking verfiy success and create or update party 
        postOtp()
      }).catch((error) => {
        // User couldn't sign in (bad verification code?)
        setshowOtpBox(true);
        setOtpError(true);
      });
    }
  };

  const postOtp = async () => {
    await axios
      .post(Requests.otpValidation, {
        otp: otp,
        registration_id: registration,
        enquiry_id: enquiryId,
        name: fullName,
        email: email,
        mobile_no: phone,
        receive_future_updates: agree,
      })
      .then((response) => {
        providerDetails();
        setshowOtpBox(false);
        setOtpError(false);
        onClose();
        setExistingEmail(false);
        setOtpError(false);
        setUserParty(response.data.data.party);
      })
      .catch((e) => {
        setshowOtpBox(true);
        setOtpError(true);
        console.log(e);
      });
  }

  // const loadInitial = () => {
  //   setOtpError(false);
  //   setValidForm(true);
  //   setTimerEnable(false);
  //   setshowOtpBox(false);
  //   onClose();
  // };

  // useEffect(() => {
  //   setOtpError(false);
  //   setValidForm(true);
  //   setTimerEnable(false);
  //   setshowOtpBox(false);
  // }, [onClose]);

  return (
    <Modal
      isOpen={showModal}
      onClose={onClose}
      rounded="md"
      p="35px"
      align="center"
      justify="center"
      maxW="550px"
      w="100%"
      pos="relative"
    >
      <Image
        src={require("../../assets/images/close.png")}
        maxW="22px"
        maxH="22px"
        pos="absolute"
        top="1rem"
        right="1rem"
        cursor="pointer"
        onClick={onClose}
      />

      <Div m={{ b: "1.8rem", t: "15px" }}>
        <Label
          label="Verify your information"
          style={{
            fontFamily: "Montserrat-Medium",
            textSize: "19px",
            textColor: Colors.primaryColor,
          }}
        />
      </Div>
      <Div m={{ b: "0.6rem", r: "10px" }}>
        <TextBox
          type="primary"
          style={{ placeholder: "Full Name" }}
          trackValue={trackValue}
          id="fullName"
          require
          value={fullName}
        />
        {!validForm ? (
          !fullName ? (
            <Div style={{ color: "#d05941", paddingLeft: "4px" }}>
              {errorMessages.INVALID_NAME}
            </Div>
          ) : (
            ""
          )
        ) : (
          ""
        )}
      </Div>
      <Div m={{ b: "0.6rem", r: "10px" }}>
        <TextBox
          type="primary"
          style={{ placeholder: "Email" }}
          trackValue={trackValue}
          id="email"
          value={email}
        />
      </Div>
      {!validForm ? (
        !ValidateEmail(email) ? (
          <Div style={{ color: "#d05941", paddingLeft: "4px" }}>
            {errorMessages.INVALID_EMAIL}
          </Div>
        ) : (
          ""
        )
      ) : (
        ""
      )}
      <Div m={{ b: "0.6rem", r: "10px" }}>
        <TextBox
          type="primary"
          style={{ placeholder: "Phone Number" }}
          trackValue={trackValue}
          id="phone"
          value={phone}
        />
      </Div>
      {!validForm ? (
        (phone.length !== constValues.PHONE_NUMBER_LENGHT || !phone.match(numbers)) ? (
          <Div style={{ color: "#d05941", paddingLeft: "4px" }}>
            {errorMessages.INVALID_PHONE}
          </Div>
        ) : (
          ""
        )
      ) : (
        ""
      )}
      <Div m={{ b: "0.9rem", r: "10px" }}>
        {showOtpBox ? (
          <TextBox
            type="primary"
            style={{ placeholder: "OTP", display: "hidden" }}
            trackValue={trackValue}
            id="otp"
          />
        ) : (
          ""
        )}
      </Div>
      {otpError ? (
        <Div style={{ color: "#d05941", paddingLeft: "4px" }}>Invalid OTP</Div>
      ) : (
        ""
      )}
      {existingEmail ? (
        <Div style={{ color: "#d05941", paddingLeft: "4px" }}>
          {errorMessages.EXISTING_USER}
        </Div>
      ) : (
        ""
      )}
      {/* <CheckBoxItem
        label="I agree to receive future updates."
        type="contact"
        formAgree={formAgree}
        selectedItems={agree}
        action={formAgree}
        id="agree"
      />
      <CheckBoxItem
        label="Share my contact details."
        type="contact"
        formAgree={formAgree}
        selectedItems={shareContact}
        action={formAgree}
        id="shareContact"
      />
      <CheckBoxItem
        label="Share my service request details."
        type="contact"
        formAgree={formAgree}
        selectedItems={shareService}
        action={formAgree}
        id="shareService"
        
      /> */}
      <Div
        id="timer"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: Colors.primaryColor,
        }}
      />

      <CheckBoxItem
        label="I agree to receive future updates."
        type="contact"
        formAgree={formAgree}
        selectedItems={agree}
        action={formAgree}
        id="agree"
      />
      <Div
        m={{ t: "1.8rem", r: "10px" }}
        d="flex"
        justify="center"
        align="center"
      >
        {/* <Button
          label="Get contact details"
          type="primary"
          textType="menu"
          style={{ w: "180px" }}
          onPress={() => {
            // onClose();
            postDetails();
            // verifyOtp();
          }}
        />  */}
        <Button
          label="Submit"
          type="primary"
          textType="menu"
          style={{ w: "180px" }}
          onPress={() => {
            // onClose();
            if (!showOtpBox) {
              postDetails();
            }
            if (showOtpBox) {
              verifyOtp();
            }

            // verifyOtp();
          }}
        />
      </Div>
      <Div
        id="recaptcha-container"
      >
      </Div>
      <Div
        id="recaptcha-containerw"
      >
      </Div>
    </Modal>
  );
};

export default ContactModal;

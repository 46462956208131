import React, { useState, useEffect, useContext } from "react";
import "./FilterDropdown.css";
import { Div, Row, Col, Image } from "atomize";
import { Label } from "../../components";
import Colors from "../../Themes/Colors";
import { SlideDown } from "react-slidedown";
import "react-slidedown/lib/slidedown.css";
import CheckBoxItem from "../CheckBox/CheckBox";
import Button from "../Button/Button";
import RadioBoxItem from "../RadioBox/RadioBox";
import { store } from "../../context/store";
import Requests from "../../utils/requests";
import axios from "../../utils/axios";
import constantValues from "../../constants/others.json";

function Dropdown({ title, items, id, filterType, buttonType }) {
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);
  const [currentPolicy, setCurrentPolicy] = useState(0);
  const [searchCompany, setSearchCompany] = useState("");
  const [relatedCompanies, setRelatedCompanies] = useState([]);
  const [selectedCompanyIds, setSelectedCompanyIds] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const globalState = useContext(store);
  const { dispatch } = globalState;

  const onPolicySelected = (id, label, index) => {
    setCurrentPolicy(id);
    dispatch({ type: "selectedPolicy", payload: id });
    dispatch({ type: "selectedPolicyName", payload: label });
    dispatch({ type: "selectedAsset", payload: 0 });
    dispatch({ type: "selectedAssetNameInsurance", payload: "" });
    dispatch({ type: "selectedPolicyIdx", payload: index });
    toggle(false);
  };

  const onAssetSelected = (id, label) => {
    dispatch({ type: "selectedAsset", payload: id });
    dispatch({ type: "selectedAssetNameInsurance", payload: label });
  };
  const clearPolicyTypes = () => {
    setCurrentPolicy(0);
    dispatch({ type: "selectedPolicy", payload: 0 });
    dispatch({ type: "selectedPolicyName", payload: "" });
    dispatch({ type: "selectedAsset", payload: 0 });
    dispatch({ type: "selectedAssetNameInsurance", payload: "" });
    dispatch({ type: "policyTypeReset", payload: true });
    toggle(false);
  }
  const companyName = (e) => {
    setSearchCompany(e.target.value);
  };

  // Fetch comapnies list based on search
  useEffect(() => {
    const fetchCompanyList = async () => {
      await axios
        .post(Requests.fetchCompanyList, {
          name: searchCompany,
        })
        .then((response) => {
          setRelatedCompanies(response.data.data);
        })
        .catch((e) => { });
    };
    if (searchCompany.length > 2) {
      fetchCompanyList();
      showCompanies();
    } else {
      hideCompanies();
    }
  }, [searchCompany]);

  // Save the state of selected companies and companies ids
  // useEffect(() => {
  //   if (filterType === "insuranceCompany") {
  //     console.log('selectedCompanyIds---', selectedCompanyIds);
  //     console.log('selectedCompanyNames-', selectedCompanies);
  //     dispatch({ type: "selectedCompanies", payload: selectedCompanyIds });
  //     dispatch({ type: "selectedCompanyNames", payload: selectedCompanies });
  //   }
  // }, [selectedCompanyIds, selectedCompanies, dispatch, filterType]);

  // Companies selection. Add if the company not present. Remove if it has already added.
  const companyChecked = (id, label) => {
    let currentSelectedList = selectedCompanyIds;
    const index = currentSelectedList.indexOf(id);
    let currentList = selectedCompanies;
    const indexValue = currentList.indexOf(label);

    if (index > -1) {
      const newList = currentSelectedList.filter((e) => e !== id);
      setSelectedCompanyIds([...newList]);
      dispatch({ type: "selectedCompanies", payload: newList });
      if (newList.length === 0) {
        dispatch({ type: "companiesCleared", payload: true });
      }
    } else {
      currentSelectedList.push(id);
      setSelectedCompanyIds([...currentSelectedList]);
      dispatch({ type: "selectedCompanies", payload: currentSelectedList });
    }

    if (indexValue > -1) {
      const newNames = currentList.filter((e) => e !== label);
      setSelectedCompanies([...newNames]);
      dispatch({ type: "selectedCompanyNames", payload: newNames });
    } else {
      currentList.push(label);
      setSelectedCompanies([...currentList]);
      dispatch({ type: "selectedCompanyNames", payload: currentList });
    }
  };

  const showCompanies = () => {
    try {
      document.getElementById("relatedCompanies").style.display = "block";
    } catch (e) { }
  };

  const hideCompanies = () => {
    try {
      document.getElementById("relatedCompanies").style.display = "none";
    } catch (e) { }
  };

  useEffect(() => {
    // console.log({ id });
    window.addEventListener("click", function (e) {
      if (!document.getElementById(id)?.contains(e.target)) {
        setOpen(false);
      }
    });
  }, [id]);
  let arrowClass = "arrow-icon";
  if (open) {
    arrowClass = "arrow-icon arrow-active";
  }
  const [mLeft, setMLeft] = useState(0);

  const SelectedItem = ({ click, label }) => (
    <Div
      p={{ y: "5px", x: "20px" }}
      d="flex"
      align="center"
      maxH="100px"
      minH="40px"
      border={"1px solid"}
      rounded="sm"
      justify="space-between"
      borderColor={Colors.primaryColor}
      m={{ y: "10px" }}
      cursor="pointer"
      onClick={click}
    >
      <Label
        label={label}
        type="bodyText3"
        style={{ m: { l: "8px" }, w: "100%", textAlign: "left" }}
      />
      <Image
        src={require("../../assets/images/close.png")}
        maxW="20px"
        maxH="20px"
        cursor="pointer"
      />
    </Div>
  );

  return (
    <Div id={id} w="100%" m={{ r: "10px" }}>
      <Div
        // bg="#fff"
        onClick={(e) => {
          const dropdown = document.getElementById(id).getBoundingClientRect();
          const sW = window.innerWidth;
          const mP = dropdown.left;
          const mW = 650;
          const tW = mP + mW;
          if (tW > sW) {
            const fLeft = tW - sW;
            setMLeft(fLeft + 30);
          } else {
            setMLeft(0);
          }

          toggle(!open);
        }}
        // maxW="300px"
        w="100%"
        d="flex"
        justify="space-between"
        p={{ x: "10px", y: "7px" }}
        align="center"
        border="1px solid"
        rounded="md"
        bg={open ? Colors.primaryColor : "transperent"}
        borderColor={open ? Colors.primaryColor : Colors.textSecondaryColor}
        cursor="pointer"
      >
        <Label
          label={title}
          type="title2"
          style={{
            textColor: open ? Colors.white : Colors.primaryColor,
            m: { x: "5px", y: "7px" },
          }}
        />
        <Image
          src={require("../../assets/images/arrow.png")}
          w="15px"
          height="15px"
          className={arrowClass}
        />
      </Div>
      {open && (
        <SlideDown className={"my-dropdown-slidedown"}>
          <Div
            className="dd-list"
            bg="#fff"
            p={{ x: "15px", y: "10px" }}
            border="1px solid"
            borderColor="#b5b5b5"
            maxW="650px"
            m={{ l: `-${mLeft}px` }}
          >
            {filterType === "insuranceCompany" && (
              <Div
                p={{ x: "5px" }}
                d="flex"
                cursor="pointer"
                align="center"
                maxH="45px"
                m={{ t: "12px", b: "20px" }}
                minH="40px"
                border="1px 0px 1px 1px"
                borderColor="#a0a3c8"
                className="input-container"
                maxW={{ md: "370px" }}
              >
                <input
                  className="location-input"
                  placeholder="Search companies"
                  onChange={(e) => companyName(e)}
                />
                <Image
                  src={require("../../assets/images/search.png")}
                  maxW="25px"
                  maxH="25px"
                  cursor="pointer"
                />
              </Div>
            )}
            <Row>
              {filterType === "insuranceCompany" ? (
                <div id="relatedCompanies">
                  <Row>
                    {/* <div style={{ display: "flex", flexDirection:'' }}> */}
                    {relatedCompanies
                      ?.slice(0, constantValues.COMPANIES_LIST_LIMIT)
                      .map((item, index) => (
                        <Col key={`key-${index}`} size="6">
                          <CheckBoxItem
                            label={item.name}
                            action={companyChecked}
                            selectedItems={
                              selectedCompanyIds.indexOf(item.id) > -1
                                ? true
                                : false
                            }
                            id={item.id}
                          />
                        </Col>
                      ))}
                    {/* </div> */}
                  </Row>
                </div>
              ) : (
                items?.map((item, index) => (
                  <Col key={`key-${index}`} size="6">
                    {buttonType === "radio" ? (
                      <RadioBoxItem
                        label={
                          filterType === "policy"
                            ? item?.name
                            : item?.asset?.name
                        }
                        checkStatus={
                          filterType === "policy"
                            ? index === currentPolicy - 1
                              ? true
                              : false
                            : item?.asset?.id ===
                              globalState.state.selectedAsset
                              ? true
                              : false
                        }
                        id={
                          filterType === "policy" ? item?.id : item?.asset?.id
                        }
                        onClick={
                          filterType === "policy"
                            ? onPolicySelected
                            : onAssetSelected
                        }
                        index={index}
                      />
                    ) : (
                      ""
                    )}
                  </Col>
                ))
              )}
            </Row>
            {filterType === "insuranceCompany" && (
              <Row>
                {selectedCompanies?.map((item, index) => {
                  return (
                    <Col
                      size="6"
                      onClick={() =>
                        companyChecked(selectedCompanyIds[index], item)
                      }
                      key={selectedCompanyIds[index]}
                    >
                      <SelectedItem label={item} />
                    </Col>
                  );
                })}
              </Row>
            )}
            <div style={{ "display": "flex" }}>
              {filterType !== "policy" ?
                <span onClick={toggle}>
                  <Button
                    label="Apply filters"
                    type="primary"
                    textType="menu"
                    style={{ w: "120px", m: { t: "5px" } }}
                  />
                </span> :
                <span style={{ "marginLeft": "10px" }} onClick={clearPolicyTypes}>
                  <Button
                    label="Clear filters"
                    type="primary"
                    textType="menu"
                    style={{ w: "120px", m: { t: "5px" } }}
                    disabled={currentPolicy > 0 ? false : true}
                  />
                </span>}
            </div>
          </Div>
        </SlideDown>
      )}
    </Div>
  );
}

export default Dropdown;

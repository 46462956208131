import React from "react";
import { Row, Col, Input, Text } from "atomize";
import Colors from "../../../Themes/Colors";

export const KycDetails = ({
  documentType = "",
  documentNumber = "",
}) => {
  return (
    <Row>
      <Col size={12} p={{ t: "2em", b: "1em" }}>
        <Text
          textSize={{ xs: "subheader", lg: "subheader" }}
          textColor={Colors.servicePartnerPrimary}
        >
          KYC Details
        </Text>
      </Col>
      <Col size={4} p={{ y: "0.5em" }}>
        Document Type
      </Col>
      <Col size={8} p={{ y: "0.5em" }}>
        <Input
          value={documentType}
          placeholder={"Document Type"}
          h={"1.8rem"}
          disabled={true}
        />
      </Col>
      <Col size={4} p={{ y: "0.5em" }}>
        Document Number
      </Col>
      <Col size={8} p={{ y: "0.5em" }}>
        <Input
          value={documentNumber}
          placeholder={"Document Number"}
          h={"1.8rem"}
          disabled={true}
        />
      </Col>
    </Row>
  );
};

import React, { useEffect, useState } from "react";
import "../../styles.css";
import DrawerToggleButton from "../SideDrawer/DrawerToggleButton";
import "./Toolbar.css";
import { Image } from "atomize";
//import { Label } from "../../components";
import { useHistory } from "react-router-dom";

const getBackPath = ({ path }) => {
  switch (true) {
    case path.includes("/service/claimadvice/detail"): {
      return "/service/claimadvice";
    }
    case path.includes("/service/buyinsurance/detail"): {
      return "/service/buyinsurance";
    }
    case path.includes("/service/claimadvice"): {
      return "/";
    }
    case path.includes("/service/buyinsurance"): {
      return "/";
    }
    case path.includes("/servicepartner"): {
      return "/";
    }
    case path.includes("/coporatesolutions"): {
      return "/";
    }

    default:
      return "/";
  }
};



// const MenuItem = ({ label, onPress }) => {
//   const history = useHistory();
//   return (
//     <Div className="nav-menu" cursor="pointer" onClick={onPress}>
//       <div className="dots" />
//       <Label label={label} type="title2" />
//     </Div>
//   );
// };

const ToolbarForForm = (props) => {
  const history = useHistory();
  const [path, setPath] = useState(history.location.pathname);
  useEffect(() => {
    return history.listen((location) => {
      // console.log({ backPath: getBackPath({ path: location.pathname }) });
      setPath(location.pathname);
    });
  }, [history]);

  return (
    <header className="toolbar screen-padding">
      <nav className="toolbar__navigation">
        <div className="toolbar__toggle-button">
          <DrawerToggleButton
            click={props.drawerClickHandler}
            getBackPath={getBackPath}
            path={path}
          />
        </div>
        <div className="navbar-items">
          <div className="toolbar__logo">
            <Image
              src={require("../../assets/images/logo.png")}
              maxW="120px"
            />
          </div>

          {path === "/servicepartner/form" || path === "/servicepartner/preview" ? (
            ''
          ) : (

            <div className="toolbar_navigation-items">
              {/* <MenuItem
              label="Become Our Service Partner"
              onPress={() => history.push("/servicepartner")}
            />
            <MenuItem
              label="Corporate Solutions"
              onPress={() => history.push("/coporatesolutions")}
            /> */}
            </div>
          )}
        </div>
      </nav>
    </header>
  );
};

export default ToolbarForForm;

import React from "react";

import "./DrawerToggleButton.css";
import { Image } from "atomize";
import { useHistory } from "react-router-dom";

const DrawerToggleButton = ({ click, path, getBackPath }) => {
  const history = useHistory();
  const goBack = (path) => history.push(path);
  return (
    <div className="toggle-button">
      {path === "/" ? (
        <button onClick={click} className="toggle-button">
          <div className="toggle-button__line" />
          <div className="toggle-button__line" />
          <div className="toggle-button__line" />
        </button>
      ) : (
        <Image
          src={require("../../assets/images/back-arrow.png")}
          maxW="13px"
          maxH="22px"
          cursor="pointer"
          onClick={() => goBack(getBackPath({ path }))}
        />
      )}
    </div>
  );
};

export default DrawerToggleButton;

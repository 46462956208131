import React from "react";
import ReactDOM from "react-dom";
import 'antd/dist/antd.css'
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { StyleReset } from "atomize";
import { Provider as StyletronProvider, /*DebugEngine*/ } from "styletron-react";
import { Client as Styletron } from "styletron-engine-atomic";
import { StateProvider } from "./context/store";

const engine = new Styletron();
ReactDOM.render(
  <React.StrictMode>
    <StyleReset />
    <StyletronProvider value={engine}>
      <StateProvider>
        <App />
      </StateProvider>
    </StyletronProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register();

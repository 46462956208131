import React/*, { useState, useEffect, useContext }*/ from "react";
import "./SelectedDropdown.css";
import { Div, /*Row, Col, Image, Checkbox*/ } from "atomize";
import { Label } from "../../components";
import Colors from "../../Themes/Colors";
//import { SlideDown } from "react-slidedown";
import "react-slidedown/lib/slidedown.css";
// import CheckBoxItem from "../CheckBox/CheckBox";
// import Button from "../Button/Button";
// import RadioBoxItem from "../RadioBox/RadioBox";
// import { store } from "../../context/store";
// import Requests from "../../utils/requests";
// import axios from "../../utils/axios";
// import constantValues from "../../constants/others.json";

const SelectedItem = ({ click, label }) => (
  <Div
    p={{ y: "2px", x: "10px" }}
    d="flex"
    align="center"
    maxH="100px"
    minH="40px"
    border={"1px solid"}
    rounded="sm"
    justify="space-between"
    borderColor={Colors.primaryColor}
    m={{ y: "5px" }}
    style={{ marginRight: "10px" }}
    cursor="pointer"
    onClick={click}
  >
    <Label
      label={label}
      type="bodyText3"
      style={{ w: "100%", textAlign: "left" }}
    />
    {/* <Image
      src={require("../../assets/images/close.png")}
      maxW="20px"
      maxH="20px"
      cursor="pointer"
    /> */}
  </Div>
);

function SelectedDropdown({ title, items, id, filterType, buttonType }) {
  return (
    <Div>
      {items && (
        <div id="selectedClaims" style={{ display: "table-cell" }}>
          {filterType === "insuranceAsset" ? (
            <SelectedItem label={items} />
          ) : items?.[0]?.length > 0 ? (
            items?.map((item, index) => {
              return (
                <span style={{ display: "inline-block" }} key={`key-${index}`}>
                  <SelectedItem label={item} />
                </span>
              );
            })
          ) : (
            ""
          )}
        </div>
      )}
    </Div>
  );
}

export default SelectedDropdown;

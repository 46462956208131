import React from "react";
import { Div, Row, Col, Text, Image } from "atomize";
import { LinearProgress } from "@material-ui/core";

import { NSButton } from "../../components/ServicePartner/Button";
import { NSDatePicker } from "../../components/ServicePartner/DatePicker";
import { InputText } from "../../components/ServicePartner/InputText";
import { NSUpload } from "../../components/ServicePartner/Upload";
import Colors from "../../Themes/Colors";
import { NSBanner } from "../../components/ServicePartner/Banner";
import { store } from "./context/store";
import { MEDIA_URL, getOrSaveAgencyDetails } from "./Api/api";
import moment from "moment";
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";
export const AgencyLicence = (props) => {
  const [agencyDetails, setAgencyDetails] = React.useState(() => ({
    licence_number: "",
    reg_authority: "",
    date_of_issue: undefined,
    date_of_expiry: undefined,
    licence_copy: "",
  }));
  const { state } = React.useContext(store);
  const [fetchDetailsApiStatus, setFetchDetailsApiStatus] = React.useState(
    () => ({
      error: "",
      loading: false,
    })
  );
  const [saveDetailsApiStatus, setSaveDetailsApiStatus] = React.useState(
    () => ({
      error: "",
      loading: false,
    })
  );
  const [uploadedFile, setUploadedFile] = React.useState(() => undefined);

  React.useEffect(() => {
    setFetchDetailsApiStatus({ error: "", loading: true });
    const payload = new FormData();
    payload.append("party", state.partyID);
    async function getAgencyDetails() {
      const getAgencyDetails = await getOrSaveAgencyDetails(payload);
      if (getAgencyDetails.isSuccess && getAgencyDetails.data) {
        setAgencyDetails(getAgencyDetails.data);
        if (getAgencyDetails.data.licence_copy) {
          setUploadedFile(`${MEDIA_URL}${getAgencyDetails.data.licence_copy}`);
        }
        setFetchDetailsApiStatus({
          error: "",
          loading: false,
        });
      } else {
        setFetchDetailsApiStatus({
          error: "Unable to fetch details. Try again",
          loading: false,
        });
      }
    }
    getAgencyDetails();
  }, [state.partyID]);

  const onClickBack = () => {
    props.onClickBack();
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!agencyDetails.licence_number) {
      setSaveDetailsApiStatus({
        error: "Please fill licence number",
        loading: false,
      });
      return;
    }
    if (!agencyDetails.reg_authority) {
      setSaveDetailsApiStatus({
        error: "Please fill registration authority",
        loading: false,
      });
      return;
    }
    if (!agencyDetails.date_of_issue || !agencyDetails.date_of_expiry) {
      setSaveDetailsApiStatus({
        error: "Select a date",
        loading: false,
      });
      return;
    }
    if (uploadedFile || agencyDetails.licence_copy) {
      setSaveDetailsApiStatus({ error: "", loading: true });
      const payload = new FormData();
      payload.append("party", state.partyID);
      payload.append("licence_number", agencyDetails.licence_number);
      payload.append("reg_authority", agencyDetails.reg_authority);
      payload.append("date_of_issue", agencyDetails.date_of_issue);
      payload.append("date_of_expiry", agencyDetails.date_of_expiry);
      typeof uploadedFile === "object" &&
        payload.append("licence_copy", uploadedFile);

      const saveAgencyDetailsResponse = await getOrSaveAgencyDetails(payload);
      if (
        saveAgencyDetailsResponse.isSuccess &&
        saveAgencyDetailsResponse.data
      ) {
        props.onClickNext();
      } else {
        setSaveDetailsApiStatus({
          error: "Unable to update details. Try again",
          loading: false,
        });
      }
    } else {
      setSaveDetailsApiStatus({
        error: "Upload licence copy",
        loading: false,
      });
      return;
    }
  };

  const onChangeLicenceNumber = (e) => {
    setAgencyDetails({
      ...agencyDetails,
      licence_number: e.currentTarget.value,
    });
  };
  const onChangeRegAuthority = (e) => {
    setAgencyDetails({
      ...agencyDetails,
      reg_authority: e.currentTarget.value,
    });
  };

  const onChangeIssueDate = (_, dateString) => {
    setAgencyDetails({ ...agencyDetails, date_of_issue: dateString });
  };
  const onChangeExpiryDate = (_, dateString) => {
    setAgencyDetails({ ...agencyDetails, date_of_expiry: dateString });
  };
  const onChangeLicenceFile = (file) => {
    setUploadedFile(file);
  };
  const getLicenceCopy = () => {
    switch (typeof uploadedFile) {
      case "string":
        return uploadedFile;
      case "object":
        return URL.createObjectURL(uploadedFile);
      default:
        return "";
    }
  };

  if (fetchDetailsApiStatus.error) {
    return (
      <Text
        textAlign="center"
        textSize="title"
        textColor={"red"}
        m={{ t: "0.8em" }}
      >
        {fetchDetailsApiStatus.error}
      </Text>
    );
  }
  if (fetchDetailsApiStatus.loading) {
    return <LinearProgress />;
  }

  return (
    <Row>
      <Col size={12}>
        <NSBanner />
      </Col>
      <Col size={{ xs: 12, lg: 6 }} order={{ xs: 2, lg: 1 }}>
        <Div p={{ x: "1rem" }}>
          <Text
            textSize={{ xs: "heading", lg: "heading" }}
            textColor={Colors.servicePartnerText}
          >
            Agency Licence Details
          </Text>
          <form onSubmit={onSubmit}>
            <Row>
              <Col size={12} p={{ b: "2em" }}>
                <InputText
                  value={agencyDetails.licence_number}
                  placeholder="Agency Licence Number"
                  onChange={onChangeLicenceNumber}
                // error={formik.touched.firstName && formik.errors.firstName}
                />
                <InputText
                  value={agencyDetails.reg_authority}
                  placeholder="Registration Authority"
                  onChange={onChangeRegAuthority}
                // error={formik.touched.firstName && formik.errors.firstName}
                />
                <NSDatePicker
                  onChange={onChangeIssueDate}
                  label="Date of issue"
                  value={
                    agencyDetails.date_of_issue
                      ? moment(agencyDetails.date_of_issue)
                      : undefined
                  }
                />
                <NSDatePicker
                  onChange={onChangeExpiryDate}
                  label="Expiry date"
                  value={
                    agencyDetails.date_of_expiry
                      ? moment(agencyDetails.date_of_expiry)
                      : undefined
                  }
                />
                <NSUpload
                  onChange={onChangeLicenceFile}
                  defaultFile={getLicenceCopy()}
                  label={"Upload Licence"}
                  fileLabel={"Licence"}
                />
              </Col>
              <Col size={6}>
                <NSButton
                  style={{ width: "100%", borderRadius: "3px" }}
                  btntype="secondary"
                  iconposition="left"
                  // icon={<Icon name="LongLeft" size="16px" color="black" />}
                  minW="100%"
                  onClick={onClickBack}
                >
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div style={{ background: "#d5d8dc", padding: "7px", height: "40px", textAlign: "center", width: "33%", marginLeft: "-16px", borderRadius: "3px" }}> <ArrowLeftOutlined /> </div>
                    <div style={{ marginRight: "20px" }}>
                      Back</div>
                  </div>
                </NSButton>
              </Col>
              <Col size={6}>
                <NSButton
                  style={{ width: "100%", borderRadius: "3px" }}
                  btntype="submit"
                  loading={saveDetailsApiStatus.loading}
                  // icon={<Icon name="LongRight" size="16px" color="white" />}
                  minW="100%"
                >
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div style={{ marginLeft: "20px" }}>
                      {" "}
                      Next{" "}</div> <div style={{ background: "#3193ff", padding: "7px", height: "40px", textAlign: "center", width: "33%", marginRight: "-16px", borderRadius: "3px" }}> <ArrowRightOutlined /> </div>
                  </div>
                </NSButton>
              </Col>
              <Text textSize="caption" textColor={"red"} m={{ t: "0.8em" }}>
                {saveDetailsApiStatus.error}
              </Text>
            </Row>
          </form>
        </Div>
      </Col>
      <Col
        m={{ xs: 0, lg: { t: "-10em" } }}
        size={{ xs: 12, lg: 6 }}
        order={{ xs: 1, lg: 2 }}
      >
        <Div p="1rem">
          <Image src={require("../../assets/images/licence.png")} />
        </Div>
      </Col>
    </Row>
  );
};

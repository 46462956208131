/* global grecaptcha */
import { Div, Row, Col, Text, Image } from "atomize";
import React from "react";
import { useFormik } from "formik";

import { InputText } from "../../components/ServicePartner/InputText";
import COLORS from "../../Themes/Colors";
import "./style.css";
import { RequestOTPModal } from "./RequestOTPModal";
import { NSButton } from "../../components/ServicePartner/Button";
import firebase from "../../utils/firebase";
// import firebase from './Auth/firebase'
import { registerUserAsync, loginUserAsync, phoneNumberExists } from "./Api/api";
import { store } from "./context/store";
import { useHistory } from "react-router";
import { signupFormValidate } from './Validations'
import { ArrowRightOutlined } from "@ant-design/icons";
//import ToolbarForm from "../../components/Toolbar/ToolbarForm"
export const SignUp = () => {
  const [showOTPModal, setShowOTPModal] = React.useState(() => false);
  const [otp, setOtp] = React.useState(() => "");
  const [timer, setTimer] = React.useState(() => ({
    minutes: 2,
    seconds: 30,
  }));
  const [otpTimeOut, setOtpTimeOut] = React.useState(false);
  const [signupValues, setSignupValues] = React.useState(() => ({
    email: "",
    username: "",
    password: "",
    name: "",
  }));
  const [otpLoading, setOtpLoading] = React.useState(() => false);
  const [loading, setLoading] = React.useState(() => false);
  const [errorMessages, setErrorMessages] = React.useState(() => ({
    register: "",
    otpValidate: "",
  }));

  const globalState = React.useContext(store);
  const { dispatch } = globalState;
  const history = useHistory();

  React.useEffect(() => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {
          console.log("Recaptcha resolved");
        },
      }
    );
  }, []);

  React.useEffect(() => {
    let otpTimer = null;
    if (showOTPModal) {
      otpTimer = setInterval(() => {
        if (timer.seconds > 0) {
          setTimer({ ...timer, seconds: timer.seconds - 1 });
        }
        if (timer.seconds === 0) {
          if (timer.minutes === 0) {
            setOtpTimeOut(true);
            clearInterval(otpTimer);
          } else {
            setTimer({ ...timer, minutes: timer.minutes - 1, seconds: 59 });
          }
        }
      }, 1000);
    }
    return () => {
      clearInterval(otpTimer);
    };
  }, [showOTPModal, timer]);

  const formik = useFormik({
    initialValues: {
      userName: "",
      phoneNumber: "",
      password: "",
      confirmPassword: "",
    },
    validate: signupFormValidate,
    onSubmit: (values) => {
      setLoading(true);
      setErrorMessages({ otpValidate: "", register: "" });
      const phoneNumber = `${values.phoneNumber}`;
      setSignupValues({
        email: "",
        password: values.password,
        username: phoneNumber,
        name: values.userName,
      });

      //call api and check whether phone number is already registered
      const phonenumberExistsResponse = phoneNumberExists(phoneNumber);
      Promise.all([phonenumberExistsResponse]).then((responses) => {

        if (responses[0].status === 404) { //no matching numbers found in db, send otp
          requestOTP(phoneNumber);
        } else if (responses[0].status === 200) { //number already existing in db
          setLoading(false);
          setErrorMessages({
            otpValidate: "",
            register: "This mobile number is already existing in our records."
          });
        } else { //server unavailable
          setLoading(false);
          setErrorMessages({
            otpValidate: "",
            register: "An unexpected exception triggered. Please come back later."
          });
        }
      });
    },
  });

  const requestOTP = (phoneNumber) => {
    setOtpLoading(true);
    setOtpTimeOut(false);
    const appVerifier = window.recaptchaVerifier;
    if (appVerifier) {
      firebase
        .auth()
        .signInWithPhoneNumber(`+91${phoneNumber}`, appVerifier)
        .then(async (confirmationResult) => {
          window.confirmationResult = confirmationResult;
          setLoading(false);
          setOtpLoading(false);
          setTimer({ minutes: 2, seconds: 30 });
          setShowOTPModal(true);
        })
        .catch(function (error) {
          setErrorMessages({
            otpValidate: "",
            register: "Failed to send otp. Try again",
          });
          window.recaptchaVerifier.render().then(function (widgetId) {
            grecaptcha.reset(widgetId);
          });
          setLoading(false);
        });
    }
  };

  const onCloseOTPModal = () => {
    setErrorMessages({ otpValidate: "", register: "" });
    setOtp("");
    setShowOTPModal(false);
  };

  const onChangeOTP = (otp) => {
    setOtp(otp);
  };

  const onSubmitOTP = () => {
    setOtpLoading(true);
    const optConfirm = window.confirmationResult;
    optConfirm
      .confirm(otp)
      .then(async (result) => {
        const registerUserRequest = await registerUserAsync({
          username: signupValues.username,
          password: signupValues.password,
        });
        if (!registerUserRequest.isSuccess) {
          setOtpLoading(false);
          setOtp("")
          setErrorMessages({
            otpValidate:
              registerUserRequest.message ||
              "Failed to register. Check your details",
            register: "",
          });
          return;
        }
        const loginPayload = {
          email: registerUserRequest.data.email,
          password: signupValues.password,
        };
        const loginUserRequest = await loginUserAsync(loginPayload);
        if (!loginUserRequest.isSuccess) {
          setOtpLoading(false);
          setOtp("")
          setErrorMessages({
            otpValidate: "Unable to login. Try again",
            register: "",
          });
          return;
        }
        dispatch({
          type: "registerSuccess",
          payload: {
            phoneNumber: signupValues.username,
            isLoggedIn: true,
            fullName: signupValues.name,
            party: loginUserRequest.data.party || undefined,
          },
        });
        setShowOTPModal(false);
        history.push("/servicepartner/form");
      })
      .catch(function (error) {
        setOtpLoading(false);
        setOtp("")
        setErrorMessages({
          otpValidate: "Incorrect OTP. Try again",
          register: "",
        });
      });
  };
  const onClickResendOTP = () => {
    requestOTP(signupValues.username);
  };

  return (
    <>
      {/* <ToolbarForm/> */}
      <Row p={{ xs: "1em", lg: "2em" }} shadow="0 0 5px #ccc" rounded="md">
        <Col size="12">
          <Text
            tag="h1"
            textSize={{ xs: "display1", lg: "display3" }}
            m={{ b: "1em" }}
            textColor={COLORS.servicePartnerPrimary}
          >
            Service Awaiting For You
          </Text>
        </Col>
        <Col size="12">
          <Row>
            <Col size={{ xs: 12, lg: 5 }} order={{ xs: 2, lg: 1 }}>
              <Div p="1rem">
                <Text
                  textSize={{ xs: "heading", lg: "display2" }}
                  textColor={COLORS.servicePartnerPrimary}
                  m={{ b: "0.8em" }}
                >
                  Join NSURE360
                </Text>
                <form onSubmit={formik.handleSubmit}>
                  <div id="recaptcha-container"></div>
                  <InputText
                    value={formik.values.userName}
                    id="userName"
                    placeholder="Enter name here"
                    onChange={formik.handleChange}
                    error={formik.touched.userName && formik.errors.userName}
                  />

                  <InputText
                    value={formik.values.phoneNumber}
                    id="phoneNumber"
                    placeholder="Enter number"
                    caption="An OTP will be send to this phone number"
                    onChange={formik.handleChange}
                    error={
                      formik.touched.phoneNumber && formik.errors.phoneNumber
                    }
                  />
                  <InputText
                    value={formik.values.password}
                    type="password"
                    id="password"
                    placeholder="Enter password"
                    onChange={formik.handleChange}
                    error={formik.touched.password && formik.errors.password}
                  />
                  <InputText
                    value={formik.values.confirmPassword}
                    type="password"
                    id="confirmPassword"
                    placeholder="Confirm password"
                    onChange={formik.handleChange}
                    error={
                      formik.touched.confirmPassword &&
                      formik.errors.confirmPassword
                    }
                  />
                  <NSButton
                    id="recaptcha-container"
                    type="submit"
                    loading={loading}
                    // icon={<Icon name="LongRight" size="16px" color="white" />}
                    style={{ width: "100%", borderRadius: "3px" }}
                  >
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                      <div style={{ marginLeft: "60px" }}>
                        {" "}
                        Continue{" "}</div> <div style={{ background: "#3193ff", padding: "7px", height: "40px", textAlign: "center", width: "20%", marginRight: "-16px", borderRadius: "3px" }}> <ArrowRightOutlined /> </div>
                    </div>
                  </NSButton>
                  <Text textSize="caption" textColor={"red"} m={{ t: "0.8em" }}>
                    {errorMessages.register}
                  </Text>
                </form>
              </Div>
            </Col>
            <Col
              m={{ xs: 0, lg: { t: "-10em" } }}
              size={{ xs: 12, lg: 7 }}
              order={{ xs: 1, lg: 2 }}
            >
              <Div p="1rem">
                <Image src={require("../../assets/images/Frame61.png")} />
              </Div>
            </Col>
          </Row>
        </Col>
        <RequestOTPModal
          isOpen={showOTPModal}
          onClose={onCloseOTPModal}
          otp={otp}
          onChangeOTP={onChangeOTP}
          timer={timer}
          otpTimeOut={otpTimeOut}
          errorMessage={errorMessages.otpValidate}
          onClickApply={onSubmitOTP}
          onClickResend={onClickResendOTP}
          loading={otpLoading}
        />
      </Row>
    </>
  );
};

import firebase from "firebase";

// var firebaseConfig = {
//     apiKey: "AIzaSyAvKpsE5egQ4kLpQrzXW3GAZ0fIO2Bz0BA",
//     authDomain: "nsure360.firebaseapp.com",
//     projectId: "nsure360",
//     storageBucket: "nsure360.appspot.com",
//     messagingSenderId: "1023126248512",
//     appId: "1:1023126248512:web:9fc3a5644cb3f20ca293a5",
//     measurementId: "G-3VYB79YG9D"
//   };
//   // Initialize Firebase
//   firebase.initializeApp(firebaseConfig);

var firebaseConfig = {
  apiKey: "AIzaSyAEt1CfUnlR0P3ikVB1OiZnKDreTfqx-cA",
  authDomain: "nsure360-otp-service.firebaseapp.com",
  projectId: "nsure360-otp-service",
  storageBucket: "nsure360-otp-service.appspot.com",
  messagingSenderId: "344308618497",
  appId: "1:344308618497:web:4b06fbcd6a7556fbceb700"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;
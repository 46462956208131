import React from 'react'
import { Div, Text, Button, Modal } from 'atomize'
import OtpInput from 'react-otp-input';
import { LinearProgress } from "@material-ui/core";

import COLORS from '../../Themes/Colors'


export const RequestOTPModal = (props) => {
    return (
        <Modal isOpen={props.isOpen} onClose={props.onClose} align="center" p={{ x: '2em', y: '1.5em' }} rounded="md" maxW='21rem'>
            {props.loading ? <LinearProgress /> : <>
                <Text textAlign="center" textSize="title" textColor={COLORS.servicePartnerPrimary} m={{ b: "0.3em" }}>
                    Enter OTP
                </Text>
                <OtpInput
                    className="ns-otp"
                    value={props.otp}
                    onChange={props.onChangeOTP}
                    numInputs={6}
                    separator={<span></span>}
                />
                <Text textAlign="center" textSize="tiny" textColor={COLORS.servicePartnerText} m={{ t: "1em" }}>
                    {props.timer.minutes} : {props.timer.seconds} : left for enter OTP.
                </Text>
                <Div d="flex" m={{ t: "1em" }}>
                    <Button
                        shadow="3"
                        hoverShadow="4"
                        disabled={props.otpTimeOut}
                        bg={COLORS.servicePartnerPrimary}
                        m={{ r: "1rem" }}
                        onClick={props.onClickApply}
                        isLoading={props.loading}
                    >
                        Confirm
                    </Button>
                    <Button
                        bg={COLORS.servicePartnerBtnGreen}
                        hoverBg={COLORS.servicePartnerBtnGreenHover}
                        textColor={COLORS.servicePartnerBtnGreenText}
                        // m={{ x: "1.5em" }}
                        onClick={props.onClickResend}
                        shadow="3"
                        hoverShadow="4"
                        flexGrow="4"
                    >
                        Resend OTP
                    </Button>
                </Div>
                <Text textSize="label" textColor={"red"} m={{ t: "0.8em" }}>
                    {props.errorMessage}
                </Text>
            </>}
        </Modal>
    )
}
import { Div, Row, Col, Text, Icon, Image, Modal } from "atomize";
import React from "react";
import { useFormik } from "formik";

import { InputText } from "../../components/ServicePartner/InputText";
import COLORS from "../../Themes/Colors";
import "./style.css";
import { NSButton } from "../../components/ServicePartner/Button";
import { loginExistingUserAsync } from "./Api/api";
import { store } from "./context/store";
import { useHistory } from "react-router";
import {ArrowRightOutlined}  from "@ant-design/icons";
export const Login = () => {
  const [loading, setLoading] = React.useState(() => false);
  const [errorMessage, setErrorMessage] = React.useState(() => "");
  const [showModal, setShowModal] = React.useState(() => false);
  const [modalMessage, setModalMessage] = React.useState("");

  const globalState = React.useContext(store);
  const { dispatch } = globalState;
  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      userName: "",
      password: "",
    },
    // validate,
    onSubmit: async (values) => {
      console.log("submitted", values);
      if (values.username !== "" && values.password !== "") {
        setLoading(true);
        const loginPayload = {
          username: values.username,
          password: values.password,
        };

        const loginUserRequest = await loginExistingUserAsync(loginPayload);
        if (!loginUserRequest.isSuccess) {
          setErrorMessage(
            loginUserRequest.message ||
              "Unable to login. Check your credentials."
          );
          setLoading(false);
          return;
        }

        if (Number(loginUserRequest.data.laststep) === 7) {
          setModalMessage(
            "We're are verifying your registration details. We will let you know once the verification is over."
          );
          setShowModal(true);
        } else if (Number(loginUserRequest.data.laststep) === 8) {
          setModalMessage("Your details has been successfully verified.");
          setShowModal(true);
        } else {
          dispatch({
            type: "setServicePartnerSignupState",
            payload: {
              ...globalState.state,
              isLoggedIn: true,
              partyID: loginUserRequest.data.party,
              lastStep: loginUserRequest.data.laststep,
              serviceCategory: loginUserRequest.data.role,
            },
          });
          history.push("/servicepartner/form");
        }
        setLoading(false);
      } else {
        setErrorMessage("Username / Password must not be blank");
      }
    },
  });

  const onCloseModal = () => {
    setShowModal(false);
  };

  return (
    <Row p={{ xs: "1em", lg: "2em" }} shadow="0 0 5px #ccc" rounded="md">
      <Col size="12">
        <Text
          tag="h1"
          textSize={{ xs: "display1", lg: "display3" }}
          m={{ b: "1em" }}
          textColor={COLORS.servicePartnerPrimary}
        >
          Service Awaiting For You
        </Text>
      </Col>
      <Col size="12">
        <Row>
          <Col size={{ xs: 12, lg: 5 }} order={{ xs: 2, lg: 1 }}>
            <Div p="1rem">
              <Text
                textSize={{ xs: "heading", lg: "display2" }}
                textColor={COLORS.servicePartnerPrimary}
                m={{ b: "0.8em" }}
              >
                Login NSURE360
              </Text>
              <form onSubmit={formik.handleSubmit}>
                <InputText
                  value={formik.values.username}
                  id="username"
                  placeholder="Enter number"
                  onChange={formik.handleChange}
                  error={
                    formik.touched.phoneNumber && formik.errors.phoneNumber
                  }
                />
                <InputText
                  value={formik.values.password}
                  type="password"
                  id="password"
                  placeholder="Enter password"
                  onChange={formik.handleChange}
                  error={formik.touched.password && formik.errors.password}
                />
               <NSButton
                  id="recaptcha-container"
                  type="submit"
                  loading={loading}
                  // icon={<Icon name="LongRight" size="16px" color="white" />}
                  style={{width:"70%",borderRadius:"3px"}}
                >
                  <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                    <div style={{marginLeft:"48px"}}>
                  {" "}
                  login{" "}</div> <div style={{background:"#3193ff",padding:"7px",height:"40px",textAlign:"center",width:"20%",marginRight:"-16px",borderRadius:"3px"}}> <ArrowRightOutlined/> </div>
                  </div>
                </NSButton>
                <Text textSize="caption" textColor={"red"} m={{ t: "0.8em" }}>
                  {errorMessage}
                </Text>
              </form>
            </Div>
          </Col>
          <Col
            m={{ xs: 0, lg: { t: "-10em" } }}
            size={{ xs: 12, lg: 7 }}
            order={{ xs: 1, lg: 2 }}
          >
            <Div p="1rem">
              <Image src={require("../../assets/images/Frame61.png")} />
            </Div>
          </Col>
        </Row>
      </Col>
      <Modal
        isOpen={showModal}
        onClose={onCloseModal}
        align="center"
        rounded="md"
      >
        <Div d="flex" m={{ b: "2rem" }}>
          <Icon
            name="Success"
            color="success700"
            m={{ t: "0.40rem", r: "0.5rem" }}
          />
          <Text p={{ l: "0.5rem", t: "0.25rem" }} textSize="subheader">
            {modalMessage}
          </Text>
        </Div>
      </Modal>
    </Row>
  );
};

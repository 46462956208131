import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { Toolbar, SideDrawer, Backdrop } from "./components";
import { useState } from "react";
import { useEffect } from "react";
import {
  Home,
  CorporateSolutions,
  InsuranceService,
  ClaimAdviceService,
  ServicePartner,
} from "./pages";
import Footer from "./components/Footer/Footer";
//import ToolbarForForm from "./components/Toolbar/ToolbarForForm";
const App = () => {
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);
  const drawerToggleClickHandler = () => {
    setSideDrawerOpen(!sideDrawerOpen);
  };

  const backdropClickHandler = () => {
    setSideDrawerOpen(false);
  };
  let backdrop;

  if (sideDrawerOpen) {
    backdrop = <Backdrop click={backdropClickHandler} />;
  }
  useEffect(() => {
    // console.log(sideDrawerOpen);
  }, [sideDrawerOpen]);
  return (
    <Router>
      {/* <Toolbar drawerClickHandler={drawerToggleClickHandler} /> */}
      <Switch>
        <Route path="/servicepartner" component={ServicePartner} />
        <Route path="/coporatesolutions" component={CorporateSolutions} />
        <Route
          exact
          path="/service/claimadvice"
          component={ClaimAdviceService}
        />
        <Route
          exact
          path="/service/buyinsurance"
          component={InsuranceService}
        />
        <Route
          exact
          path="/service/claimadvice/detail/:id"
          component={ClaimAdviceService}
        />
        <Route
          exact
          path="/service/buyinsurance/detail/:id"
          component={InsuranceService}
        />
        <Route path="/" render={() => {
          return (<>
            <Toolbar drawerClickHandler={drawerToggleClickHandler} />
            <SideDrawer
              show={sideDrawerOpen}
              backdropClickHandler={backdropClickHandler}
            />
            {backdrop}
            <Route path="/" exact component={Home} />
          </>)
        }} />

      </Switch>
      <Footer />
    </Router>
  );
};

export default App;

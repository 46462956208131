import React, { useState } from "react";
import { Div, Image } from "atomize";
import { Label, Button } from "../../../../components";
import "../../../../styles.css";
import "./ContactSection.css";
import axios from '../../../../utils/axios'
import './ContactSection.css'
import { validateEmail } from '../../../ServicePartner/Validations'
const DetailSection = () => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")

  function clearMessage() {
    setErrorMessage("")
    setSuccessMessage("")
  }

  const postDetails = async () => {
    await axios
      .post("/api/subscription/add/", {
        name: name,
        email: email,
      }).then((response) => {
        setSuccessMessage("Subscribed successfully!")
        setTimeout(clearMessage, 3000)
      }).catch((error) => {
        if (!validateEmail(email)) {
          setErrorMessage("Invalid email")
          setTimeout(clearMessage, 3000)
        }
        else if (name.length === 0) {
          setErrorMessage("Name required")
          setTimeout(clearMessage, 3000)
        } else {
          setErrorMessage("Email already registered")
          setTimeout(clearMessage, 3000)
        }
      })
  }
  // console.log("data to be posted is",saveDetailsApiStatus.error)
  // if(saveDetailsApiStatus.loading){
  //   return(
  //     <p style={{color:"red"}}>{saveDetailsApiStatus.error}</p>
  //   )
  // }
  return (
    <Div w="100%" d="flex" flexDir="column" justify="center" className="md-1">
      <Label
        label="Connect with us to get updates on future offerings."
        type="subHeader"
        style={{ m: { b: "10px" } }}
      />
      <Div
        d="flex"
        m={{ t: "18px" }}
        maxW="650px"
        flexDir={{ xs: "column", sm: "column", md: "row" }}
      >
        <input type="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="enter your name"
          style={{ border: "1px solid gray", borderRadius: "7px", marginRight: "3px", paddingLeft: "5px" }} />

        <input type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)} placeholder="enter your email"
          style={{ border: "1px solid gray", borderRadius: "7px", marginRight: "3px", paddingLeft: "10px" }} />
        <Button
          label="Submit"
          type="primary"
          style={{ m: { l: "5px" }, maxW: "110px" }}
          onPress={postDetails}
        />
      </Div>
      <p style={{ color: "#7cadf1", fontSize: ".9rem" }}>{successMessage}</p>
      <p style={{ color: "red", fontSize: ".9rem" }}>{errorMessage}</p>
    </Div>
  )
};

const DetailMobileSection = () => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")

  function clearMessage() {
    setErrorMessage("")
    setSuccessMessage("")
  }
  const postDetails = async () => {
    await axios
      .post("/api/subscription/add/", {
        name: name,
        email: email,
      }).then((response) => {
        setSuccessMessage("Subscribed successfully!")
        setTimeout(clearMessage, 3000)
      }).catch((error) => {
        if (!validateEmail(email)) {
          setErrorMessage("Invalid email")
          setTimeout(clearMessage, 3000)
        }
        else if (name.length === 0) {
          setErrorMessage("Name required")
          setTimeout(clearMessage, 3000)
        } else {
          setErrorMessage("Email already registered")
          setTimeout(clearMessage, 3000)
        }
      })
  }
  return (
    <Div w="100%" d="flex" flexDir="column" className="sm-1">
      <Label
        label="Connect with us to get updates on future offerings."
        type="subHeader"
        style={{ m: { b: "10px" } }}
      />
      <Div d="flex">
        <Div
          d="flex"
          justify="center"
          align="center"
          w="50%"
          maxW={{ xs: "200px", sm: "200px" }}
        >
          <Image
            src={require("../../../../assets/images/sec6.png")}
            maxW={{ xs: "150px", sm: "150px" }}
          />
        </Div>
        <Div
          d="flex"
          m={{ t: "18px" }}
          w="60%"
          maxW="400px"
          h="100%"
          flexDir={{ xs: "column", sm: "column", md: "row" }}
          justify="space-between"
          minH="150px"
        >

          <input type="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="enter your name"
            style={{ border: "1px solid gray", borderRadius: "7px", marginRight: "3px", paddingLeft: "5px", width: "100%" }} />
          <input type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)} placeholder="enter your email"
            style={{ border: "1px solid gray", borderRadius: "7px", marginRight: "3px", paddingLeft: "10px" }} />
          <p style={{ color: "#7cadf1", fontSize: ".9rem" }}>{successMessage}</p>
          <p style={{ color: "red", fontSize: ".9rem" }}>{errorMessage}</p>
          <Button label="Submit" type="primary" style={{ maxW: "110px" }} onPress={postDetails} />
        </Div>
      </Div>
    </Div>
  )
};

const ContactSection = () => {

  return (
    <div className="screen-padding">
      <Div
        d="flex"
        flexDir={{ xs: "column", sm: "column", md: "row" }}
        justify="space-between"
        m={{ t: { xs: "20px", sm: "20px", md: "75px" } }}
        w="100%"
        bg="#f7f7f7"
        p={{ y: "20px", x: "40px" }}
        align="center"
      >
        <DetailSection />
        <DetailMobileSection />
        <Div
          d="flex"
          justify="center"
          align="center"
          w="100%"
          className="md-1"
          maxW={{ lg: "500px", md: "250px", xl: "500px" }}
          m={{ l: "20px" }}
        >
          <Image
            src={require("../../../../assets/images/sec6.png")}
            maxW={{ lg: "300px", md: "200px", xl: "300px" }}
          />
        </Div>
      </Div>
    </div>
  );
};

export default ContactSection;

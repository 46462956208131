import React, { useEffect, useState, useContext } from "react";

import "./Filter.css";
import { Div, Image, Row, Col } from "atomize";
import { Label } from "..";
import Colors from "../../Themes/Colors";
import CheckBoxItem from "../CheckBox/CheckBox";
//import LocationSelect from "../LocationSelect/LocationSelect";
import RadioBoxItem from "../RadioBox/RadioBox";
//import Requests from "../../utils/requests";
//import axios from "../../utils/axios";
import { store } from "../../context/store";

// const SideDrawerHeader = ({ click }) => {
//   return (
//     <Div d="flex" justify="space-between" m={{ y: "25px" }}>
//       <Image
//         src={require("../../assets/images/back-arrow.png")}
//         maxW="13px"
//         maxH="22px"
//         cursor="pointer"
//         onClick={click}
//       />
//       <Label
//         label="Set filters"
//         type="bodyText1"
//         style={{
//           textAlign: "center",
//           m: { l: "85px" },
//           textColor: Colors.primaryColor,
//         }}
//       />
//       <span onClick={() => window.location.reload()}>
//         <Button
//           label="Clear"
//           type="secondary"
//           textType="menu"
//           style={{ w: "100px", borderColor: Colors.primaryColor }}
//         />
//       </span>
//     </Div>
//   );
// };

// const SelectedItem = ({ click, label }) => (
//   <Div
//     p={{ y: "5px", x: "20px" }}
//     d="flex"
//     align="center"
//     maxH="45px"
//     minH="40px"
//     border={"1px solid"}
//     rounded="sm"
//     justify="space-between"
//     borderColor={Colors.primaryColor}
//     m={{ y: "10px" }}
//     cursor="pointer"
//     onClick={click}
//   >
//     <Label
//       label={label}
//       type="bodyText3"
//       style={{ m: { l: "8px" }, w: "100%", textAlign: "left" }}
//     />
//     <Image
//       src={require("../../assets/images/close.png")}
//       maxW="20px"
//       maxH="20px"
//       cursor="pointer"
//     />
//   </Div>
// );

// UI for the dropdown list
export const FilterContent = ({
  title,
  items,
  filterType,
  buttonType,
  claimAndAsset,
}) => {
  const [currentClaims, setcurrentClaims] = useState([]);
  const [currentAssets, setCurrentAssets] = useState([]);
  const [currentServices, setCurrentServices] = useState([]);
  //const [selectedAssets, setSelectedAssets] = useState([]);
  const globalState = useContext(store);
  const { dispatch } = globalState;

  // Claim selection
  const claimsSelected = (id) => {
    const index = currentClaims.indexOf(id);

    if (index > -1) {
      const newList = currentClaims.filter((e) => e !== id);
      setcurrentClaims([...newList]);
    } else {
      currentClaims.push(id);
      setcurrentClaims([...currentClaims]);
    }
  };

  // Claim Asset selection
  const assetSelected = (id) => {
    const index = globalState.state.selectedClaimAssets.indexOf(id);

    if (index > -1) {
      const newList = globalState.state.selectedClaimAssets.filter(
        (e) => e !== id
      );
      setCurrentAssets([...newList]);
    } else {
      globalState.state.selectedClaimAssets.push(id);
      setCurrentAssets([...globalState.state.selectedClaimAssets]);
    }
  };

  // service selection
  const serviceSelected = (id) => {
    // console.log("selected");
    const index = currentServices.indexOf(id);

    if (index > -1) {
      const newList = currentServices.filter((e) => e !== id);
      setCurrentServices([...newList]);
    } else {
      currentServices.push(id);
      setCurrentServices([...currentServices]);
    }
  };

  // Save the state of selected claims
  useEffect(() => {
    dispatch({ type: "selectedClaims", payload: currentClaims });
  }, [
    dispatch,
    currentClaims,
    // globalState.state.selectedClaims,
  ]);

  // Save the state of selected claims
  useEffect(() => {
    //dispatch({ type: "selectedClaimAssets", payload: currentAssets });
  }, [
    dispatch,
    currentAssets,
    // globalState.state.selectedClaims,
  ]);

  // Save the help values
  useEffect(() => {
    dispatch({ type: "selectedHelps", payload: currentServices });
  }, [currentServices, dispatch]);

  // Repopulate the selected array list based on the chage in claim select
  useEffect(() => {
    if (globalState.state.selectedAdicerAssets === undefined) {
      dispatch({
        type: "selectedAdicerAssets",
        payload: [],
      });
    }
    if (claimAndAsset) {
      let uiAssets = [];
      let uiAssetsName = [];
      for (let i of claimAndAsset?.[1]) {
        uiAssets.push(i.asset.id);
        uiAssetsName.push(i.asset.name);
      }
      // eslint-disable-next-line
      const filteredArray = globalState.state.selectedClaimAssets.filter(
        (value) => uiAssets.includes(value)
      );
      // eslint-disable-next-line
      const filteredAssetNameArray = globalState.state.selectedAdicerAssets?.filter(
        (value) => uiAssetsName.includes(value)
      );
      // dispatch({
      //   type: "selectedAdicerAssets",
      //   payload: filteredAssetNameArray,
      // });
      //setCurrentAssets([...filteredArray]);
      //setSelectedAssets([filteredAssetNameArray]);
    }
  }, [globalState.state.claimTypeAssetsArray, claimAndAsset, dispatch, globalState.state.selectedAdicerAssets, globalState.state.selectedClaimAssets]);

  return (
    <Div m={{ y: "20px" }}>
      <Label
        label={title}
        type="title"
        style={{ textColor: Colors.primaryColor, m: { l: "15px", b: "10px" } }}
      />
      {filterType === "insuranceCompany" && (
        <Div
          p={{ x: "5px" }}
          d="flex"
          cursor="pointer"
          align="center"
          maxH="45px"
          m={{ t: "12px", b: "20px" }}
          minH="40px"
          border="1px 0px 1px 1px"
          borderColor="#a0a3c8"
          className="input-container"
          maxW={{ md: "370px" }}
        >
          <input className="location-input" placeholder="Search companies" />
          <Image
            src={require("../../assets/images/search.png")}
            maxW="25px"
            maxH="25px"
            cursor="pointer"
          />
        </Div>
      )}
      {filterType === "ClaimType" && (
        <Row>
          {claimAndAsset?.[0]?.map((item, i) => (
            <Col key={`key-${i}`} size="12">
              {buttonType === "radio" ? (
                <RadioBoxItem label={item?.name} />
              ) : (
                <CheckBoxItem
                  label={item.name}
                  action={claimsSelected}
                  selectedItems={
                    currentClaims.indexOf(item.id) > -1 ? true : false
                  }
                  id={item.id}
                />
              )}
            </Col>
          ))}
        </Row>
      )}
      {filterType === "ClaimAsset" && (
        <Row>
          {items?.map((item, i) => (
            <Col key={`key-${i}`} size="12">
              {buttonType === "radio" ? (
                <RadioBoxItem label={item.asset.name} />
              ) : (
                <CheckBoxItem
                  label={item.asset.name}
                  action={assetSelected}
                  selectedItems={
                    globalState.state.selectedClaimAssets.indexOf(
                      item.asset.id
                    ) > -1
                      ? true
                      : false
                  }
                  id={item.asset.id}
                />
              )}
            </Col>
          ))}
        </Row>
      )}
      {filterType === "ClaimHelp" && (
        <Row>
          {items.map((item, i) => (
            <Col key={`key-${i}`} size="12">
              {buttonType === "radio" ? (
                <RadioBoxItem label={item.name} />
              ) : (
                item.name && (
                  <CheckBoxItem
                    label={item.name}
                    action={serviceSelected}
                    selectedItems={
                      currentServices.indexOf(item.id) > -1 ? true : false
                    }
                    id={item.id}
                  />
                )
              )}
            </Col>
          ))}
        </Row>
      )}
      <Label label="Show Less-" type="bodyText3" />
    </Div>
  );
};

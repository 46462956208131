import React from "react";
// import { Button as ButtonAtom, Input } from "atomize";
// import { Label } from "..";
import "./TextBox.css";
//import Colors from "../../Themes/Colors";
// const customStyle = {
//   primary: {},
//   secondary: {
//     textSize: "12.5px",
//     fontFamily: "Montserrat-Bold",
//     textColor: Colors.primaryColor,
//     border: "1px solid",
//     borderColor: Colors.textMainColor,
//   },
// };

const TextBox = ({ style, type, trackValue, id, value }) => {
  let className = "contact input";

  if (type === "primary") {
    className = "primary input";
  } else {
    className = "contact input";
  }

  return (
    <input
      className={className}
      {...style}
      id={id}
      value={value}
      onChange={(e) => trackValue(id, e.target.value)}
    />
  );
};

export default TextBox;

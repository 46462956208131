import React from "react";
import { Div, Row, Col, Text } from "atomize";
import { LinearProgress, Chip } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";

import { MultipleSelect } from "../../components/ServicePartner/MultipleSelect";
import { NSButton } from "../../components/ServicePartner/Button";
import Colors from "../../Themes/Colors";
import { NSCheckBox } from "../../components/ServicePartner/Checkbox";
import { store } from "./context/store";
import {
  getServiceLineOfBusinessesAsync,
  getServiceCompaniesLobAsync,
  saveServiceCompaniesLobAsync,
  saveServiceLineOfBusinessesAsync,
  createServiceProfileAsync,
} from "./Api/api";
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";
export const InsuranceAgent = (props) => {
  const [selectedLob, setSelectedLob] = React.useState(() => []);
  const [selectedLobVals, setSelectedLobVals] = React.useState(() => []);

  const [serviceLobs, setServiceLobs] = React.useState(() => ({
    data: [],
    loading: false,
    error: "",
  }));

  const [selectedCompany, setSelectedCompany] = React.useState(() => []);
  const [serviceCompanies, setServiceCompanies] = React.useState(() => ({
    data: [],
    loading: false,
    error: "",
  }));
  const [saveDetailsApiStatus, setSaveDetailsApiStatus] = React.useState(
    () => ({
      error: "",
      loading: false,
    })
  );
  const { state } = React.useContext(store);

  React.useEffect(() => {
    async function getProfile() {
      const payload = {
        party: state.partyID,
      };
      setServiceLobs((prevState) => ({ ...prevState, loading: true }));
      const getServiceLobPromise = getServiceLineOfBusinessesAsync();
      const getSavedServiceLobPromise = saveServiceLineOfBusinessesAsync(payload);
      const getServiceCompaniesPromise = saveServiceCompaniesLobAsync(payload);
      Promise.all([
        getServiceLobPromise,
        getSavedServiceLobPromise,
        getServiceCompaniesPromise,
      ]).then((responses) => {
        if (
          !responses[0].isSuccess ||
          !responses[1].isSuccess ||
          !responses[2].isSuccess
        ) {
          setServiceLobs({
            data: [],
            error: "Unable to fetch data",
            loading: false,
          });
          return;
        }
        if (responses[0].isSuccess && responses[0].data.length) {
          setServiceLobs({
            data: responses[0].data,
            error: "",
            loading: false,
          });
        }
        if (responses[1].isSuccess && responses[1].data.lobs.length) {
          setSelectedLob(responses[1].data.lobs);
          setSelectedLobVals(responses[1].data.lobsMas);
        }
        if (responses[2].isSuccess && responses[2].data.length) {
          setSelectedCompany(responses[2].data);
        }
      });
    }
    getProfile();
  }, [state.partyID]);

  React.useEffect(() => {
    if (selectedLobVals.length > 0) {
      setServiceCompanies({ data: [], error: "", loading: true });
      const payload = {
        lob_id: selectedLobVals,
      };
      getServiceCompaniesLobAsync(payload).then((response) => {
        if (response.isSuccess) {
          setServiceCompanies({
            data: response.data,
            error: "",
            loading: false,
          });
        } else {
          setServiceCompanies({
            data: [],
            error: "Failed to fetch companies",
            loading: false,
          });
        }
      });
    }
  }, [selectedLobVals, selectedLob]);

  const onSubmit = (e) => {
    e.preventDefault();
    let validationError = false;
    if (!props.yearsExp) {
      validationError = true;
      setSaveDetailsApiStatus({
        error: "Please fill years of experience",
        loading: false,
      });
    } else if (typeof props.yearsExp != 'number' && isNaN(props.yearsExp)) {
      validationError = true;
      setSaveDetailsApiStatus({
        error: "Years of experience must be greater than 0 and less than 100.",
        loading: false,
      });
    } else if (props.yearsExp < 0 || props.yearsExp > 100) {
      validationError = true;
      setSaveDetailsApiStatus({
        error: "Years of experience must be greater than 0 and less than 100.",
        loading: false,
      });
    } else if (!props.selectedState) {
      validationError = true;
      setSaveDetailsApiStatus({
        error: "Please select state",
        loading: false,
      });
    } else if (!props.selectedCity) {
      validationError = true;
      setSaveDetailsApiStatus({
        error: "Please select city",
        loading: false,
      });
    } else if (selectedLob.length === 0) {
      validationError = true;
      setSaveDetailsApiStatus({
        error: "Please select line of businesses",
        loading: false,
      });
    } else if (selectedCompany.length === 0) {
      validationError = true;
      setSaveDetailsApiStatus({
        error: "Please select companies you are licenced to represent",
        loading: false,
      });
    }
    if (!validationError) {
      setSaveDetailsApiStatus({ error: "", loading: true });
      const serviceProfilePayload = {
        years_exp: props.yearsExp,
        party: state.partyID,
        city: props.selectedCity,
      };
      const saveProfileDetailsPromise = createServiceProfileAsync(
        serviceProfilePayload
      );
      const serviceLobPayload = {
        party: state.partyID,
        lob_type_id: selectedLob,
      };
      const saveServiceLobPromise =
        saveServiceLineOfBusinessesAsync(serviceLobPayload);
      const serviceCompaniesPayload = {
        party: state.partyID,
        companies: selectedCompany,
      };
      const saveServiceCompaniesPromise = saveServiceCompaniesLobAsync(
        serviceCompaniesPayload
      );

      Promise.all([
        saveProfileDetailsPromise,
        saveServiceLobPromise,
        saveServiceCompaniesPromise,
      ])
        .then((responses) => {
          if (
            responses[0].isSuccess &&
            responses[1].isSuccess &&
            responses[2].isSuccess
          ) {
            setSaveDetailsApiStatus({ error: "", loading: false });
            props.onClickNext();
          }
        })
        .catch((err) => {
          setSaveDetailsApiStatus({
            error: "Unable to update. Try again.",
            loading: false,
          });
        });
    }
  };

  const onClickBack = () => {
    props.onClickBack();
  };

  const onClickLob = (e) => {
    const clickedLob = parseInt(e.currentTarget.id);
    const clickedLobVal = parseInt(e.currentTarget.value);
    if (selectedLob) {
      const selectedLobIndex = selectedLob.indexOf(clickedLob);
      if (selectedLobIndex === -1) {
        setSelectedLob((prevState) => [...prevState, clickedLob]);
        setSelectedLobVals((prevState) => [...prevState, clickedLobVal]);
      } else {
        selectedLob.splice(selectedLobIndex, 1);
        setSelectedLob([...selectedLob]);
        selectedLobVals.splice(selectedLobIndex, 1);
        setSelectedLobVals([...selectedLobVals]);
      }
    }
  };
  const onChangeCompany = (e) => {
    if (e.target && e.target.value) {
      setSelectedCompany(e.target.value);
    }
  };
  const getLineOfBusinessMenu = () => {
    return (
      <Row w={"100%"} p={{ y: "1em", x: "2em" }} textSize={"12px"}>
        {serviceLobs.data.map((lob, index) => {
          return (
            <Col
              key={lob.id.toString()}
              size={{ xs: 12, lg: 6 }}
              p={{ y: "0.7em" }}
            >
              <NSCheckBox
                id={lob.id.toString()}
                checked={selectedLob.indexOf(lob.id) !== -1}
                onChange={onClickLob}
                value={lob.lob.toString()}
              />
              {lob.name}
            </Col>
          );
        })}
      </Row>
    );
  };
  const getCompaniesOptions = () => {
    return serviceCompanies.data.map((aCompany) => {
      return (
        <MenuItem key={`state-options-${aCompany.id}`} value={aCompany.id}>
          {aCompany.name}
        </MenuItem>
      );
    });
  };

  const renderSelectedCompanies = (selected) => {
    const selectedCompanies = serviceCompanies.data.filter(
      (aCompany) => selected.indexOf(aCompany.id) !== -1
    );
    return (
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        {selectedCompanies.map((company) => (
          <Chip
            key={company.id}
            label={company.name}
            style={{ margin: "5px" }}
          />
        ))}
      </div>
    );
  };
  return (
    <>
      <Col size={12}>
        <Text
          textSize={"subheader"}
          textColor={Colors.servicePartnerText}
          m={{ b: "1em" }}
        >
          Line of Business
        </Text>
        <Div d="flex" m={{ b: "3em" }} shadow={3}>
          {serviceLobs.loading ? (
            <LinearProgress style={{ width: "100%" }} />
          ) : (
            getLineOfBusinessMenu()
          )}
        </Div>
        <Text
          textSize={"subheader"}
          textColor={Colors.servicePartnerText}
          m={{ b: "0.2em" }}
        >
          Companies You Are Licenced to Represent
        </Text>
        <Div m={{ b: "1em" }}>
          <MultipleSelect
            value={selectedCompany}
            id="companies"
            multiple
            placeholder="Select companies"
            options={getCompaniesOptions()}
            renderValue={renderSelectedCompanies}
            onChange={onChangeCompany}
          // error={
          //   props.touched.companies &&
          //   (props.errors.companies || serviceCompanies.error)
          // }
          />
        </Div>
      </Col>
      <Col size={6}>
        <NSButton
          btntype="secondary"
          iconposition="left"
          style={{ width: "100%", borderRadius: "3px" }}
          // icon={<Icon name="LongLeft" size="16px" color="black" />}
          // maxW="100%"
          onClick={onClickBack}
        >
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div style={{ background: "#d5d8dc", padding: "7px", height: "40px", textAlign: "center", width: "33%", marginLeft: "-16px", borderRadius: "3px" }}> <ArrowLeftOutlined /> </div>
            <div style={{ marginRight: "20px" }}>
              Back</div>
          </div>
        </NSButton>
      </Col>
      <Col size={6}>
        <NSButton
          style={{ width: "100%", borderRadius: "3px" }}
          btntype="primary"
          onClick={onSubmit}
          // icon={<Icon name="LongRight" size="16px" color="white" />}
          minW="100%"
          loading={saveDetailsApiStatus.loading}
        >
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div style={{ marginLeft: "20px" }}>
              {" "}
              Next{" "}</div> <div style={{ background: "#3193ff", padding: "7px", height: "40px", textAlign: "center", width: "33%", marginRight: "-16px", borderRadius: "3px" }}> <ArrowRightOutlined /> </div>
          </div>
        </NSButton>
      </Col>
      <Text textSize="caption" textColor={"red"} m={{ t: "0.8em" }}>
        {saveDetailsApiStatus.error}
      </Text>
    </>
  );
};

import axios from "axios";
const token = process.env.REACT_APP_API_TOKEN;
//const token = "Custom 7WdbcJ8anQc8lsP8rOuvnb9p6ytqOlrtZNQSAMTuaLD83hRZZLxLpZ3EGhBB";
const URL = process.env.NODE_ENV !== 'development' ? process.env.REACT_APP_API_URL : process.env.REACT_APP_API_LOCAL_URL;

const instance = axios.create({
  baseURL: URL,
  headers: {
    'Authorization': `${token}`,
  },
});

export default instance;
import URLs from "./urls";
import axios from "axios";
//import { onResponse, onResponseError } from "./interceptor";

const getAccessToken = () => {
  return localStorage.getItem("access_token");
};

export const BASE_URL = process.env.REACT_APP_API_URL;
export const MEDIA_URL = process.env.REACT_APP_MEDIA_URL;
const instance = axios.create({
  baseURL: BASE_URL,
});

instance.interceptors.request.use((config) => {
  config.headers.post["Authorization"] = `Bearer ${getAccessToken()}`;
  config.headers.get["Authorization"] = `Bearer ${getAccessToken()}`;
  return config;
});

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { response, config } = error;
    if (response.status === 401) {
      try {
        //try refreshing token
        const refreshTokenAsync = await refreshToken();
        if (refreshTokenAsync.isSuccess) {
          config.headers["Authorization"] = `Bearer ${getAccessToken()}`;
          return instance(config);
        }
      } catch (err) {
      }
    }
    return error;
  }
);

const instanceWithCustomToken = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Authorization: `Custom 7WdbcJ8anQc8lsP8rOuvnb9p6ytqOlrtZNQSAMTuaLD83hRZZLxLpZ3EGhBB`,
  },
});

export const registerUserAsync = (payload) => {
  return instanceWithCustomToken
    .post(URLs.registerUser, payload)
    .then((response) => {
      if (response.status !== 201) {
        return {
          isSuccess: false,
          data: {},
        };
      }
      return {
        isSuccess: true,
        data: response.data.data,
      };
    })
    .catch((err) => {
      let erroMessage = undefined;
      if (err.response.data.username) {
        erroMessage = `${err.response.data.username?.[0]}`;
      } else if (err.response.data.password) {
        erroMessage = `Password: ${err.response.data.password?.[0]}`;
      }
      return {
        isSuccess: false,
        message: erroMessage,
        data: {},
      };
    });
};

export const loginUserAsync = (payload) => {
  return instanceWithCustomToken
    .post(URLs.loginUser, payload)
    .then((response) => {
      if (response.status !== 200) {
        return {
          isSuccess: false,
          data: {},
        };
      }
      localStorage.setItem("access_token", response.data.data.access_token);
      localStorage.setItem("refresh_token", response.data.data.refresh_token);
      return {
        isSuccess: true,
        data: response.data.data,
      };
    })
    .catch((err) => {
      return {
        isSuccess: false,
        data: {},
      };
    });
};

export const loginExistingUserAsync = (payload) => {
  return instanceWithCustomToken
    .post(URLs.loginExistingUser, payload)
    .then((response) => {
      localStorage.setItem("access_token", response.data.data.access_token);
      localStorage.setItem("refresh_token", response.data.data.refresh_token);
      localStorage.setItem("party", response.data.data.party);
      localStorage.setItem("lastStep", response.data.data.laststep);
      localStorage.setItem("serviceCategory", response.data.data.role);
      return {
        isSuccess: true,
        data: response.data.data,
      };
    })
    .catch((err) => {
      return {
        isSuccess: false,
        message: err.response.data.message || "Unable to login. Try again",
        data: {},
      };
    });
};

export const generatePartyAsync = (payload) => {
  return instance
    .post(URLs.generateParty, payload)
    .then((response) => {
      if (!response.data.success) {
        throw Error("Failed to login");
      }
      return {
        isSuccess: true,
        data: response.data.data,
      };
    })
    .catch((err) => {
      return {
        isSuccess: false,
        data: "",
      };
    });
};

export const refreshToken = () => {
  const payload = {
    refresh: localStorage.getItem("refresh_token"),
  };
  return instance
    .post(URLs.refreshToken, payload)
    .then((response) => {
      localStorage.setItem("access_token", response.data.access);
      return {
        isSuccess: true,
        data: {},
      };
    })
    .catch((err) => {
      return {
        isSuccess: false,
        data: {},
      };
    });
};

export const createProfileAsync = (payload) => {
  return refreshToken()
    .then(() => {
      return instance
        .post(URLs.createProfile, payload)
        .then((response) => {
          // if (response.data.success !== "true") {
          //   throw Error("Failed to Create profile");
          // }
          return {
            isSuccess: true,
            data: response.data.data,
          };
        })
        .catch((err) => {
          return {
            isSuccess: false,
            data: {},
          };
        });
    })
    .catch((err) => {
      return {
        isSuccess: false,
        data: {},
      };
    });
};

export const createServiceProfileAsync = (payload) => {
  return instance
    .post(URLs.serviceProfile, payload)
    .then((response) => {
      if (!response.data.success) {
        throw Error("Failed to Create service profile");
      }
      return {
        isSuccess: true,
        data: response.data.data,
      };
    })
    .catch((err) => {
      return {
        isSuccess: false,
        data: {},
      };
    });
};

export const getServiceStatesAsync = (payload) => {
  return instance
    .post(URLs.getStates, payload)
    .then((response) => {
      if (response.data.success !== "True") {
        throw Error("Failed to fetch states data");
      }
      return {
        isSuccess: true,
        data: response.data.data,
      };
    })
    .catch((err) => {
      return {
        isSuccess: false,
        data: {},
      };
    });
};

export const getServiceCitiesAsync = (payload) => {
  return instance
    .post(URLs.getCities, payload)
    .then((response) => {
      if (response.data.success !== "True") {
        throw Error("Failed to fetch cities data");
      }
      return {
        isSuccess: true,
        data: response.data.data,
      };
    })
    .catch((err) => {
      return {
        isSuccess: false,
        data: {},
      };
    });
};

export const getCitiesFromState = (payload) => {
  return instance
    .post(URLs.getCitiesFromState, payload)
    .then((response) => {
      if (response.data.success !== "True") {
        throw Error("Failed to fetch cities data");
      }
      return {
        isSuccess: true,
        data: response.data.data,
      };
    })
    .catch((err) => {
      return {
        isSuccess: false,
        data: {},
      };
    });
};

export const getServiceLineOfBusinessesAsync = () => {
  return instance
    .get(URLs.getLineOfBusinesses)
    .then((response) => {
      if (!response.data.success) {
        throw Error("Failed to fetch lob data");
      }
      return {
        isSuccess: true,
        data: response.data.data,
      };
    })
    .catch((err) => {
      return {
        isSuccess: false,
        data: {},
      };
    });
};

export const saveServiceLineOfBusinessesAsync = (payload) => {
  return instance
    .post(URLs.saveLineOfBusinesses, payload)
    .then((response) => {
      if (!response.data.success) {
        throw Error("Failed to save lob data");
      }
      return {
        isSuccess: true,
        data: response.data.data,
      };
    })
    .catch((err) => {
      return {
        isSuccess: false,
        data: {},
      };
    });
};

export const getServiceCompaniesLobAsync = (payload) => {
  return instance
    .post(URLs.getCompaniesLob, payload)
    .then((response) => {
      if (!response.data.success) {
        throw Error("Failed to fetch companies lob data");
      }
      return {
        isSuccess: true,
        data: response.data.data,
      };
    })
    .catch((err) => {
      return {
        isSuccess: false,
        data: {},
      };
    });
};

export const saveServiceCompaniesLobAsync = (payload) => {
  return instance
    .post(URLs.saveCompaniesLob, payload)
    .then((response) => {
      if (!response.data.success) {
        throw Error("Failed to save companies lob data");
      }
      return {
        isSuccess: true,
        data: response.data.data,
      };
    })
    .catch((err) => {
      return {
        isSuccess: false,
        data: {},
      };
    });
};

export const getOrSaveAgencyDetails = (payload) => {
  return instance
    .post(URLs.agencyLicenceDetails, payload, {
      headers: { "content-type": "multipart/form-data" },
    })
    .then((response) => {
      return {
        isSuccess: true,
        data: response.data.data,
      };
    })
    .catch((err) => {
      return {
        isSuccess: false,
        data: {},
      };
    });
};

export const getOrSaveCommunicationDetails = (payload) => {
  return instance
    .post(URLs.communicationDetails, payload)
    .then((response) => {
      return {
        isSuccess: true,
        data: response.data.data,
      };
    })
    .catch((err) => {
      return {
        isSuccess: false,
        data: {},
      };
    });
};

export const getPincodeAsync = (payload) => {
  return instance
    .post(URLs.pincode, payload)
    .then((response) => {
      return {
        isSuccess: true,
        data: response.data.data,
      };
    })
    .catch((err) => {
      return {
        isSuccess: false,
        data: {},
      };
    });
};

export const getOrSaveWhatsAppNumber = (payload) => {
  return instance
    .post(URLs.whatsAppNumber, payload)
    .then((response) => {
      return {
        isSuccess: true,
        data: response.data.data,
      };
    })
    .catch((err) => {
      return {
        isSuccess: false,
        data: {},
      };
    });
};

export const getOrSaveContactEmail = (payload) => {
  return instance
    .post(URLs.contactEmail, payload)
    .then((response) => {
      return {
        isSuccess: true,
        data: response.data.data,
      };
    })
    .catch((error) => {
      return {
        isSuccess: false,
        data: {},
      };
    });
};

export const getAvailableKycDocuments = () => {
  return instance
    .get(URLs.availableKycDocuments)
    .then((response) => {
      return {
        isSuccess: true,
        data: response.data.data,
      };
    })
    .catch((err) => {
      return {
        isSuccess: false,
        data: {},
      };
    });
};
export const getOrSaveKycDetails = (payload) => {
  return instance
    .post(URLs.kycDetails, payload)
    .then((response) => {
      return {
        isSuccess: true,
        data: response.data.data,
      };
    })
    .catch((err) => {
      return {
        isSuccess: false,
        data: {},
      };
    });
};

export const getAvailableClaimAdvisorLobAsync = () => {
  return instance
    .get(URLs.claimAdvisorLob)
    .then((response) => {
      return {
        isSuccess: true,
        data: response.data.results,
      };
    })
    .catch((err) => {
      return {
        isSuccess: false,
        data: {},
      };
    });
};
export const getAvailableClaimAdvisorServicesAsync = () => {
  return instance
    .get(URLs.claimAdvisorService)
    .then((response) => {
      return {
        isSuccess: true,
        data: response.data.results,
      };
    })
    .catch((err) => {
      return {
        isSuccess: false,
        data: {},
      };
    });
};
export const getOrSaveClaimAdvisorLobAsync = (payload) => {
  return instance
    .post(URLs.getOrSaveClaimAdvisorLob, payload)
    .then((response) => {
      const data = Array.isArray(response.data.data) ? response.data.data : [];
      return {
        isSuccess: true,
        data: data,
      };
    })
    .catch((err) => {
      return {
        isSuccess: false,
        data: {},
      };
    });
};

export const getOrSaveClaimAdvisorServicesAsync = (payload) => {
  return instance
    .post(URLs.getOrSaveclaimAdvisorService, payload)
    .then((response) => {
      const data = Array.isArray(response.data.data) ? response.data.data : [];
      return {
        isSuccess: true,
        data: data,
      };
    })
    .catch((err) => {
      return {
        isSuccess: false,
        data: {},
      };
    });
};

export const getOrSaveServiceCategoryAsync = (payload) => {
  return instance
    .post(URLs.serviceCategory, payload)
    .then((response) => {
      return {
        isSuccess: true,
        data: response.data.data,
      };
    })
    .catch((err) => {
      return {
        isSuccess: false,
        data: {},
      };
    });
};

export const getPreviewDetailsAsync = (partyID) => {
  return instance
    .get(`${URLs.getPreviewDetails}${partyID}/`)
    .then((response) => {
      return {
        isSuccess: true,
        data: response.data.data,
      };
    })
    .catch((err) => {
      return {
        isSuccess: false,
        data: {},
      };
    });
};

export const getOrSavePhoneNumberAsync = (payload) => {
  return instance
    .post(URLs.phoneNumber, payload)
    .then((response) => {
      return {
        isSuccess: true,
        data: response.data.data,
      };
    })
    .catch((err) => {
      return {
        isSuccess: false,
        data: {},
      };
    });
};

export const saveAgencyDetails = (payload) => {
  return fetch(URLs.agencyLicenceDetails, {
    method: "POST",
    body: payload,
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      "content-type": "multipart/form-data",
    },
  })
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      return {
        isSuccess: true,
        data: response.data.data,
      };
    })
    .catch((err) => {
      return {
        isSuccess: false,
        data: {},
      };
    });
};

export const sendForVerification = (payload) => {
  return instance
    .post(URLs.verifyDetails, payload)
    .then((response) => {
      return {
        isSuccess: true,
        data: response.data.message,
      };
    })
    .catch((err) => {
      return {
        isSuccess: false,
        data: {},
      };
    });
};

//check phone number exists in the database
export const phoneNumberExists = (phoneNumber) => {
  return instanceWithCustomToken
    .get(`${URLs.phoneNumberExists}${phoneNumber}/`)
    .then((response) => {
      return {
        isSuccess: true,
        data: response.data.data,
        status: response.status
      };
    })
    .catch((err) => {
      const result = {}
      result['isSuccess'] = false;
      result['data'] = {}
      result['status'] = err.response ? err.response.status : 500
      return result
    });
};

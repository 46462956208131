import axiosdefault from "axios";

const getPincodeFromGoogleResponse = (googleResponse) => {
  if (!googleResponse || !googleResponse.address_components) return null;
  for (let addrComp of googleResponse.address_components) {
    if (addrComp.types.length > 0 && addrComp.types[0] === 'postal_code') {
      return addrComp.long_name;
    }
  }
  return null;
}

export const getPincodeForLocation = async (location) => {
  let lati = location.coords.latitude;
  let longi = location.coords.longitude;
  if (lati < 8.4 || lati > 37.6 || longi < 68.7 || longi > 97.25) {
    lati = 28.6327; longi = 77.2196;
  }
  const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lati},${longi}&key=${process.env.REACT_APP_GOOGLE_API}`;
  try {
    return await axiosdefault.get(url).then((response) => {
      const result = response.data;
      let pincode = getPincodeFromGoogleResponse(result?.results[0]);
      return pincode;
    }).catch((e) => {
      console.log('Excepton occured --- From axios.fetchGoogleMapsAPi', e);
      return null;
    });
  }
  catch (e) {
    console.log('Exception from GoogleMapsAPi', e)
    return null;
  }
}


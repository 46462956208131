import React from "react";
import { Div, Image } from "atomize";
import { Label } from "..";
import "./LocationSelect.css";
import Colors from "../../Themes/Colors";

const LocationSelect = ({ label, type, passedFunction }) => {

  return (
    <Div m={{ b: "30px" }}>
      <Div
        p={type === "filter" ? { y: "5px", x: "13px" } : { y: "3px" }}
        d="flex"
        align="center"
        maxH="45px"
        minH="40px"
        border={type === "filter" ? "1px solid" : "0"}
        borderColor={Colors.primaryColor}
      >
        <Image
          src={require("../../assets/images/location.png")}
          maxW="25px"
          maxH="25px"
          cursor="pointer"
        />
        <Label
          label={label}
          type="bodyText3"
          style={{ m: { l: "8px" } }}
        />
      </Div>

      <Div
        p={{ x: "5px" }}
        d="flex"
        cursor="pointer"
        align="center"
        maxH="45px"
        m={type === "filter" ? { y: "15px" } : { y: "0" }}
        minH="40px"
        border="1px 0px 1px 1px"
        borderColor="#a0a3c8"
        className="input-container"
        maxW={{ md: "500px" }}
      >
        <input
          className="location-input"
          placeholder="Locality/pincode"
          onChange={(e) => passedFunction(e.target.value)}
        />
        <Image
          src={require("../../assets/images/search.png")}
          maxW="25px"
          maxH="25px"
          cursor="pointer"
        />
      </Div>
    </Div>
  );
};

export default LocationSelect;

let timerOn = true;

function Timer(remaining, timerId) {
  var m = Math.floor(remaining / 60);
  var s = remaining % 60;

  m = m < 10 ? "0" + m : m;
  s = s < 10 ? "0" + s : s;
  try {
    document.getElementById("timer").innerHTML = m + ":" + s;
    remaining -= 1;

    if (remaining >= 0 && timerOn) {
      setTimeout(function () {
        Timer(remaining);
      }, 1000);
      return;
    }

    if (!timerOn) {
      // Do validate stuff here
      return;
    }
  } catch (e) {}
}

export default Timer;

// Timer(120);

import React from "react";
import { Upload, Button } from "antd";
import { Image } from "atomize";
import styled from "styled-components";

export const CustomUpload = styled((props) => <Upload {...props} />)`
  width: 100%;
  height: 42px;
  .ant-upload {
    width: 100% !important;
  }
  .ant-btn {
    width: 100%;
  }
  button {
    height: 38px;
    span {
      margin-top: auto;
      margin-bottom: auto;
    }
  }
  img {
    object-fit: contain;
  }
`;
export const NSUpload = ({ onChange, defaultFile, label, fileLabel }) => {
  const upload = React.useRef(null);
  // const getDefaultFile = () => {
  //   if (defaultFile) {
  //     return [
  //       {
  //         name: "licence",
  //         status: "done",
  //         url: defaultFile,
  //       },
  //     ];
  //   }
  //   return [];
  // };
  console.log("nsupload component mounted", defaultFile);
  const onChangUpload = (e) => {
    console.log("file changed", e.currentTarget.value);
    onChange(e.currentTarget.files[0]);
  };
  // const props = {
  //   onRemove: (file) => {
  //     this.setState((state) => {
  //       const index = state.fileList.indexOf(file);
  //       const newFileList = state.fileList.slice();
  //       newFileList.splice(index, 1);
  //       return {
  //         fileList: newFileList,
  //       };
  //     });
  //   },
  //   beforeUpload: (file) => {
  //     console.log("file:", file);
  //     onChange(file);
  //     return false;
  //   },
  //   maxCount: 1,
  //   // defaultFileList: getDefaultFile(),
  // };
  return (
    <>
      <input
        ref={upload}
        accept="image/*"
        className="d-none"
        id="contained-button-file"
        multiple
        type="file"
        onChange={onChangUpload}
      />
      <Button
        style={{
          width: "100%",
          height: "56px",
        }}
      >
        <label
          htmlFor="contained-button-file"
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            height: "100%",
            alignItems: "center",
          }}
        >
          <Image
            w={"20px"}
            p={10}
            style={{ objectFit: "contain" }}
            src={require("../../assets/images/document.png")}
          />
          {label || "Upload"}
        </label>
      </Button>
      {defaultFile ? (
        <a rel="noopener noreferrer" target="_blank" href={defaultFile}>
          {fileLabel || "file"}
        </a>
      ) : (
        ""
      )}
    </>
  );
};

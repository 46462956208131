import React from 'react'
import { Stepper, Step, StepLabel, StepConnector, MobileStepper } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import COLORS from '../../Themes/Colors'
import { BrowserView, MobileView } from "react-device-detect";
import "./style.css"

const QontoConnector = withStyles({
    alternativeLabel: {
        top: 10,
        left: 'calc(-50% + 10px)',
        right: 'calc(50% + 10px)',
    },
    active: {
        '& $line': {
            borderColor: COLORS.servicePartnerPrimary,
        },
    },
    completed: {
        '& $line': {
            borderColor: COLORS.servicePartnerPrimary,
        },
    },
    line: {
        borderColor: '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
})(StepConnector);

const CustomMobileStepper = withStyles({
    positionStatic: {
        width: '100%',
    },
    progress: {
        width: '100%',
    }
})(MobileStepper)

const useQontoStepIconStyles = makeStyles({
    root: {
        color: '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center',
    },
    active: {
        color: '#784af4',
    },
    circle: {
        width: 20,
        height: 20,
        borderRadius: '50%',
        backgroundColor: '#eaeaf0',
        fontSize: 10,
        lineHeight: '20px',
        textAlign: 'center',
        color: 'gray'
    },
    completed: {
        backgroundColor: COLORS.servicePartnerPrimary,
        zIndex: 1,
        width: 20,
        height: 20,
        borderRadius: '50%',
        fontSize: 10,
        lineHeight: '20px',
        textAlign: 'center'
    },
});


const QontoStepIcon = (props) => {
    const classes = useQontoStepIconStyles();
    const { active, completed, icon } = props;
    return (
        <div
            className={classes.root}
        >
            <div className={completed || active ? classes.completed : classes.circle} >{icon} </div>
        </div>
    );
}

export const NSStepper = (props) => {
    return (
        <>
            <BrowserView>
                <Stepper activeStep={props.activeStep} alternativeLabel connector={<QontoConnector />}>
                    {props.steps.map((label) => (
                        <Step key={label}>
                            <StepLabel StepIconComponent={QontoStepIcon}><span className="container">{label}</span></StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </BrowserView>
            <MobileView>
                <CustomMobileStepper
                    variant="progress"
                    steps={6}
                    position="static"
                    activeStep={props.activeStep}
                    nextButton={<span className="ns-stepper-label container">{props.activeStep}/7</span>}
                // style={{maxWidth: '100%', flexGrow: 1}}
                />
            </MobileView>
        </>
    )
}

import React from "react";
import { Row, Col, Input, Text } from "atomize";
import Colors from "../../../Themes/Colors";

export const AddressDetails = ({
  country = "India",
  pincode = "",
  buildingNo = "",
  street = "",
  city = "",
  state = "",
}) => {
  return (
    <Row>
      <Col size={12} p={{ t: "2em", b: "1em" }}>
        <Text
          textSize={{ xs: "subheader", lg: "subheader" }}
          textColor={Colors.servicePartnerPrimary}
        >
          Address Details
        </Text>
      </Col>
      <Col size={4} p={{ y: "0.5em" }}>
        Country
      </Col>
      <Col size={8} p={{ y: "0.5em" }}>
        <Input
          value={country}
          placeholder={"Country"}
          h={"1.8rem"}
          disabled={true}
        />
      </Col>
      <Col size={4} p={{ y: "0.5em" }}>
        Postal / Zipcode
      </Col>
      <Col size={8} p={{ y: "0.5em" }}>
        <Input
          value={pincode}
          placeholder={"Pincode"}
          h={"1.8rem"}
          disabled={true}
        />
      </Col>
      <Col size={4} p={{ y: "0.5em" }}>
        Building / Door Number
      </Col>
      <Col size={8} p={{ y: "0.5em" }}>
        <Input
          value={buildingNo}
          placeholder={"Building Number"}
          h={"1.8rem"}
          disabled={true}
        />
      </Col>
      <Col size={4} p={{ y: "0.5em" }}>
        Street Address
      </Col>
      <Col size={8} p={{ y: "0.5em" }}>
        <Input
          value={street}
          placeholder={"Street"}
          h={"1.8rem"}
          disabled={true}
        />
      </Col>
      <Col size={4} p={{ y: "0.5em" }}>
        City
      </Col>
      <Col size={8} p={{ y: "0.5em" }}>
        <Input
          value={city}
          placeholder={"City"}
          h={"1.8rem"}
          disabled={true}
        />
      </Col>
      <Col size={4} p={{ y: "0.5em" }}>
        State
      </Col>
      <Col size={8} p={{ y: "0.5em" }}>
        <Input
          value={state}
          placeholder={"State"}
          h={"1.8rem"}
          disabled={true}
        />
      </Col>
    </Row>
  );
};

import { Div, Text } from "atomize";
import {  Select, FormControl } from "@material-ui/core";
import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import COLORS from "../../Themes/Colors";
import "./style.css";

const useStyles = makeStyles((theme) => ({
  formControl: {
    //   margin: theme.spacing(1),
    minWidth: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const CustomSelect = withStyles({
  select: {
    backgroundColor: "white",
    paddingTop: "12px",
    paddingBottom: "11px",
    "&:focus": {
      backgroundColor: "white",
    },
  },
})(Select);

export const InputSelect = ({
  type,
  id,
  placeholder,
  onChange,
  caption,
  value,
  error,
  className,
  options,
  multiple = false,
}) => {
  const classes = useStyles();
    return (
    <Div m={{ y: "1.5rem" }} className={className || ""}>
      <Div className="input-container no-border">
        <Text className="input-label" textSize="caption">
          {placeholder}
        </Text>
        <FormControl variant="outlined" className={classes.formControl}>
          <CustomSelect
            id="userName"
            fullWidth
            placeholder="Enter name"
            shrink={"true"}
            value={value === undefined ? "" : value}
            multiple={multiple}
            onChange={onChange}
          >
            {/* <option value={"none"}>None</option> */}
            {options}
          </CustomSelect>
        </FormControl>
      </Div>
      <Text textSize="tiny" textColor={COLORS.servicePartnerPrimary}>
        {caption}
      </Text>
      <Text textSize="tiny" textColor="danger800">
        {error}
      </Text>
    </Div>
  );
};

import React from "react";
import { Row, Col, Input, Text } from "atomize";
import Colors from "../../../Themes/Colors";

export const ContactDetails = ({
  email = "",
  whatsApp = "",
  phonenumber = "",
}) => {
  return (
    <Row>
      <Col size={12} p={{ t: "2em", b: "1em" }}>
        <Text
          textSize={{ xs: "subheader", lg: "subheader" }}
          textColor={Colors.servicePartnerPrimary}
        >
          Contact Details
        </Text>
      </Col>
      <Col size={4} p={{ y: "0.5em" }}>
        Phone Number
      </Col>
      <Col size={8} p={{ y: "0.5em" }}>
        <Input
          value={phonenumber}
          placeholder={"Phone Number"}
          h={"1.8rem"}
          disabled={true}
        />
      </Col>
      <Col size={4} p={{ y: "0.5em" }}>
        WhatsApp Number
      </Col>
      <Col size={8} p={{ y: "0.5em" }}>
        <Input
          value={whatsApp}
          placeholder={"WhatsApp Number"}
          h={"1.8rem"}
          disabled={true}
        />
      </Col>
      <Col size={4} p={{ y: "0.5em" }}>
        Email ID
      </Col>
      <Col size={8} p={{ y: "0.5em" }}>
        <Input
          value={email}
          placeholder={"Email"}
          h={"1.8rem"}
          disabled={true}
        />
      </Col>
    </Row>
  );
};

import React from "react";
import { Div, Col, Row } from "atomize";

import Colors from "../../Themes/Colors";
import { store } from "../../pages/ServicePartner/context/store";
import "./style.css";

export const NSBanner = (props) => {
  const { state } = React.useContext(store);

  const getServiceCategoryName = () => {
    switch (Number(state.serviceCategory)) {
      case 2:
        return "Insurance agent";
      case 3:
        return "Claim Advisor";
      default:
        return "Unknown";
    }
  };
  return (
    <Div
      m={{ x: { xs: "0em", lg: "4em" }, b: { xs: "2em", lg: "5em" } }}
      p={{ y: { xs: "0.8em", lg: "0em" } }}
      bg={Colors.servicePartnerBanner}
      className="ns-banner"
    >
      <Row w="100%">
        <Col
          textSize={{ xs: "tiny", lg: "caption" }}
          size={{ xs: 12, lg: 6 }}
          className="ns-banner-item"
          justify={{ xs: "space-between", lg: "center" }}
        >
          <Div m={{ x: { lg: "1em" } }}>Party Id:</Div>
          {state.partyID}
        </Col>
        <Col
          textSize={{ xs: "tiny", lg: "caption" }}
        //   m={{ t: { lg: "0", xs: "2em" } }}
        //   size={{ xs: 12, lg: 6 }}
        //   className="ns-banner-item"
        //   justify={{ xs: "space-between", lg: "center" }}
        >
          <Div  m={{ t: { lg: "0", xs: "1em" } }} className="ns-banner-item"  justify={{ xs: "space-between", lg: "center" }}  size={{ xs: 12, lg: 6 }}>
            <Div m={{ x: { lg: "1em" } }}>Service Category:</Div>
            {getServiceCategoryName()}
          </Div>
        </Col>
      </Row>
    </Div>
  );
};

import React from "react";
import './SearchDropdown.css'
import { MapPin } from 'react-feather'
const SearchDropdown = ({ locationSelected, relatedLocations }) => {

  return (
    <div
      className="dropdown-content"
      id="locationContainer"
      style={
        relatedLocations.length > 0 ? { display: "block" } : { display: "none" }
      }
    >
      <div
        className="dropdown"
        id="locationDropdown"
        style={
          relatedLocations.length > 0
            ? { display: "block" }
            : { display: "none" }
        }
      >
        {relatedLocations.map((item, index) => {
          return (
            <div className="locations"
              key={`location-key-${index}`}>
              <div className="locations_data"
                onClick={() => {
                  // return locationSelected(item.pincode, item.name, item.id, item.taluk, item.district, item.state.name)
                  return locationSelected(item.pincode, item.name, item.id, item.district_city_town.name,
                    item.district_city_town.state.name, item.district_city_town.id)
                }}
              >
                <div className="location_name">
                  <div className="location_icon"><MapPin /></div>
                  <h1>{item.name}</h1>
                </div>
                {/* <div className="location_details">{`${item.taluk}, ${item.district}, ${item.state.name}, ${item.pincode}`}</div> */}
                <div className="location_details">
                  {`${item.district_city_town.name}, ${item.district_city_town.state.name}, ${item.pincode}`}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SearchDropdown;

import React from "react";
import { Div } from "atomize";
import { Label } from "../../../../components";
import "../../../../styles.css";
import "./DetailSection.css";
import Line from "../../../../components/Line/Line";

const DetailSection = () => {
  return (
    <Div
      d="flex"
      flexDir="column"
      justify="space-between"
      className="screen-padding"
      m={{ t: { xs: "20px", sm: "20px", md: "20px" } }}
      w="100%"
    >
      <Label
        label="Nsure360 - Technology with human touch."
        style={{ className: "detail-text" }}
        type="title"
      />
      <Label
        label="Understanding the nuances of an insurance policy in its current format is hard;getting hassle-free claims is harder. Nsure360 is an online marketplace to help customers to manage their insurance needs by finding right expert at a right place. All our experts are verified thru a rigorous application process to ensure that all our expert have the highest quality and sevice standards. Our aim is to ensure that our customer understand the nitty gritty of insurance and take the right decision with help of experts who know this business."
        type="bodyText3"
        style={{ m: { t: "20px" } }}
      />
      <Div m={{ t: "60px" }}>
        <Line />
      </Div>
    </Div>
  );
};

export default DetailSection;

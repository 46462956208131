import React from "react";
import { useEffect, useState } from "react";
import "./Footer.css";
import { Div, Image, Icon, Row, Col } from "atomize";
import Label from "../Label/Label";
import { useHistory } from "react-router-dom";
import Line from "../Line/Line";

const MenuItem = ({ label, onPress }) => {
  //const history = useHistory();
  return (
    <Div
      className="nav-menu"
      cursor="pointer"
      onClick={onPress}
      m={{ y: "25px" }}
    >
      <Icon name="Status" color="#0e3edf" size="15px" />
      <Label
        label={label}
        type="title"
        style={{ m: { l: "3px" }, textAlign: "left" }}
      />
    </Div>
  );
};

const Footer = () => {
  const history = useHistory();
  const [path, setPath] = useState(history.location.pathname);
  useEffect(() => {
    return history.listen((location) => {
      setPath(location.pathname);
    });
  }, [history]);

  return (
    <Div className="screen-padding" p={{ y: "50px" }}>
      {path === "/servicepartner/form" || path === "/servicepartner/preview" ? (
        ''
      ) : (
        <Row>
          <Col>
            <Div>
              <Image src={require("../../assets/images/logo.png")} maxW="180px" />
              <Label
                label="Insuryze Innovation Labs Private Limited"
                type="title2"
                style={{ m: { t: "20px" } }}
              />
              <Label
                label="Apt.3, Residency Parikarma"
                type="bodyText4"
                style={{ m: { t: "8px" } }}
              />
              <Label
                label="13/6, 14th Cross, Shastri Nagar"
                type="bodyText4"
                style={{ m: { t: "5px" } }}
              />
              <Label
                label="Adyar, Chennai"
                type="bodyText4"
                style={{ m: { t: "5px" } }}
              />
              <Label
                label="Tamilnadu - 600020"
                type="bodyText4"
                style={{ m: { t: "5px" } }}
              />
            </Div>
          </Col>
          <Col>
            <Div
              w="100%"
              d="flex"
              flexDir="column"
              justify="center"
              align="flex-end"
              h="100%"
            >
              <Div>
                <MenuItem label="Privacy Policy" />
                <MenuItem label="Terms and Condition" />
              </Div>
            </Div>
          </Col>
        </Row>
      )}
      <Div m={{ y: "30px" }}>
        <Line />
      </Div>
      <Div d="flex" justify="center">
        <Label
          label="Copyright @ 2020 insuryze Innovation Labs Pvt.Ltd."
          type="bodyText2"
        />
      </Div>
    </Div>
  );
};

export default Footer;

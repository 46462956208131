import React from "react";
import { Div } from "atomize";
import { Slider } from "../../../../components";
import "../../../../styles.css";
import "./SliderSection.css";

const images = [
  {
    image: require("../../../../assets/images/sec4.jpg"),
    label: "Nsure360",
    label2: "Technology with human touch",
  },
  {
    image: require("../../../../assets/images/sec4.jpg"),
    label: "Nsure360",
    label2: "Technology with human touch",
  },
];

const SliderSection = () => {
  return (
    <Div
      d="flex"
      flexDir={{ xs: "column", sm: "column", md: "row" }}
      justify="space-between"
      className="screen-padding"
      m={{ t: { xs: "20px", sm: "20px", md: "75px" } }}
      w="100%"
      align="center"
      p={{ y: "20px" }}
    >
      <Slider images={images} />
    </Div>
  );
};

export default SliderSection;

import React, { useState, Fragment } from "react";
import { Div, Row, Col, Text, Image } from "atomize";
import { LinearProgress } from "@material-ui/core";
import { MenuItem, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { InputText } from "../../components/ServicePartner/InputText";
import { InputSelect } from "../../components/ServicePartner/InputSelect";
import Colors from "../../Themes/Colors";
import { NSBanner } from "../../components/ServicePartner/Banner";
import { store } from "./context/store";
import { InsuranceAgent } from "./InsuranceAgent";
import { ClaimAdvisor } from "./ClaimAdvisor";
import {
  createServiceProfileAsync,
  //getServiceCitiesAsync,
  getServiceStatesAsync,
  getCitiesFromState,
  getPincodeAsync,
} from "./Api/api";

export const ServiceProfile = (props) => {
  const [yearsExp, setYearsExp] = useState(() => "1");
  // const [serviceProfile, setServiceProfile] = React.useState(() => {
  //   return {
  //     years_exp: 1,
  //     city: undefined,
  //     state: -1,
  //   };
  // });
  const [selectedState, setSelectedState] = React.useState(() => undefined);
  const [serviceStates, setServiceStatesList] = React.useState(() => ({
    data: [],
    loading: false,
    error: "",
  }));
  const [serviceCities, setServiceCities] = React.useState(() => ({
    data: [],
    loading: false,
    error: "",
  }));
  const [apiFailed, setApiFailed] = React.useState(false);

  const { state } = React.useContext(store);
  const [selectedCity, setSelectedCity] = React.useState(() => "");
  const [fetchDetailsApiStatus, setFetchDetailsApiStatus] = React.useState(
    () => ({
      error: "",
      loading: false,
    })
  );
  const [inputValue, setInputValue] = React.useState('');
  const [err, seterr] = useState("")

  React.useEffect(() => {
    const payload = {
      party: state.partyID,
    };
    async function getProfile() {
      setFetchDetailsApiStatus({ error: "", loading: true });
      const getProfileDetails = await createServiceProfileAsync(payload);
      if (getProfileDetails.isSuccess) {
        setYearsExp(getProfileDetails.data?.years_exp);
        setSelectedCity(getProfileDetails.data?.city);
        setSelectedState(getProfileDetails.data?.state);
        setFetchDetailsApiStatus({ error: "", loading: false });

        const pincodePayload = {
          city_id: getProfileDetails.data?.city
        }
        getPincodeAsync(pincodePayload)
          .then((response) => {
            if (response.isSuccess) {
              setInputValue(response.data[0].name + ', ' + response.data[0].taluk + ', ' + response.data[0].district + ', ' + response.data[0].pincode)
            } else {
              setInputValue('')
            }
          })
          .catch((err) => {
            setApiFailed(true);
            setFetchDetailsApiStatus({
              error: "Failed to fetch details",
              loading: false,
            });
          });

      } else {
        setApiFailed(true);
        setFetchDetailsApiStatus({
          error: "Failed to fetch details",
          loading: false,
        });
      }
      const getStatePayload = {
        country_id: 1,
      };
      setServiceStatesList((prevState) => ({ ...prevState, loading: true }));
      const getServiceStatesPromise = getServiceStatesAsync(getStatePayload);
      Promise.all([getServiceStatesPromise]).then((responses) => {
        if (responses[0].isSuccess) {
          setServiceStatesList({
            data: responses[0].data,
            error: "",
            loading: false,
          });
        } else {
          setServiceStatesList({
            data: [],
            error: "Unable to fetch data",
            loading: false,
          });
        }
      });
    }
    getProfile();
  }, [state.partyID]);
  React.useEffect(() => {
    console.log(apiFailed);
  }, [apiFailed]);
  // React.useEffect(() => {
  //   if (selectedState) {
  //     setServiceCities({ data: [], error: "", loading: true });
  //     const payload = {
  //       state_id: selectedState,
  //     };
  //     getServiceCitiesAsync(payload)
  //       .then((response) => {
  //         if (response.isSuccess) {
  //           setServiceCities({
  //             data: response.data,
  //             error: "",
  //             loading: false,
  //           });
  //         } else {
  //           setServiceCities({
  //             data: response.data,
  //             error: "Failed to fetch cities",
  //             loading: false,
  //           });
  //         }
  //       })
  //       .catch((err) => {
  //         setServiceCities({
  //           data: [],
  //           error: "Failed to fetch cities",
  //           loading: false,
  //         });
  //       });
  //   }
  // }, [selectedState]);

  if (fetchDetailsApiStatus.error) {
    return (
      <Text
        textAlign="center"
        textSize="title"
        textColor={"red"}
        m={{ t: "0.8em" }}
      >
        Failed to fetch data. Try again.
      </Text>
    );
  }

  if (fetchDetailsApiStatus.loading) {
    return <LinearProgress />;
  }

  const onChangeYearsExp = (e) => {
    setYearsExp(e.currentTarget.value);
  };

  const getStateOptions = () => {
    return serviceStates.data.map((aState) => {
      return (
        <MenuItem key={`state-options-${aState.id}`} value={aState.id}>
          {aState.name}
        </MenuItem>
      );
    });
  };

  // const getCitiesOptions = () => {
  //   return serviceCities.data.map((aCity) => {
  //     return (
  //       <MenuItem key={`state-options-${aCity.id}`} value={aCity.id}>
  //         {aCity.name}
  //       </MenuItem>
  //     );
  //   });
  // };

  const onChangeCity = (e, value, reason) => {
    if (reason === "select-option") {
      setSelectedCity(value.id);
      setInputValue(value.name + ', ' + value.taluk + ', ' + value.district + ', ' + value.pincode)
    }
    if (reason === "clear") {
      setSelectedCity('')
      setInputValue('')
    }
  };

  const onChangeState = (e) => {
    setSelectedState(e.target.value);
    setServiceCities({
      data: [],
      loading: false,
      error: "",
    })
    setSelectedCity('')
    setInputValue('')
  };


  const onSearchLocation = (e, value, reason) => {
    if (selectedState) {
      seterr("")
      if (reason === "input") {
        e.preventDefault()
        setInputValue(value);
        if (value.length >= 2) {
          setServiceCities({ data: [], error: "", loading: true });
          const payload = {
            state_id: selectedState,
            name: value
          };
          getCitiesFromState(payload)
            .then((response) => {
              if (response.isSuccess) {
                setServiceCities({
                  data: response.data,
                  error: "",
                  loading: false,
                });
              } else {
                setServiceCities({
                  data: response.data,
                  error: "Failed to fetch cities",
                  loading: false,
                });
              }
            })
            .catch((err) => {
              setServiceCities({
                data: [],
                error: "Failed to fetch cities",
                loading: false,
              });
            });
        }
      }
    } else {
      seterr("Please select a state")
    }
  };

  const getCategorySpecificForm = () => {
    switch (Number(state.serviceCategory)) {
      case 2:
        return (
          <InsuranceAgent
            selectedCity={selectedCity}
            selectedState={selectedState}
            yearsExp={yearsExp}
            onClickNext={props.onClickNext}
            onClickBack={props.onClickBack}
          />
        );
      case 3:
        return (
          <ClaimAdvisor
            selectedCity={selectedCity}
            selectedState={selectedState}
            yearsExp={yearsExp}
            onClickNext={props.onClickNext}
            onClickBack={props.onClickBack}
          />
        );
      default:
        return (
          <Text textSize="caption" textColor={"red"} m={{ t: "0.8em" }}>
            Unable to fetch service category. Try again.
          </Text>
        );
    }
  };

  return (
    <Row>
      <Col size={12}>
        <NSBanner
          partyID={state.partyID}
          serviceCategory={state.serviceCategory}
        />
      </Col>
      <Col size={{ xs: 12, lg: 6 }} order={{ xs: 2, lg: 1 }}>
        <Div p={{ x: "1rem" }}>
          <Text
            textSize={{ xs: "heading", lg: "heading" }}
            textColor={Colors.servicePartnerText}
          >
            Service Profile
          </Text>
          <Row>
            <Col size={12}>
              <InputText
                value={yearsExp}
                id="years_exp"
                placeholder="Years Of Service"
                onChange={onChangeYearsExp}
              // error={props.touched.yearsExp && props.errors.yearsExp}
              />
              <Text
                textSize={"subheader"}
                textColor={Colors.servicePartnerText}
                m={{ b: "1em" }}
              >
                Location Serviced
              </Text>
              {serviceStates.loading ? (
                <LinearProgress />
              ) : (
                <InputSelect
                  value={selectedState}
                  id="state"
                  placeholder="State"
                  options={getStateOptions()}
                  onChange={onChangeState}
                // error={
                //   props.touched.state &&
                //   (props.errors.state || serviceStates.error)
                // }
                />
              )}
              {/* {serviceCities.loading ? (
                <LinearProgress />
              ) : ( */}
              <Autocomplete
                value={selectedCity}
                onChange={onChangeCity}
                inputValue={inputValue}
                onInputChange={onSearchLocation}
                id="city"
                size="small"
                clearText={() => setInputValue('')}
                options={serviceCities.data}
                renderOption={option => <Fragment>{option.name}, {option.taluk}, {option.district}, {option.pincode}</Fragment>}
                getOptionLabel={option => option.name || ""}
                renderInput={(params) => <TextField {...params} variant="outlined" fullWidth label="Search Area/City" />}
              />
              <Text textSize="caption" textColor={"red"} m={{ t: "0.8em" }}>
                {err && err}
              </Text>
              {/* )} */}
            </Col>
            {getCategorySpecificForm()}
            {/* <ClaimAdvisor
              selectedCity={selectedCity}
              yearsExp={yearsExp}
              onClickNext={props.onClickNext}
            /> */}
          </Row>
          {/* </form> */}
          {/* )}
          </Formik> */}
        </Div>
      </Col>
      <Col
        m={{ xs: 0, lg: { t: "-10em" } }}
        size={{ xs: 12, lg: 6 }}
        order={{ xs: 1, lg: 2 }}
      >
        <Div p="1rem">
          <Image src={require("../../assets/images/Frame64.png")} />
        </Div>
      </Col>
    </Row>
  );
};

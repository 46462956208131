import React from "react";
import { Div, Row, Col, Text, Image, Label, Radiobox } from "atomize";
import { LinearProgress } from "@material-ui/core";
import { store } from "./context/store";
import Colors from "../../Themes/Colors";
import { NSButton } from "../../components/ServicePartner/Button";
import { useHistory } from "react-router";
import { getOrSaveServiceCategoryAsync } from "./Api/api";
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";
export const ServiceCategory = (props) => {
  const [category, setCategory] = React.useState(() => undefined);

  const [fetchDetailsApiStatus, setFetchDetailsApiStatus] = React.useState(
    () => ({
      error: "",
      loading: false,
    })
  );
  const [saveDetailsApiStatus, setSaveDetailsApiStatus] = React.useState(
    () => ({
      error: "",
      loading: false,
    })
  );
  const { state, dispatch } = React.useContext(store);
  const history = useHistory();

  React.useEffect(() => {
    const payload = {
      party: state.partyID,
    };
    async function getProfile() {
      setFetchDetailsApiStatus({ error: "", loading: true });
      const getServiceCategory = await getOrSaveServiceCategoryAsync(payload);
      if (getServiceCategory.isSuccess) {
        setCategory(getServiceCategory.data?.role);
        setFetchDetailsApiStatus({ error: "", loading: false });
      } else {
        setFetchDetailsApiStatus({
          error: "Failed to fetch details",
          loading: false,
        });
      }
    }
    getProfile();
  }, [state.partyID]);

  const onClickNext = async () => {
    if (category) {
      setSaveDetailsApiStatus({ error: "", loading: true });
      const payload = {
        party: state.partyID,
        role: category,
      };
      const getServiceCategory = await getOrSaveServiceCategoryAsync(payload);
      if (getServiceCategory.isSuccess) {
        dispatch({ type: "setServiceCategory", payload: category });
        setSaveDetailsApiStatus({ error: "", loading: false });
        props.onClickNext();
      } else {
        setSaveDetailsApiStatus({
          error: "Failed to fetch details",
          loading: false,
        });
      }
    } else {
      setSaveDetailsApiStatus({ error: "Select a category", loading: false });
    }
    // dispatch({ type: "setServiceCategory", payload: category });
    // props.onClickNext();
  };
  const onClickBack = () => {
    history.push("/servicepartner/signup");
  };

  const onChangeRadioBtn = (e) => {
    setCategory(Number(e.target.value));
  };
  if (fetchDetailsApiStatus.error) {
    return (
      <Text
        textAlign="center"
        textSize="title"
        textColor={"red"}
        m={{ t: "0.8em" }}
      >
        {fetchDetailsApiStatus.error}
      </Text>
    );
  }

  if (fetchDetailsApiStatus.loading) {
    return <LinearProgress />;
  }
  return (
    <Row>
      <Col size={{ xs: 12, lg: 6 }} order={{ xs: 2, lg: 1 }}>
        <Div p="1rem">
          <Text
            textSize={{ xs: "heading", lg: "display1" }}
            textColor={Colors.servicePartnerGray}
          >
            Service Category
          </Text>
          <Row>
            <Col size={12}>
              <Label
                align="center"
                textWeight="400"
                m={{ t: "3em" }}
                className="bordered-radio-btn"
              >
                <Radiobox
                  value={2}
                  checked={category === 2}
                  onChange={onChangeRadioBtn}
                />
                Insurance Agent
              </Label>
              <Label
                align="center"
                textWeight="400"
                m={{ t: "2em" }}
                className="bordered-radio-btn"
              >
                <Radiobox
                  value={3}
                  checked={category === 3}
                  onChange={onChangeRadioBtn}
                />
                Claim Advisor
              </Label>
            </Col>
            <Col size={6}>
              <Div m={{ t: "3em" }}>
                <NSButton
                  btntype="secondary"
                  iconposition="left"
                  style={{ width: "100%", borderRadius: "3px" }}
                  // icon={<Icon name="LongLeft" size="16px" color="black" />}
                  // minW="50%"
                  onClick={onClickBack}
                >
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div style={{ background: "#d5d8dc", padding: "7px", height: "40px", textAlign: "center", width: "33%", marginLeft: "-16px", borderRadius: "3px" }}> <ArrowLeftOutlined /> </div>
                    <div style={{ marginRight: "20px" }}>
                      Back</div>
                  </div>
                </NSButton>
              </Div>
            </Col>
            <Col size={6}>
              <Div m={{ t: "3em" }}>
                <NSButton
                  // icon={<Icon name="LongRight" size="16px" color="white" />}
                  style={{ width: "100%", borderRadius: "3px" }}
                  minW="50%"
                  loading={saveDetailsApiStatus.loading}
                  onClick={onClickNext}
                >
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div style={{ marginLeft: "20px" }}>
                      {" "}
                      Next{" "}</div> <div style={{ background: "#3193ff", padding: "7px", height: "40px", textAlign: "center", width: "33%", marginRight: "-16px", borderRadius: "3px" }}> <ArrowRightOutlined /> </div>
                  </div>
                </NSButton>
              </Div>
            </Col>
          </Row>
        </Div>
      </Col>
      <Col
        m={{ xs: 0, lg: { t: "-10em" } }}
        size={{ xs: 12, lg: 6 }}
        order={{ xs: 1, lg: 2 }}
      >
        <Div p="1rem">
          <Image src={require("../../assets/images/Frame62.png")} />
        </Div>
      </Col>
    </Row>
  );
};

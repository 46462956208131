import React from "react";

import "./SideDrawer.css";
import { Div, Image } from "atomize";
import { Button } from "../../components";
import Colors from "../../Themes/Colors";
import { useHistory } from "react-router-dom";

const SideDrawerHeader = ({ click }) => {
  return (
    <Div d="flex" justify="space-between" m={{ y: "25px" }}>
      <Image
        src={require("../../assets/images/close.png")}
        maxW="18px"
        maxH="18px"
        cursor="pointer"
        onClick={click}
      />
      <a href="/">
        <Image src={require("../../assets/images/logo.png")} maxW="100px" />
      </a>
    </Div>
  );
};
const SideDrawer = ({ show, backdropClickHandler }) => {
  let drawerClasses = "side-drawer";
  if (show) {
    drawerClasses = "side-drawer open";
  }
  const buttonStyle = {
    rounded: "0",
    m: { y: "15px" },
  };

  const history = useHistory();
  return (
    <nav className={drawerClasses}>
      <Div p={{ x: "20px" }}>
        <SideDrawerHeader click={backdropClickHandler} />
        <Div m={{ t: "60px" }}>
          <Button
            label="Become Our Service Partner"
            type="primary"
            textType="bold"
            style={buttonStyle}
            onPress={() => history.push("/servicepartner")}
          />
          <Button
            label="Corporate Solutions"
            type="primary"
            textType="bold"
            style={buttonStyle}
            onPress={() => history.push("/coporatesolutions")}
          />
          <Button
            label="I want to"
            label2="Buy Insurance"
            type="secondary"
            textType="menu"
            style={{ ...buttonStyle, borderColor: Colors.primaryColor }}
            onPress={() => history.push("/service/buyinsurance")}
          />
          <Button
            label="I need"
            label2="Claim Advice"
            type="secondary"
            textType="menu"
            style={{ ...buttonStyle, borderColor: Colors.primaryColor }}
            onPress={() => history.push("/service/claimadvice")}
          />
        </Div>
      </Div>
    </nav>
  );
};

export default SideDrawer;

import React, { useState, useEffect, useContext } from "react";
import { Div, Row, Col } from "atomize";
import { ServiceItem, Button, Slider, Label } from "../../components";
import FilterDropdown from "../../components/FilterDropdownClaim/FilterDropdown";
import { FilterContent } from "../../components/FilterClaim/Filter";
import Filter from "../../components/Filter/Filter";
//import { assetTypes, claims, advices } from "./data";
import LocationSelect from "../../components/LocationSelect/LocationSelect";
import FilterButton from "../../components/FilterButton/FilterButton";
import Colors from "../../Themes/Colors";
import ContactModal from "../../components/ContactModal/ContactModal";
import ServiceItemDetail from "../../components/ServiceItemDetail/ServiceItemDetail";
import { useHistory } from "react-router-dom";
import { BrowserView } from "react-device-detect";
//import ipLocation from "../../utils/services";
import { store } from "../../context/store";
import Requests from "../../utils/requests";
import SearchDrop from "../../components/SearchDropdown/SearchDropdown";
import SelectedDropdown from "../../components/SelectedDropdown/SelectedDropdown";
// import { HeaderSection } from "../Home/Sections/HeaderSection";
// import ContactSection from "../CorporateSolutions/ContactSection/ContactSection";
// import { AboutSection } from "../Home/Sections/AboutSection";
import { Toolbar } from "../../components";
import {
  setLocationForPosition, fetchDropdownList, locationSearch, showHideFilterList, fetchAdvisors, fetchDepDropdownList,
  filterAdvisors, locationfilter, searchOrFilter
} from "../../utils/commonfunctions";
//TODO: Google location api integration

const images = [
  {
    image: require("../../assets/images/sec4.jpg"),
    label: "Claim Advice",
    label3: "Find insurance advisors you can trust",
  }
];

const CountLabel = ({ count }) => (
  <span className="service-2-l-2">
    {count} <span className="service-2-l-1">{" results available"}</span>
  </span>
);
const ClaimAdviceService = ({ match }) => {
  const { id } = match.params;
  const [filterOpen, setFilterOpen] = useState(false);
  const globalState = useContext(store);
  const { dispatch } = globalState;
  const [currentLocation, setCurrentLocation] = useState("");
  const [search, setKeyword] = useState("");
  const [relatedLocations, setRelatedLocations] = useState([]);
  const [advisors, setAdvisors] = useState([]);
  const [claim, setClaim] = useState([]);
  const [claimAsset, setClaimAsset] = useState([]);
  const [selectedClaims, setSelectedClaims] = useState([]);
  const [service, setService] = useState([]);
  const [party, setParty] = useState(0);
  const [prevSelectedServices, setPrevSelectedServices] = useState(null);
  //const [advisorOrder, setAdvisorOrder] = useState(0);
  //const [filterNullCheck, setFilterNullCheck] = useState(0);
  //const [backupAdvisor, setBackupAdvisor] = useState([]);
  const [searchDropShow, setSearchDrop] = useState("none");
  const [selectedAdviceNames, setSelectedAdviceNames] = useState([]);
  //const [selectedAssetNames, setSelectedAssetNames] = useState([]);
  const [selectedhelpNames, setSelectedhelpNames] = useState([]);
  //const isInitialMount = React.useRef(true);
  const history = useHistory();

  // useEffect(() => {
  //   //setAdvisorOrder(globalState.state.nameOrder);
  // }, [history.location]);

  useEffect(() => {
    setSelectedAdviceNames(globalState.state.selectedAvicerNames);
  }, [globalState.state.selectedAvicerNames]);

  // useEffect(() => {
  //   //setSelectedAssetNames(globalState.state.selectedAdicerAssets);
  // }, [globalState.state.selectedAdicerAssets]);

  useEffect(() => {
    // console.log("selectedAssetName", selectedAssetName);
    setSelectedhelpNames(globalState.state.selectedHelpNames);
  }, [globalState.state.selectedHelpNames]);

  const item = {
    // image: require("../../assets/images/avatar.png"),
    // name: "Agent Name 1",
    // star: 4,
    // reviewedCustomer: 10,
    // serviceType: "Insurance Agent",
    // experience: "15 years of overall experience",
    address:
      globalState?.state?.providerDetails?.address?.[0].city?.state?.name,
    // features: [
    //   "General Insurance Personal",
    //   "Health Insurance",
    //   "General Insurance Commercial",
    // ],
    // verified: true,
  };
  // TODO: Replace the image url with the env variables
  const filterToggleClickHandler = () => {
    setFilterOpen(!filterOpen);
  };
  // Set the location name by ip/gps
  useEffect(() => {
    setLocationForPosition(globalState.state.selectedLocationPincode, dispatch);

    fetchDropdownList(Requests.fetchClaimTypes, setClaim, dispatch, 'claimTypes');

    if (filterOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [filterOpen, dispatch, globalState.state.selectedLocationPincode]);

  const backdropClickHandler = () => {
    setFilterOpen(false);
  };

  const [contactShow, setContactShow] = useState(false);

  const openContactModal = (party) => {
    setParty(party);
    setContactShow(true);
  };

  const closeContactModal = () => {
    setContactShow(false);
  };

  const redirectToDetail = (id) => {
    history.push(`/service/claimadvice/detail/${id}`);
  };

  // Set the selected location from the location search dropdown
  useEffect(() => {
    setCurrentLocation(globalState.state.selectedLocation);
  }, [globalState.state.selectedLocation]);

  const searchLocation = (location) => {
    setKeyword(location);
    if (location.length < 3) {
      setSearchDrop("none");
    } else {
      setSearchDrop("block");
    }
  };

  // Fetch advisors based on location and claim
  useEffect(() => {
    const claims = globalState.state.selectedClaims;
    const cityId = globalState.state.selectedLocationId;
    if (globalState.state.backupAdvisors.length > 0 &&
      claims.length && cityId &&
      !globalState.state.selectedHelps[0] &&
      !globalState.state.selectedClaimAssets[0]
    ) {
      let filteredAdvisors = filterAdvisors(globalState.state.backupAdvisors, 'CLAIM', globalState.state.selectedClaims);
      setAdvisors(filteredAdvisors);
    }
  }, [
    selectedClaims, globalState.state.selectedClaims, globalState.state.selectedLocationId,
    globalState.state.selectedClaimAssets, globalState.state.backupAdvisors,
    globalState.state.selectedHelps, dispatch
  ]);

  // Fetch advisors based on location and claim
  useEffect(() => {
    const claims = globalState.state.selectedClaims;
    const cityId = globalState.state.selectedLocationId;
    if (globalState.state.backupAdvisors.length === 0 &&
      claims.length && cityId &&
      !globalState.state.selectedHelps[0] &&
      !globalState.state.selectedClaimAssets[0]
    ) {
      fetchAdvisors(Requests.fetchAdvisorClaim, {
        city_id: globalState.state.selectedLocationId,
        claimtype_ids: globalState.state.selectedClaims,
      }, setAdvisors, dispatch);
    }
  }, [
    selectedClaims, globalState.state.selectedClaims, globalState.state.selectedLocationId,
    globalState.state.selectedClaimAssets,
    globalState.state.selectedHelps, dispatch, globalState.state.backupAdvisors
  ]);

  // Fetch advisors based on location , claim and asset
  useEffect(() => {
    const assets = globalState.state.selectedClaimAssets;
    const cityId = globalState.state.selectedLocationId;
    if (assets?.length && cityId && !globalState.state.selectedHelps[0]) {
      fetchAdvisors(Requests.fetchAdvisorsAssets, {
        city_id: globalState.state.selectedLocationId,
        claimtype_ids: globalState.state.selectedClaims,
        asset_ids: globalState.state.selectedClaimAssets,
      }, setAdvisors, dispatch);
    }
  }, [globalState.state.selectedClaimAssets, globalState.state.selectedClaims,
  globalState.state.selectedHelps, globalState.state.selectedLocationId, dispatch]);

  // Fetch advisors based on help services from server
  useEffect(() => {
    const services = globalState.state.selectedHelps;
    const cityId = globalState.state.selectedLocationId;

    if (services.length && cityId &&
      (prevSelectedServices == null || prevSelectedServices !== globalState.state.selectedHelps)) {
      setPrevSelectedServices(globalState.state.selectedHelps);
      fetchAdvisors(Requests.fetchAdvisorsWithServices, {
        city_id: globalState.state.selectedLocationId,
        claimtype_ids: globalState.state.selectedClaims,
        asset_ids: globalState.state.selectedClaimAssets,
        claimservice_ids: globalState.state.selectedHelps,
      }, setAdvisors, dispatch);
    }
  }, [
    globalState.state.selectedHelps, globalState.state.backupAdvisors,
    selectedClaims, globalState.state.selectedClaims, globalState.state.selectedLocationId,
    globalState.state.selectedClaimAssets, prevSelectedServices, dispatch
  ]);

  // Render the UI for the very first time with the locaton data
  useEffect(() => {
    const currentPincode = globalState.state.selectedLocationPincode;
    if (currentPincode &&
      !globalState.state.selectedClaims[0] &&
      !globalState.state.selectedHelps[0]) {
      fetchAdvisors(Requests.fetchAdvisorWithLocation, {
        pincode: globalState.state.selectedLocationPincode,
      }, setAdvisors, dispatch);
    }
  }, [
    globalState.state.selectedLocationPincode,
    globalState.state.selectedClaims,
    globalState.state.selectedHelps, dispatch
  ]);

  useEffect(() => {
    fetchDropdownList(Requests.fetchClaimService, setService);
  }, []);

  // Fetch claim assets
  useEffect(() => {
    const fetchDropdownList = async () => {
      if (!globalState.state.selectedClaims || globalState.state.selectedClaims.length === 0) return;
      setSelectedClaims([...globalState.state.selectedClaims]);
      let arrayRet = await fetchDepDropdownList(Requests.fetchClaimAssets, {
        claimtype_ids: globalState.state.selectedClaims,
      }, null, dispatch, 'claimTypeAssetsArray');
      const filteredArray = arrayRet.filter(
        (v, i, a) =>
          a.findIndex((t) => t.asset.name === v.asset.name) === i
      );
      setClaimAsset(filteredArray);
    }
    fetchDropdownList();
  }, [globalState.state.selectedClaims, dispatch]);

  const getContact = (data) => { };

  // const locationSelected = (pincode, location, id, taluk, district, statename) => {
  const locationSelected = (pincode, location, id, district, statename, district_city_town_id) => {
    showHideFilterList('HIDE');
    dispatch({ type: "selectedLocationPincode", payload: pincode });
    dispatch({ type: "selectedLocation", payload: location + ', ' + district + ', ' + statename + ', ' + pincode });
    dispatch({ type: "selectedLocationId", payload: district_city_town_id });
    setSearchDrop("none");
  };

  // Load the locations based on searched location or pincode.
  useEffect(() => {
    if (search.length > 2) {
      if (globalState.state.relatedLocations.length === 0 ||
        searchOrFilter(search, globalState.state.prevSearchStr) === 'search') {
        locationSearch(search, setRelatedLocations, dispatch);
      }
    }
    if (search !== globalState.state.prevSearchStr) {
      dispatch({
        type: "prevSearchStr",
        payload: search,
      });
    }
    if (search === "" && globalState.state.relatedLocations.length > 0) {
      dispatch({
        type: "relatedLocations",
        payload: [],
      });
    }
  }, [dispatch, search, setRelatedLocations, globalState.state.prevSearchStr, globalState.state.relatedLocations]);

  useEffect(() => {
    if (globalState.state.relatedLocations.length > 0 && search.length > 2) {
      if (searchOrFilter(search, globalState.state.prevSearchStr) === 'filter') {
        locationfilter(search, setRelatedLocations, globalState.state.relatedLocations);
      }
    }
  }, [globalState.state.relatedLocations, search, setRelatedLocations, globalState.state.prevSearchStr]);

  // useEffect(async() => {
  //   const arrayRecon = globalState.state.claimTypeAssetsArray.map((item) => {
  //     return { claim_types: item.claimtype.id, assets: item.asset.id };
  //   });
  //   console.log(arrayRecon);

  // }, [globalState.state.claimTypeAssetsArray]);

  return (
    <>
      <Toolbar />
      <ContactModal
        isOpen={contactShow}
        onClose={closeContactModal}
        redirectToDetail={redirectToDetail}
        getContact={getContact}
        currentParty={party}
      />
      <Filter show={filterOpen} backdropClickHandler={backdropClickHandler}>
        <FilterContent
          title="I need advice for"
          claimAndAsset={[[...claim], [...claimAsset]]}
          filterType="ClaimType"
        />
        <FilterContent
          title="Asset Type Damaged"
          items={claimAsset}
          filterType="ClaimAsset"
        />
        <FilterContent
          title="I need help in"
          items={service}
          filterType="ClaimHelp"
        />
      </Filter>

      <Div className="screen-padding" p={{ y: "20px" }}>
        <BrowserView>
          <Div m={{ y: "20px" }}>
            <Slider images={images} />
          </Div>
        </BrowserView>
        <LocationSelect
          label={currentLocation}
          type="filter"
          passedFunction={searchLocation}
        />
        <span style={{ display: searchDropShow }}>
          <SearchDrop
            locationSelected={locationSelected}
            relatedLocations={relatedLocations}
          />
        </span>
        <FilterButton click={filterToggleClickHandler} />
        <Div
          maxW="800px"
          pos="relative"
          d={{ sm: "none", xs: "none", md: "flex" }}
        >
          <FilterDropdown
            title="I need advice for"
            claimAndAsset={[[...claim], [...claimAsset]]}
            multiSelect
            id="sel-movie1"
            filterType="ClaimType"
          />
          <FilterDropdown
            title="Asset Type Damaged"
            items={claimAsset}
            multiSelect
            id="sel-movie2"
            filterType="ClaimAsset"
          />
          <FilterDropdown
            title="I need help in"
            items={service}
            multiSelect
            id="sel-movie3"
            filterType="ClaimHelp"
          />
        </Div>
        <Div d={{ sm: "none", xs: "none", md: "flex" }}>
          <div
            style={{
              display: "grid",
              width: "100vw",
              gridTemplateColumns: "1fr 1fr 2.1fr",
              gridAutoRows: "minmax(100px, auto)",
              gap: "10px",
              maxWidth: "1073px",
            }}
          // d={{ sm: "none", xs: "none", md: "flex" }}
          >
            <SelectedDropdown
              items={selectedAdviceNames}
              style={{ gridColumn: 1 }}
            />
            <SelectedDropdown
              items={globalState.state.selectedAdicerAssets}
              style={{ gridColumn: 2 }}
            />
            <SelectedDropdown
              items={selectedhelpNames}
              style={{ gridColumn: 3 }}
            />
          </div>
        </Div>
        <Div m={{ t: "40px" }} d="flex" justify="space-between">
          <CountLabel count={advisors?.length} />
          <Button
            label="Sort"
            type="secondary"
            style={{ w: "80px", h: "35px", zIndex: -1 }}
            buttonType="sort"
          />
        </Div>
      </Div>
      <Div className="screen-padding" bg="#f7f7f7" p={{ y: "40px" }}>
        {id && (
          <>
            <Label
              // label={`Advisor Name ${advisorOrder}`}
              type="subHeader"
              style={{ textColor: Colors.primaryColor, m: { y: "30px" } }}
            />
            <ServiceItemDetail {...{ item, openContactModal }} />

            <Label
              label="Advisors near your location"
              type="bodyText2"
              style={{
                textColor: Colors.primaryColor,
                m: { y: "50px" },
                textSize: "30px",
              }}
            />
          </>
        )}

        <Row m={{ x: "-18px" }}>
          {/* <Col
            size={{ xs: "12", sm: "12", md: "6", lg: "4", xl: "4" }}
            p={{ r: "3px", l: "8px" }}
          >
            <ServiceItem {...{ item, openContactModal }} />
          </Col>
          <Col
            size={{ xs: "12", sm: "12", md: "6", lg: "4", xl: "4" }}
            p={{ r: "3px", l: "8px" }}
          >
            <ServiceItem {...{ item, openContactModal }} />
          </Col>
          <Col
            size={{ xs: "12", sm: "12", md: "6", lg: "4", xl: "4" }}
            p={{ r: "3px", l: "8px" }}
          >
            <ServiceItem {...{ item, openContactModal }} />
          </Col> */}
          {advisors?.map((item, index) => {
            const service = "advisor";
            return (
              <Col
                key={`key-${index}`}
                size={{ xs: "12", sm: "12", md: "6", lg: "4", xl: "4" }}
                p={{ r: "3px", l: "8px" }}
              >
                <ServiceItem {...{ item, openContactModal, service, index }} />
              </Col>
            );
          })}
        </Row>
      </Div>
    </>
  );
};

export default ClaimAdviceService;

// //filter advisors based on help services locally
// useEffect(() => {
//   const services = globalState.state.selectedHelps;
//   const cityId = globalState.state.selectedLocationId;
//   const filterAdvisors = () => {
//     console.log("filtering Advisors locally for claim type");

//   };
//   if (globalState.state.backupAdvisors.length > 0 && services.length && cityId &&
//     (prevSelectedServices == null || prevSelectedServices !== globalState.state.selectedHelps)) {
//     setPrevSelectedServices(globalState.state.selectedHelps);
//     filterAdvisors();
//   }
// }, [
//   globalState.state.selectedHelps, globalState.state.backupAdvisors,
//   selectedClaims, filterNullCheck, globalState.state.selectedClaims, globalState.state.selectedLocationId,
//   globalState.state.selectedClaimAssets, prevSelectedServices, dispatch
// ]);

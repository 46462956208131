import React from "react";
import { Row, Col, Input } from "atomize";
import moment from 'moment'

export const PersonalDetailsBlock = ({
  partyID = "",
  serviceCategory = "",
  enrolDate = "",
  firstName = "",
  lastName = "",
}) => {
  return (
    <Row>
      <Col size={4} p={{ y: "0.5em" }}>
        Party Id
      </Col>
      <Col size={8} p={{ y: "0.5em" }}>
        <Input
          value={partyID}
          placeholder={"party ID"}
          h={"1.8rem"}
          disabled={true}
        />
      </Col>
      <Col size={4} p={{ y: "0.5em" }}>
        Service Category
      </Col>
      <Col size={8} p={{ y: "0.5em" }}>
        <Input
          value={serviceCategory}
          placeholder={"Service Category"}
          h={"1.8rem"}
          disabled={true}
        />
      </Col>
      <Col size={4} p={{ y: "0.5em" }}>
        Enrolment Date
      </Col>
      <Col size={8} p={{ y: "0.5em" }}>
        <Input
          value={enrolDate ? moment(enrolDate).format("DD.MM.YYYY") : ""}
          placeholder={"Enrolment Date"}
          h={"1.8rem"}
          disabled={true}
        />
      </Col>
      <Col size={4} p={{ y: "0.5em" }}>
        First Name
      </Col>
      <Col size={8} p={{ y: "0.5em" }}>
        <Input
          value={firstName}
          placeholder={"First Name"}
          h={"1.8rem"}
          disabled={true}
        />
      </Col>
      <Col size={4} p={{ y: "0.5em" }}>
        Last Name
      </Col>
      <Col size={8} p={{ y: "0.5em" }}>
        <Input
          value={lastName}
          placeholder={"Last Name"}
          h={"1.8rem"}
          disabled={true}
        />
      </Col>
    </Row>
  );
};

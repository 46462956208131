

export default {
    registerUser: '/auth/register/',
    loginUser: '/auth/login/',
    loginExistingUser: '/auth/mob-exists/',
    generateParty: '/api/party/',
    createProfile: '/api/personal-details/',
    refreshToken: '/api/token/refresh/',
    serviceCategory: '/api/service-category/',
    serviceProfile: '/api/service-profile/',
    getStates: '/api/states/',
    getCities: '/api/state-city/',
    getLineOfBusinesses: '/api/lob-types/',
    saveLineOfBusinesses: '/api/service-lob/',
    getCompaniesLob: '/api/companies-lob/',
    saveCompaniesLob: '/api/service-company/',
    agencyLicenceDetails: '/api/agency-licence/',
    communicationDetails: '/api/communication-details/',
    pincode: '/api/get-postalcode/',
    whatsAppNumber: '/api/whatsapp/',
    contactEmail: '/api/email-address/',
    availableKycDocuments: '/api/kyc-types/',
    kycDetails: '/api/kyc_documents/',
    claimAdvisorLob: '/api/claim-types/',
    claimAdvisorService: '/api/insurance/claim/services/',
    getOrSaveClaimAdvisorLob: '/api/service-specialization/',
    getOrSaveclaimAdvisorService: '/api/service-provided/',
    getPreviewDetails: '/api/party-data/',
    phoneNumber: '/api/phone-number/',
    verifyDetails: '/api/create-verification/',
    getCitiesFromState: '/api/city-from-state/',
    phoneNumberExists: '/api/phone-number-exists/'
}
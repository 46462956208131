import React, { useState, useEffect, useContext } from "react";
import { Div, Row, Col } from "atomize";
import { ServiceItem, Button, Slider, Label } from "../../components";
import FilterDropdown from "../../components/FilterDropdown/FilterDropdown";
import Filter, { FilterContent } from "../../components/Filter/Filter";
import { /*insureItems, policies,*/ insuraceCompanies } from "./data";
//import axios from "../../utils/axios";
import Requests from "../../utils/requests";
import LocationSelect from "../../components/LocationSelect/LocationSelect";
import FilterButton from "../../components/FilterButton/FilterButton";
import Colors from "../../Themes/Colors";
import ContactModal from "../../components/ContactModal/ContactModal";
import ServiceItemDetail from "../../components/ServiceItemDetail/ServiceItemDetail";
import { useHistory } from "react-router-dom";
import { BrowserView } from "react-device-detect";
import { store } from "../../context/store";
import SearchDrop from "../../components/SearchDropdown/SearchDropdown";
//import ipLocation from "../../utils/services";
import SelectedDropdown from "../../components/SelectedDropdown/SelectedDropdown";
import { Toolbar } from "../../components";
import {
  setLocationForPosition, fetchDropdownList, fetchDepDropdownList, locationSearch, showHideFilterList,
  filterAgents, fetchAgents, locationfilter, searchOrFilter
} from "../../utils/commonfunctions";

//const Http = new XMLHttpRequest();

const images = [
  {
    image: require("../../assets/images/sec4.jpg"),
    label: "Buy Insurance",
    label3: "Find insurance advisors you can trust",
  },
];

const CountLabel = ({ count }) => (
  <span className="service-2-l-2">
    {count} <span className="service-2-l-1">{" results available"}</span>
  </span>
);
const InsuranceService = ({ match }) => {
  const { id } = match.params;
  const globalState = useContext(store);
  const { dispatch } = globalState;
  const [search, setKeyword] = useState("");
  const [relatedLocations, setRelatedLocations] = useState([]);
  //const [currentTypeId, setCurrentType] = useState();
  const [filterOpen, setFilterOpen] = useState(false);
  const [policyTypes, setPolicyTypes] = useState([]);
  const [policyAssets, setPolicyAssets] = useState([]);
  const [policyAgents, setPolicyAgents] = useState([]);
  const [currentLocation, setCurrentLocation] = useState("");
  const [party, setParty] = useState(0);
  //const [filterNullCheck, setFilterNullCheck] = useState(0);
  // eslint-disable-next-line
  const [backupAgents, setBackupAgents] = useState([]);
  const [searchDropShow, setSearchDrop] = useState("none");
  const [selectedPolicyName, setSelectedPolicyName] = useState([]);
  const [selectedCompanyNames, setSelectedCompanyNames] = useState([]);
  const history = useHistory();

  useEffect(() => {
    setSelectedPolicyName([globalState.state.selectedPolicyName]);
  }, [globalState.state.selectedPolicyName]);

  useEffect(() => {
    setSelectedCompanyNames(globalState.state.selectedCompanyNames);
  }, [globalState.state.selectedCompanyNames]);

  const item = {
    image: require("../../assets/images/avatar.png"),
    name: "Agent Name 1",
    star: 4,
    reviewedCustomer: 10,
    serviceType: "Insurance Agent",
    company: "United India Insurance Co.Ltd.",
    experience: "15 years of overall experience",
    address: `${globalState?.state?.providerDetails?.address?.[0].city?.name}, ${globalState?.state?.providerDetails?.address?.[0].city?.state?.name}`,
    features: [
      "General Insurance Personal",
      "Health Insurance",
      "General Insurance Commercial",
    ],
  };

  const filterToggleClickHandler = () => {
    setFilterOpen(!filterOpen);
  };

  // Set the location name by ip/gps
  useEffect(() => {
    // (() => {
    setLocationForPosition(globalState.state.selectedLocationPincode, dispatch);
    // })();

    fetchDropdownList(Requests.fetchPolicyTypes, setPolicyTypes, dispatch, 'policyTypes');

    if (filterOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [filterOpen, dispatch, globalState.state.selectedLocationPincode]);

  // Fetch policy assets
  useEffect(() => {
    // const fetchDropdownList = async () => {
    //   await axios
    //     .post(Requests.fetchAssets, {
    //       policytype_ids: [globalState.state.selectedPolicy],
    //     })
    //     .then((response) => {
    //       setPolicyAssets(response.data.data)
    //     })
    //     .catch((e) => {
    //       console.log('useEffect exception fetchAssets', e);
    //     });
    // };
    fetchDepDropdownList(Requests.fetchAssets, {
      policytype_ids: [globalState.state.selectedPolicy],
    }, setPolicyAssets);
  }, [globalState.state.selectedPolicy]);

  // Fetch agents based on location, policy type and asset but no company
  useEffect(() => {
    const filterAgents = () => {
      console.log('Nothing to be done agents for TypeAssets from local cache');
      //setPolicyAgents(globalState.state.backupAgents)
    };
    if (globalState.state.backupAgents.length > 0 && globalState.state.selectedLocationId && globalState.state.selectedPolicy &&
      globalState.state.selectedAsset && !globalState.state.selectedCompanies[0]) filterAgents();
  }, [
    globalState.state.selectedAsset,
    globalState.state.selectedLocationId,
    globalState.state.selectedPolicy,
    globalState.state.selectedCompanies, globalState.state.backupAgents
  ]);

  // Fetch agents based on location, policy type and asset but no company
  useEffect(() => {
    if (globalState.state.backupAgents.length === 0 && globalState.state.selectedLocationId && globalState.state.selectedPolicy &&
      globalState.state.selectedAsset && !globalState.state.selectedCompanies[0]) {
      fetchAgents(Requests.fetchAgentsWithTypeAsset, {
        city_id: globalState.state.selectedLocationId,
        policytype_ids: [globalState.state.selectedPolicy],
        asset_ids: [globalState.state.selectedAsset],
      }, setPolicyAgents, dispatch);
    }
  }, [
    globalState.state.selectedAsset,
    globalState.state.selectedLocationId,
    globalState.state.selectedPolicy,
    globalState.state.selectedCompanies, dispatch, globalState.state.backupAgents
  ]);

  useEffect(() => {
    if (globalState.state.backupAgents.length > 0 && globalState.state.selectedPolicy &&
      globalState.state.selectedCompanies.length === 0) {
      let selectedPolicyIdx = globalState.state.selectedPolicyIdx;
      let lob = selectedPolicyIdx !== -1 ? policyTypes[selectedPolicyIdx].lob : null;
      let filteredAgents = filterAgents(globalState.state.backupAgents, 'LOB', lob);
      setPolicyAgents(filteredAgents);
    }
  }, [globalState.state.backupAgents, globalState.state.selectedCompanies, globalState.state.selectedPolicyIdx, globalState.state.selectedPolicy, policyTypes]);

  // Fetch agents based on location and policy type
  useEffect(() => {
    if (globalState.state.backupAgents.length === 0 && globalState.state.selectedPolicy &&
      globalState.state.selectedCompanies.length === 0) {
      fetchAgents(Requests.fetchAgentsWithTypes, {
        city_id: globalState.state.selectedLocationId,
        policytype_ids: [globalState.state.selectedPolicy],
      }, setPolicyAgents, dispatch);
    }
  }, [globalState.state.selectedPolicy, globalState.state.selectedCompanies,
  globalState.state.selectedLocationId, globalState.state.backupAgents, dispatch]);

  // const locationSelected = (pincode, location, id, taluk, district, statename) => {
  const locationSelected = (pincode, location, id, district, statename, district_city_town_id) => {
    showHideFilterList('HIDE');
    dispatch({ type: "selectedLocationPincode", payload: pincode });
    dispatch({ type: "selectedLocation", payload: location + ', ' + district + ', ' + statename + ', ' + pincode });
    dispatch({ type: "selectedLocationId", payload: district_city_town_id });
    setSearchDrop("none");
  };

  // // Load the agents based on searched location or pincode.
  // useEffect(() => {
  //   locationSearch(search, setRelatedLocations, dispatch);
  // }, [search, dispatch]);

  // Load the locations based on searched location or pincode.
  useEffect(() => {
    if (search.length > 2) {
      if (globalState.state.relatedLocations.length === 0 ||
        searchOrFilter(search, globalState.state.prevSearchStr) === 'search') {
        locationSearch(search, setRelatedLocations, dispatch);
      }
    }
    if (search !== globalState.state.prevSearchStr) {
      dispatch({
        type: "prevSearchStr",
        payload: search,
      });
    }
    if (search === "" && globalState.state.relatedLocations.length > 0) {
      dispatch({
        type: "relatedLocations",
        payload: [],
      });
    }
  }, [dispatch, search, setRelatedLocations, globalState.state.prevSearchStr, globalState.state.relatedLocations]);

  useEffect(() => {
    if (globalState.state.relatedLocations.length > 0 && search.length > 2) {
      if (searchOrFilter(search, globalState.state.prevSearchStr) === 'filter') {
        locationfilter(search, setRelatedLocations, globalState.state.relatedLocations);
      }
    }
  }, [globalState.state.relatedLocations, search, setRelatedLocations, globalState.state.prevSearchStr]);

  // Load the agents based on location and selected companies
  useEffect(() => {
    if (globalState.state.backupAgents.length > 0 && globalState.state.selectedCompanies.length > 0 &&
      globalState.state.selectedLocationId && !globalState.state.selectedPolicy) {
      let filteredAgents = filterAgents(globalState.state.backupAgents, 'COMPANY', globalState.state.selectedCompanies);
      setPolicyAgents(filteredAgents);
    }
  }, [
    globalState.state.selectedCompanies,
    globalState.state.selectedPolicy,
    globalState.state.selectedLocationId, dispatch, globalState.state.backupAgents
  ]);

  // Load the agents based on location and selected companies
  useEffect(() => {
    if (globalState.state.backupAgents.length === 0 && globalState.state.selectedCompanies.length > 0 &&
      globalState.state.selectedLocationId && !globalState.state.selectedPolicy) {
      fetchAgents(Requests.fetchAgentsWithCompany, {
        city_id: globalState.state.selectedLocationId,
        companies: globalState.state.selectedCompanies,
      }, setPolicyAgents, dispatch);
    }
  }, [
    globalState.state.selectedCompanies,
    globalState.state.selectedPolicy,
    globalState.state.selectedLocationId, dispatch, globalState.state.backupAgents
  ]);

  // Filter the agents locally based on type and company
  useEffect(() => {
    const location = globalState.state.selectedLocationId;
    const policyType = globalState.state.selectedPolicy;

    if (globalState.state.backupAgents.length > 0 && globalState.state.selectedCompanies.length > 0 && location && policyType) {
      let selectedPolicyIdx = globalState.state.selectedPolicyIdx;
      let lob = selectedPolicyIdx !== -1 ? policyTypes[selectedPolicyIdx].lob : null;
      let filteredAgents = filterAgents(globalState.state.backupAgents, 'LOBCOMPANY', [lob, ...globalState.state.selectedCompanies]);
      setPolicyAgents(filteredAgents);
    }
  }, [policyTypes, globalState.state.backupAgents, globalState.state.selectedCompanies,
    globalState.state.selectedLocationId, globalState.state.selectedPolicyIdx, globalState.state.selectedPolicy
  ]);
  // Load the agents based on location, type, asset and company
  useEffect(() => {
    const location = globalState.state.selectedLocationId;
    const policyType = globalState.state.selectedPolicy;

    if (globalState.state.backupAgents.length === 0 && globalState.state.selectedCompanies.length > 0 && location && policyType) {
      fetchAgents(Requests.fetchAgetsByTypeCityCompany, {
        city_id: globalState.state.selectedLocationId,
        policytype_ids: [globalState.state.selectedPolicy],
        asset_ids: globalState.state.selectedAsset ? [globalState.state.selectedAsset] : [],
        companies: globalState.state.selectedCompanies,
      }, setPolicyAgents, dispatch);
    }
  }, [
    globalState.state.selectedCompanies,
    globalState.state.selectedAsset,
    globalState.state.selectedPolicy,
    globalState.state.selectedLocationId, dispatch, globalState.state.backupAgents
  ]);

  useEffect(() => {
    if (!globalState.state.selectedPolicy) {
      if (globalState.state.policyTypeReset || globalState.state.companiesCleared) {
        if (globalState.state.backupAgents.length > 0) {
          console.log('Setting agents from local cache policy reset company reset');
          setPolicyAgents(globalState.state.backupAgents);
        }
      }
      if (globalState.state.policyTypeReset) {
        dispatch({
          type: "policyTypeReset",
          payload: false,
        });
      }
      if (globalState.state.companiesCleared) {
        dispatch({
          type: "companiesCleared",
          payload: false,
        });
      }
    }
  }, [globalState.state.policyTypeReset, globalState.state.selectedPolicy, dispatch,
  globalState.state.backupAgents, globalState.state.companiesCleared]);

  // Render the UI for the very first time with the locaton data
  useEffect(() => {
    const curentPincode = globalState.state.selectedLocationPincode;
    if (curentPincode) {
      fetchAgents(Requests.fetchAgentsWithLocation, {
        pincode: globalState.state.selectedLocationPincode,
      }, setPolicyAgents, dispatch);
    }
  }, [globalState.state.selectedLocationPincode, dispatch]);
  //Use the commeted code when testing for apis with backupAgent not set in global state
  // useEffect(() => {
  //   const curentPincode = globalState.state.selectedLocationPincode;
  //   if (curentPincode || (globalState.state.backupAgents.length === 0 && globalState.state.policyTypeReset)) {
  //     fetchAgents(Requests.fetchAgentsWithLocation, {
  //       pincode: globalState.state.selectedLocationPincode,
  //     }, setPolicyAgents, dispatch);
  //   }
  // }, [globalState.state.selectedLocationPincode, globalState.state.backupAgents, globalState.state.policyTypeReset, dispatch]);

  const backdropClickHandler = () => {
    setFilterOpen(false);
  };
  const [contactShow, setContactShow] = useState(false);

  const openContactModal = (party) => {
    setParty(party);
    setContactShow(true);
  };

  const closeContactModal = () => {
    setContactShow(false);
  };

  // Set the selected location from the location search dropdown
  useEffect(() => {
    setCurrentLocation(globalState.state.selectedLocation);
  }, [globalState.state.selectedLocation]);

  const redirectToDetail = (id) => {
    history.push(`/service/buyinsurance/detail/${id}`);
  };

  const searchLocation = (location) => {
    setKeyword(location);
    if (location.length < 3) {
      setSearchDrop("none");
    } else {
      setSearchDrop("block");
    }
  };

  return (
    <>
      <Toolbar />
      <ContactModal
        isOpen={contactShow}
        onClose={closeContactModal}
        redirectToDetail={redirectToDetail}
        currentParty={party}
      />
      <Filter show={filterOpen} backdropClickHandler={backdropClickHandler}>
        <FilterContent
          buttonType="radio"
          title="I would like to buy"
          items={policyTypes}
          id="sel-movie4"
          filterType="policy"
        />
        <FilterContent
          buttonType="radio"
          title="I want to insure"
          id="sel-movie5"
          items={policyAssets}
        />
        <FilterContent
          title="I prefer to buy policy from"
          items={insuraceCompanies}
          id="sel-movie6"
          filterType="insuranceCompany"
        />
      </Filter>
      <Div className="screen-padding" p={{ y: "20px" }}>
        <BrowserView>
          <Div m={{ y: "20px" }}>
            <Slider images={images} />
          </Div>
        </BrowserView>
        <LocationSelect
          label={currentLocation}
          type="filter"
          passedFunction={searchLocation}
        />
        <span style={{ display: searchDropShow }}>
          <SearchDrop
            locationSelected={locationSelected}
            relatedLocations={relatedLocations}
          />
        </span>
        <FilterButton click={filterToggleClickHandler} />
        <Div
          maxW="800px"
          pos="relative"
          d={{ sm: "none", xs: "none", md: "flex" }}
        >
          <FilterDropdown
            title="I would like to buy"
            items={policyTypes}
            multiSelect
            id="sel-movie1"
            buttonType="radio"
            filterType="policy"
          />
          <FilterDropdown
            title="I want to insure"
            items={policyAssets}
            multiSelect
            id="sel-movie2"
            buttonType="radio"
          />
          <FilterDropdown
            title="I prefer to buy policy from"
            items={insuraceCompanies}
            multiSelect
            id="sel-movie3"
            filterType="insuranceCompany"
          />
        </Div>
        <Div d={{ sm: "none", xs: "none", md: "flex" }}>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 2.1fr",
              width: "100vw",
              gridAutoRows: "minmax(100px, auto)",
              gap: "10px",
              maxWidth: "1073px",
            }}
          // d={{ sm: "none", xs: "none", md: "flex" }}
          >
            <SelectedDropdown
              items={selectedPolicyName}
              style={{ gridColumn: 1 }}
            />
            <SelectedDropdown
              items={globalState.state.selectedAssetNameInsurance}
              style={{ gridColumn: 2 }}
              filterType="insuranceAsset"
            />
            <SelectedDropdown
              items={selectedCompanyNames}
              style={{ gridColumn: 3 }}
            />
          </div>
        </Div>
        <Div m={{ t: "40px" }} d="flex" justify="space-between">
          <CountLabel count={policyAgents?.length} />
          <Button
            label="Sort"
            type="secondary"
            style={{ w: "80px", h: "35px", zIndex: -1 }}
            buttonType="sort"
          />
        </Div>
      </Div>
      <Div className="screen-padding" bg="#f7f7f7" p={{ y: "40px" }}>
        {id && (
          <>
            {/* <Label
              label={`Agent Name ${agentOrder}`}
              type="subHeader"
              style={{ textColor: Colors.primaryColor, m: { y: "30px" } }}
            /> */}
            <ServiceItemDetail {...{ item, openContactModal }} />

            <Label
              label="Agents near your location"
              type="bodyText2"
              style={{
                textColor: Colors.primaryColor,
                m: { y: "50px" },
                textSize: "30px",
              }}
            />
          </>
        )}
        <Row m={{ x: "-18px" }}>
          {/* <Col
            size={{ xs: "12", sm: "12", md: "6", lg: "4", xl: "4" }}
            p={{ r: "3px", l: "8px" }}
          >
            <ServiceItem {...{ item, openContactModal }} />
          </Col>
          <Col
            size={{ xs: "12", sm: "12", md: "6", lg: "4", xl: "4" }}
            p={{ r: "3px", l: "8px" }}
          >
            <ServiceItem {...{ item, openContactModal }} />
          </Col>
          <Col
            size={{ xs: "12", sm: "12", md: "6", lg: "4", xl: "4" }}
            p={{ r: "3px", l: "8px" }}
          >
            <ServiceItem {...{ item, openContactModal }} />
          </Col> */}

          {policyAgents?.map((item, index) => {
            return (
              <Col
                key={`key-${index}`}
                size={{ xs: "12", sm: "12", md: "6", lg: "4", xl: "4" }}
                p={{ r: "3px", l: "8px" }}
              >
                <ServiceItem {...{ item, openContactModal, index }} />
              </Col>
            );
          })}
        </Row>
      </Div>
    </>
  );
};

export default InsuranceService;

import React from "react";
import { Div, Image } from "atomize";
import { Label, Button } from "../../../../components";
import "../../../../styles.css";
import "./HeaderSection.css";
import { useHistory } from "react-router-dom";
const DetailSection = () => {
  const history = useHistory();
  return (
    <Div w="100%" d="flex" flexDir="column" align="center">
      <Div>
        <Label label="Find insurance advisors you can trust" type="header" />
        <Label
          label="Nsure360 connects insurance Professionals to customer seeking expert advice."
          type="bodyText1"
          style={{ m: { t: "18px" }, maxW: "550px" }}
        />
        <Div d="flex" maxW="550px" m={{ t: "18px" }}>
          <Button
            label="I want to"
            label2="Buy Insurance"
            type="secondary"
            onPress={() => history.push("/service/buyinsurance")}
            style={{ m: { r: "5px" } }}
          />
          <Button
            label="I need"
            label2="Claim Advice"
            type="secondary"
            onPress={() => history.push("/service/claimadvice")}
            style={{ m: { l: "5px" } }}
          />
        </Div>
      </Div>
    </Div>
  );
};
const HeaderSection = () => {
  return (
    <Div
      d="flex"
      flexDir={{ xs: "column", sm: "column", md: "row" }}
      justify="space-between"
      className="screen-padding"
      m={{ t: { xs: "20px", sm: "20px", md: "75px" } }}
      w="100%"
      align="center"
    >
      <Div
        d="flex"
        justify="center"
        align="center"
        w="100%"
        className="sm-1"
        m={{ b: "20px" }}
      >
        <Image
          src={require("../../../../assets/images/sec1.png")}
          maxW={{ xs: "250px", sm: "250px" }}
        />
      </Div>
      <DetailSection />
      <Div
        d="flex"
        justify="center"
        align="center"
        w="100%"
        className="md-1"
        m={{ b: "20px" }}
      >
        <Image
          src={require("../../../../assets/images/sec1.png")}
          maxW={{ lg: "400px", md: "350px", xl: "450px" }}
        />
      </Div>
    </Div>
  );
};

export default HeaderSection;

import React from "react";
import { Row, Col, Input, Text } from "atomize";
import Chip from "@material-ui/core/Chip";

import Colors from "../../../Themes/Colors";

export const ServiceProfileDetails = ({
  serviceCategory = "",
  area = "",
  lob = [],
  companies = [],
}) => {
  return (
    <Row>
      <Col size={12} p={{ t: "2em", b: "1em" }}>
        <Text
          textSize={{ xs: "subheader", lg: "subheader" }}
          textColor={Colors.servicePartnerPrimary}
        >
          Service Profile
        </Text>
      </Col>
      <Col size={4} p={{ y: "0.5em" }}>
        Service Area / Location
      </Col>
      <Col size={8} p={{ y: "0.5em" }}>
        <Input value={area} placeholder={"Area"} h={"1.8rem"} disabled={true} />
      </Col>
      <Col size={4} p={{ y: "0.5em" }}>
        Service Category
      </Col>
      <Col size={8} p={{ y: "0.5em" }}>
        <Input
          value={serviceCategory}
          placeholder={"Service Category"}
          h={"1.8rem"}
          disabled={true}
        />
      </Col>
      <Col size={4} p={{ y: "0.5em" }}>
        Line Of Business
      </Col>
      <Col size={8} p={{ y: "0.5em" }}>
        <div>
          {lob.map((lob, index) => {
            return (
              <Chip
                key={`${lob}-${index}`}
                label={lob}
                style={{ margin: "4px" }}
              />
            );
          })}
        </div>
        {/* <Input
          value={"NSASDASF3454SADA"}
          placeholder={"party ID"}
          h={"1.8rem"}
          disabled={true}
        /> */}
      </Col>
      <Col size={4} p={{ y: "0.5em" }}>
          {(serviceCategory === "Serviceprovider - advisor") 
          ? 'Services Provided'
          : 'Companies Representing'
          }
      </Col>
      <Col size={8} p={{ y: "0.5em" }}>
          {companies.map((company, index) => {
            return (
              <Chip
                key={`${company}-${index}`}
                label={company}
                style={{ margin: "4px" }} />
            );
          })}
      </Col>
    </Row>
  );
};

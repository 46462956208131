import React, { useEffect, useState, useContext } from "react";

import "./Filter.css";
import { Div, Image, Row, Col } from "atomize";
import { Button, Label } from "..";
import Colors from "../../Themes/Colors";
import CheckBoxItem from "../CheckBox/CheckBox";
import LocationSelect from "../LocationSelect/LocationSelect";
import RadioBoxItem from "../RadioBox/RadioBox";
import { store } from "../../context/store";
import Requests from "../../utils/requests";
import axios from "../../utils/axios";
import SearchDrop from "../SearchDropdown/SearchDropdown";

const SideDrawerHeader = ({ click }) => {
  return (
    <Div d="flex" justify="space-between" m={{ y: "25px" }}>
      <Image
        src={require("../../assets/images/back-arrow.png")}
        maxW="13px"
        maxH="22px"
        cursor="pointer"
        onClick={click}
      />
      <Label
        label="Set filters"
        type="bodyText1"
        style={{
          textAlign: "center",
          m: { l: "85px" },
          textColor: Colors.primaryColor,
        }}
      />
      <span onClick={() => window.location.reload()}>
        <Button
          label="Clear"
          type="secondary"
          textType="menu"
          style={{ w: "100px", borderColor: Colors.primaryColor }}
        />
      </span>
    </Div>
  );
};

const SelectedItem = ({ click, label }) => (
  <Div
    p={{ y: "5px", x: "20px" }}
    d="flex"
    align="center"
    maxH="45px"
    minH="40px"
    border={"1px solid"}
    rounded="sm"
    justify="space-between"
    borderColor={Colors.primaryColor}
    m={{ y: "10px" }}
    cursor="pointer"
    onClick={click}
  >
    <Label
      label={label}
      type="bodyText3"
      style={{ m: { l: "8px" }, w: "100%", textAlign: "left" }}
    />
    <Image
      src={require("../../assets/images/close.png")}
      maxW="20px"
      maxH="20px"
      cursor="pointer"
    />
  </Div>
);

export const FilterContent = ({ title, items, filterType, buttonType }) => {
  const [currentPolicy, setCurrentPolicy] = useState();
  const [searchCompany, setSearchCompany] = useState("");
  const [relatedCompanies, setRelatedCompanies] = useState([]);
  const [selectedCompanyIds, setSelectedCompanyIds] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const globalState = useContext(store);
  const { dispatch } = globalState;

  const policySelected = (id) => {
    setCurrentPolicy(id);
    dispatch({ type: "selectedPolicy", payload: id });
    dispatch({ type: "selectedAsset", payload: 0 });
    dispatch({ type: "selectedAssetName", payload: "" });
  };
  const clearPolicyTypes = () => {
    setCurrentPolicy(0);
    dispatch({ type: "selectedPolicy", payload: 0 });
    dispatch({ type: "selectedPolicyName", payload: "" });
    dispatch({ type: "selectedAsset", payload: 0 });
    dispatch({ type: "selectedAssetNameInsurance", payload: "" });
    dispatch({ type: "policyTypeReset", payload: true });
  }

  const assetSelected = (id) => {
    dispatch({ type: "selectedAsset", payload: id });
  };

  const companyName = (e) => {
    setSearchCompany(e.target.value);
  };

  // Fetch comapnies list based on search
  useEffect(() => {
    const fetchCompanyList = async () => {
      await axios
        .post(Requests.fetchCompanyList, {
          name: searchCompany,
        })
        .then((response) => {
          setRelatedCompanies(response.data.data);
        })
        .catch((e) => { });
    };
    if (searchCompany.length > 2) {
      fetchCompanyList();
      showCompanies();
    } else {
      hideCompanies();
    }
  }, [searchCompany]);

  // Save the state of selected companies and companies ids
  // useEffect(() => {
  //   if(filterType === 'insuranceCompany') {
  //     dispatch({ type: "selectedCompanies", payload: selectedCompanyIds });
  //     dispatch({ type: "selectedCompanyNames", payload: selectedCompanies });
  //   }
  // }, [
  //   dispatch,
  //   selectedCompanyIds,
  //   selectedCompanies,
  // ]);

  // Companies selection. Add if the company not present. Remove if it has already added.
  const companyChecked = (id, label) => {
    let currentSelectedList = selectedCompanyIds;
    const index = currentSelectedList.indexOf(id);
    let currentList = selectedCompanies;
    const indexValue = currentList.indexOf(label);

    if (index > -1) {
      const newList = currentSelectedList.filter((e) => e !== id);
      setSelectedCompanyIds([...newList]);
      dispatch({ type: "selectedCompanies", payload: newList });
    } else {
      currentSelectedList.push(id);
      setSelectedCompanyIds([...currentSelectedList]);
      dispatch({ type: "selectedCompanies", payload: currentSelectedList });
    }

    if (indexValue > -1) {
      const newNames = currentList.filter((e) => e !== label);
      setSelectedCompanies([...newNames]);
      dispatch({ type: "selectedCompanyNames", payload: newNames });
    } else {
      currentList.push(label);
      setSelectedCompanies([...currentList]);
      dispatch({ type: "selectedCompanyNames", payload: currentList });
    }
  };

  const showCompanies = () => {
    try {
      document.getElementById("relatedCompanies").style.display = "block";
    } catch (e) { }
  };

  const hideCompanies = () => {
    try {
      document.getElementById("relatedCompanies").style.display = "none";
    } catch (e) { }
  };

  return (
    <Div m={{ y: "20px" }}>
      <Label
        label={title}
        type="title"
        style={{ textColor: Colors.primaryColor, m: { l: "15px", b: "10px" } }}
      />
      {filterType === "insuranceCompany" && (
        <Div
          p={{ x: "5px" }}
          d="flex"
          cursor="pointer"
          align="center"
          maxH="45px"
          m={{ t: "12px", b: "20px" }}
          minH="40px"
          border="1px 0px 1px 1px"
          borderColor="#a0a3c8"
          className="input-container"
          maxW={{ md: "370px" }}
        >
          <input
            className="location-input"
            placeholder="Search companies"
            onChange={(e) => companyName(e)}
          />
          <Image
            src={require("../../assets/images/search.png")}
            maxW="25px"
            maxH="25px"
            cursor="pointer"
          />
        </Div>
      )}
      <Row>
        {filterType === "insuranceCompany" ? (
          <div id="relatedCompanies">
            {relatedCompanies?.map((item, index) => (
              <Col key={`key-${index}`} size="12">
                <CheckBoxItem
                  label={item.name}
                  action={companyChecked}
                  selectedItems={
                    selectedCompanyIds.indexOf(item.id) > -1 ? true : false
                  }
                  id={item.id}
                />
              </Col>
            ))}
          </div>
        ) : (
          items?.map((item, index) => (
            <Col key={`key-${index}`} size="12">
              {buttonType === "radio" ? (
                <RadioBoxItem
                  label={
                    filterType === "policy" ? item?.name : item?.asset?.name
                  }
                  checkStatus={
                    filterType === "policy"
                      ? index === currentPolicy - 1
                        ? true
                        : false
                      : item?.asset?.id === globalState.state.selectedAsset
                        ? true
                        : false
                  }
                  id={filterType === "policy" ? item?.id : item?.asset?.id}
                  onClick={
                    filterType === "policy" ? policySelected : assetSelected
                  }
                />
              ) : (
                ""
              )}
            </Col>
          ))
        )}
      </Row>
      {filterType === "insuranceCompany" && (
        <Row>
          {selectedCompanies?.map((item, index) => {
            return (
              <Col
                size="12"
                onClick={() => companyChecked(selectedCompanyIds[index], item)}
                key={selectedCompanyIds[index]}
              >
                <SelectedItem label={item} />
              </Col>
            );
          })}
        </Row>
      )}
      <Div d="flex" >
        <Label label="Show Less-" type="bodyText3" />
        {filterType === "policy" ?
          <button onClick={clearPolicyTypes}><Label m={{ l: "20px" }} label="Clear Selection" type="bodyText3" /></button> : null}
      </Div>
    </Div>
  );
};

const Filter = ({ show, backdropClickHandler, children }) => {
  const [search, setKeyword] = useState("");
  const [relatedLocations, setRelatedLocations] = useState([]);
  const [currentLocation, setCurrentLocation] = useState("");
  const globalState = useContext(store);
  const { dispatch } = globalState;

  const searchLocation = (location) => {
    setKeyword(location);
  };

  const showFilterList = () => {
    document.getElementById("locationDropdown").style.display = "block";
    document.getElementById("locationContainer").style.display = "block";
  };

  const hideFilterList = () => {
    document.getElementById("locationDropdown").style.display = "none";
    document.getElementById("locationContainer").style.display = "none";
  };

  const locationSelected = (pincode, location, id, taluk, district, statename) => {
    hideFilterList();
    dispatch({ type: "selectedLocationPincode", payload: pincode });
    dispatch({ type: "selectedLocation", payload: location });
    dispatch({ type: "selectedLocationId", payload: id });
  };

  useEffect(() => {
    if (search.length > 1) {
      const typeOfInput = Number(search);
      const fetchLocationDetails = async () => {
        if (typeOfInput) {
          await axios
            .post(Requests.fetchLocationWithPin, {
              pincode: search,
            })
            .then((response) => {
              if (response.data.data.length > 0) {
                showFilterList();
                setRelatedLocations(response.data.data);
                dispatch({
                  type: "selectedLocationId",
                  payload: response.data.id,
                });
              }
            })
            .catch((e) => { });
        } else {
          await axios
            .post(Requests.fetchLocationWithCity, {
              name: search,
            })
            .then((response) => {
              if (response.data.data.length > 0) {
                showFilterList();
                setRelatedLocations(response.data.data);
                dispatch({
                  type: "selectedLocationId",
                  payload: response.data.id,
                });
              }
            })
            .catch((e) => {
              hideFilterList();
            });
        }
      };
      fetchLocationDetails();
    } else {
      hideFilterList();
      setRelatedLocations([]);
    }
  }, [dispatch, search]);

  useEffect(() => {
    setCurrentLocation(globalState.state.selectedLocation);
  }, [globalState.state.selectedLocation]);

  let drawerClasses = "filter-drawer";
  if (show) {
    drawerClasses = "filter-drawer open";
  }
  // const buttonStyle = {
  //   rounded: "0",
  //   m: { y: "15px" },
  // };

  return (
    <Div className={drawerClasses}>
      <Div p={{ x: "20px", y: "10px" }}>
        <SideDrawerHeader click={backdropClickHandler} />
        <Label
          label="Service Location"
          type="title"
          style={{
            textColor: Colors.primaryColor,
            m: { l: "15px", b: "10px" },
          }}
        />
        <LocationSelect
          label={currentLocation}
          type="filter"
          passedFunction={searchLocation}
        />
        <SearchDrop
          locationSelected={locationSelected}
          relatedLocations={relatedLocations}
        />
        {children}
        <Div d="flex" justify="center" align="center">
          <span onClick={backdropClickHandler}>
            <Button
              label="Apply filters"
              type="primary"
              textType="menu"
              style={{ w: "120px" }}
            />
          </span>
        </Div>
      </Div>
    </Div>
  );
};

export default Filter;

import axios from "./axios";
import Requests from "./requests";
import { getPincodeForLocation } from "./googlemaps";

export const setLocationForPosition = (selectedLocationPincode, dispatch) => {
  if (!selectedLocationPincode) {
    navigator.geolocation.getCurrentPosition(async (pos) => {
      const pincode = await getPincodeForLocation(pos);
      const fetchCityDetails = async () => {
        try {
          await axios
            .post(Requests.fetchLocalityDetails, {
              pincode: pincode ? pincode : '110001',
            })
            .then((response) => {
              dispatch({
                type: "selectedLocationPincode",
                payload: response.data.data[0].pincode,
              });
              dispatch({
                type: "selectedLocation",
                // payload: `${response.data.data[0].name}, ${response.data.data[0].taluk}, ${response.data.data[0].district}, ${response.data.data[0].state.name}, ${response.data.data[0].pincode}`
                payload: `${response.data.data[0].name}, ${response.data.data[0].district_city_town.name}, ${response.data.data[0].district_city_town.state.name}, ${response.data.data[0].pincode}`
              });
              dispatch({
                type: "selectedLocationId",
                // payload: response.data.data[0].id,
                payload: response.data.data[0].district_city_town.id,
              });
            })
            .catch((e) => {
              console.log('axios fetchCityDetails', e)
            });
        } catch (e) {
          console.log('fetchCityDetails', e)
        }
      };
      fetchCityDetails();
    })
  }
}

export const fetchDropdownList = async (url, setterFunction, dispatch = null, dispatchType = null) => {
  //let api = dropDownFor === 'CLAIM' ? Requests.fetchClaimTypes : Requests.fetchPolicyTypes;
  await axios.get(url)
    .then((response) => {
      setterFunction(response.data.results);
      if (dispatch && dispatchType)
        dispatch({
          type: dispatchType,
          // payload: response.data.data[0].id,
          payload: response.data.results,
        });
    })
    .catch((e) => {
      console.log(`axios Error ${url}`, e)
    });
}

export const fetchDepDropdownList = async (url, payload, setterFunction, dispatch = null, dispatchType = null) => {
  //let api = dropDownFor === 'CLAIM' ? Requests.fetchClaimTypes : Requests.fetchPolicyTypes;
  let outData = await axios.post(url, payload)
    .then((response) => {
      let outData = null;
      if (response.data.results)
        outData = response.data.results;
      else if (response.data.data)
        outData = response.data.data;
      if (dispatch && dispatchType)
        dispatch({
          type: dispatchType,
          payload: outData,
        });
      if (setterFunction)
        setterFunction(outData);
      else
        return outData;
    })
    .catch((e) => {
      console.log(`axios Error ${url}`, e)
    });
  return outData;
}

export const showHideFilterList = (showOrHide = 'SHOW') => {
  if (showOrHide === 'SHOW') {
    document.getElementById("locationDropdown").style.display = "block";
    document.getElementById("locationContainer").style.display = "block";
  }
  else {
    document.getElementById("locationDropdown").style.display = "none";
    document.getElementById("locationContainer").style.display = "none";
  }
}

export const searchOrFilter = (search, prevSearchStr) => {
  if (prevSearchStr.length > 2) {
    let searchIn = search;
    let searchFor = prevSearchStr;
    if (prevSearchStr.length > search.length) {
      searchIn = prevSearchStr;
      searchFor = search;
    }
    if (searchIn.startsWith(searchFor))
      return 'filter';
    return 'search';
  }
}
export const locationSearch = (searchValue, setRelatedLocations, dispatch) => {
  if (searchValue.length > 2) {
    const typeOfInput = Number(searchValue);
    const fetchLocationDetails = async () => {
      if (typeOfInput) {
        if (searchValue.length < 6) {
          console.log('Nothing to do pincode less than 6 digits');
          return;
        }
        await axios
          .post(Requests.fetchLocalityWithPin, {
            pincode: searchValue,
          })
          .then((response) => {
            if (response.data.data.length > 0) {
              showHideFilterList('SHOW');
              setRelatedLocations(response.data.data);
              dispatch({
                type: "relatedLocations",
                payload: response.data.data,
              });
              dispatch({
                type: "selectedLocationId",
                payload: response.data.id,
              });
            }
          })
          .catch((e) => {
            console.log('useEffect error fetchLocalityWithPin', e);
          });
      } else {
        await axios
          .post(Requests.fetchLocalityWithCity, {
            name: searchValue,
          })
          .then((response) => {
            if (response.data.data.length > 0) {
              showHideFilterList('SHOW');
              setRelatedLocations(response.data.data);
              dispatch({
                type: "relatedLocations",
                payload: response.data.data,
              });
              dispatch({
                type: "selectedLocationId",
                payload: response.data.id,
              });
            }
          })
          .catch((e) => {
            console.log('useEffecterror  fetchLocalityWithCity', e);
            showHideFilterList('HIDE');
          });
      }
    };
    fetchLocationDetails();
  } else {
    showHideFilterList('HIDE');
    setRelatedLocations([]);
  }
}
export const locationfilter = (search, setRelatedLocations, relatedLocations) => {
  let filteredLocations = relatedLocations.filter((location) => JSON.stringify(location).toString().toLowerCase().includes(search.toLowerCase()));
  if (filteredLocations.length > 0) {
    setRelatedLocations(filteredLocations);
  }
  else {
    setRelatedLocations(relatedLocations);
  }
  showHideFilterList('SHOW');
}
export const fetchAgents = async (url, payload, setterFunction, dispatch) => {
  await axios
    .post(url, payload)
    .then((response) => {
      setterFunction(response.data.data);
      dispatch({
        type: "backupAgents",
        payload: response.data.data,
      });
    })
    .catch((e) => {
      console.log(`fetchAgents error ${url}`, e);
    });
};

export const fetchAdvisors = async (url, payload, setterFunction, dispatch) => {
  await axios
    .post(url, payload)
    .then((response) => {
      setterFunction(response.data.data);
      dispatch({
        type: "backupAdvisors",
        payload: response.data.data,
      });
    })
    .catch((e) => {
      console.log(`Error fetchAdvisors ${url}`, e)
    });
}

export const filterAgents = (agentsInp, filterFor, filterForData) => {
  // let selectedPolicy = globalState.state.selectedPolicy;
  let filteredAgents = [];
  if (!filterFor || !filterForData || !agentsInp || agentsInp.length === 0)
    return filteredAgents;
  if (filterFor === 'LOB') {
    let lob = filterForData;
    for (let agent of agentsInp) {
      for (let service of agent.services) {
        if (lob === service.lob.id) {
          filteredAgents.push(agent);
          break;
        }
      }
    }
  }
  else if (filterFor === 'COMPANY') {
    let companies = filterForData;
    for (let agent of agentsInp) {
      for (let company of companies) {
        let matched = false;
        for (let agtCompany of agent.companies) {
          if (company === agtCompany.insurance_company_id) {
            filteredAgents.push(agent);
            matched = true;
            break;
          }
        }
        if (matched) break;
      }
    }
  }
  else if (filterFor === 'LOBCOMPANY') {
    let lob = filterForData[0];
    let companies = filterForData.slice(1);
    for (let agent of agentsInp) {
      let skipAgent = true;
      for (let service of agent.services) {
        if (lob === service.lob.id) {
          skipAgent = false;
          break;
        }
      }
      if (!skipAgent) {
        for (let company of companies) {
          let matched = false;
          for (let agtCompany of agent.companies) {
            if (company === agtCompany.insurance_company_id) {
              filteredAgents.push(agent);
              matched = true;
              break;
            }
          }
          if (matched) break;
        }
      }
    }
  }
  return filteredAgents;
}

export const filterAdvisors = (advisorsInp, filterFor, filterForData) => {
  // let selectedPolicy = globalState.state.selectedPolicy;
  let filteredAdvisors = [];
  if (!filterFor || !filterForData || !advisorsInp || advisorsInp.length === 0)
    return filteredAdvisors;
  if (filterFor === 'CLAIM') {
    let claimtype_ids = filterForData;
    for (let advisor of advisorsInp) {
      let advisorFound = false;
      for (let claimServiced of advisor.claimsServiced) {
        for (let claimtype_id of claimtype_ids) {
          if (claimtype_id === claimServiced.claimtype.id) {
            filteredAdvisors.push(advisor);
            advisorFound = true;
            break;
          }
          if (advisorFound)
            break;
        }
      }
    }
  }
  else if (filterFor === 'SERVICES') {
    let servicesProvided = filterForData;
    for (let advisor of advisorsInp) {
      for (let serviceProvided of servicesProvided) {
        let matched = false;
        for (let service of advisor.services) {
          if (serviceProvided === service.claimservice_id) {
            filteredAdvisors.push(advisor);
            matched = true;
            break;
          }
        }
        if (matched) break;
      }
    }
  }
  else if (filterFor === 'CLAIMSERVICES') {
    let lob = filterForData[0];
    let companies = filterForData.slice(1);
    for (let advisor of advisorsInp) {
      let skipAdvisor = true;
      for (let service of advisor.services) {
        if (lob === service.lob.id) {
          skipAdvisor = false;
          break;
        }
      }
      if (!skipAdvisor) {
        for (let company of companies) {
          let matched = false;
          for (let agtCompany of advisor.companies) {
            if (company === agtCompany.insurance_company_id) {
              filteredAdvisors.push(advisor);
              matched = true;
              break;
            }
          }
          if (matched) break;
        }
      }
    }
  }
  return filteredAdvisors;
}
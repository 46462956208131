import { Div, Row, Col, Text, Image } from "atomize";
import React from "react";
import { Link } from "react-router-dom";

import COLORS from "../../Themes/Colors";
import { NSButton } from "../../components/ServicePartner/Button";
import { ArrowRightOutlined } from "@ant-design/icons";
export const Landing = () => {
  return (
    <Row>
      <Col size="12">
        <Text
          d={{ lg: "none" }}
          tag="h1"
          textSize="display1"
          m={{ b: "1em" }}
          textColor={COLORS.servicePartnerPrimary}
        >
          Join Our Hands Together
        </Text>
      </Col>
      <Col size="12" d={"flex"}>
        <Row>
          <Col
            className="d-flex align-items-center"
            size={{ xs: 12, lg: 6 }}
            order={{ xs: 2, lg: 1 }}
          >
            <Div p="1rem">
              <Text
                d={{ xs: "none", lg: "block" }}
                tag="h1"
                textSize="display2"
                m={{ b: "0.8em" }}
                textColor={COLORS.servicePartnerPrimary}
              >
                Join Our Hands Together
              </Text>
              <Text textColor={COLORS.servicePartnerText} m={{ b: "0.8em" }}>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took
              </Text>
              <Text textColor={COLORS.servicePartnerPrimary} textWeight="600">
                Connect with us
              </Text>
              <Link to="/servicepartner/signup">
                <NSButton
                  // icon={<Icon name="LongRight" size="16px" color="white" />}
                  // className="mt-2"
                  style={{ width: "50%", borderRadius: "3px", marginTop: "20px" }}
                >
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div style={{ marginLeft: "-10px" }}>
                      {" "}
                      Click to sign up{" "}</div> <div style={{ background: "#3193ff", padding: "7px", height: "40px", textAlign: "center", width: "25%", marginRight: "-16px", borderRadius: "3px" }}> <ArrowRightOutlined /> </div>
                  </div>
                </NSButton>
              </Link>
              <Div m={{ t: '1em' }}>
                <Link to="/servicepartner/login">
                  Already have an Account. Login now.
                </Link>
              </Div>
            </Div>
          </Col>
          <Col
            m={{ xs: 0, lg: { t: "-10em" } }}
            size={{ xs: 12, lg: 6 }}
            order={{ xs: 1, lg: 2 }}
          >
            <Div p="1rem">
              <Image src={require("../../assets/images/Frame68.png")} />
            </Div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

import React, { useContext } from "react";
import { Div, Image, Row, Col } from "atomize";
import "./ServiceItem.css";
import { Label, Button } from "../../components";
import Colors from "../../Themes/Colors";
//import { useHistory } from "react-router-dom";
//import axios from "../../utils/axios";
import { store } from "../../context/store";

const defaulAvatar = require("../../assets/images/avatar.png");
// const defaulAvatarMale = require("../../assets/images/businessman-icon.svg");
// const defaulAvatarFemale = require("../../assets/images/businesswoman-icon.svg");

// const starImage = (item) => {
//   switch (item) {
//     case 1:
//       return require("../../assets/images/one-star.png");
//     case 2:
//       return require("../../assets/images/two-star.png");
//     case 3:
//       return require("../../assets/images/three-star.png");
//     case 4:
//       return require("../../assets/images/four-star.png");
//     case 5:
//       return require("../../assets/images/five-star.png");
//     default:
//       return require("../../assets/images/empty-star.png");
//   }
// };

const HeaderSection = ({ item }) => {
  // const getPhoto = async (url) => {
  //   if (item.photo) {
  //     return await axios.get(item.photo);
  //   } else {
  //     return "";
  //   }
  // };

  return (
    <Div d="flex" justify="space-between">
      <Image src={item?.photo ? `${process.env.REACT_APP_MEDIA_URL}${item.photo}` : defaulAvatar} maxW="70px" maxH="70px" />
      <Div d="flex" flexDir="column" align="flex-end">
        <Div d="flex" justify="center" align="center">
          <Image
            src={require("../../assets/images/correct.png")}
            maxW="18px"
            maxH="18px"
          />
          <Label
            label={`${item?.first_name} ${item?.middle_name} ${item?.last_name} `}
            style={{
              textColor: Colors.primaryColor,
              fontFamily: "Montserrat-Bold",
              textSize: "22px",
              m: { l: "10px" },
            }}
          />
        </Div>
        {/* <Image src={starImage(item.star)} maxW="110px" m={{ t: "10px" }} /> */}
        {/* <span className="service-2-l-2">
          {item.reviewedCustomer}{" "}
          <span className="service-2-l-1">{" Customers"}</span>
        </span>  */}
      </Div>
    </Div>
  );
};

function getServicesOffered(services) {
  let servicesOffered = "";
  for (let service of services) {
    let serviceName = service.lob ? service.lob.name : '';
    if (servicesOffered !== "")
      servicesOffered = servicesOffered + "," + serviceName;
    else
      servicesOffered = serviceName;
  }
  return "Services offered : " + servicesOffered;
}

function getClaimTypesServiced(claimsServiced) {
  let claimsServicedNames = ""
  for (let claimserviced of claimsServiced) {
    let claimtypename = claimserviced.claimtype ? claimserviced.claimtype.name : '';
    if (claimsServicedNames !== "")
      claimsServicedNames = claimsServicedNames + "," + claimtypename;
    else
      claimsServicedNames = claimtypename;
  }
  return "Claim Types Serviced : " + claimsServicedNames;
}
function getCompaniesServed(companies) {
  let companiesServed = ""
  for (let company of companies) {
    if (companiesServed !== "")
      companiesServed = companiesServed + "," + company.company_name;
    else
      companiesServed = company.company_name;
  }
  return companiesServed;
}

const DetailSection = ({ item }) => {
  return (
    <Div d="flex" flexDir="column" align="flex-start">
      <Label
        label={
          item.claimsServiced ? getClaimTypesServiced(item.claimsServiced) : getServicesOffered(item.services)
        }
        type="title2"
        style={{ m: { t: "15px" } }}
      />
      <Label
        label={item?.company?.name ? item?.company?.name : item?.companies?.length > 0 ? getCompaniesServed(item.companies) : null}
        type="bodyText3"
        style={{ m: { b: "-5px" } }}
      />
      <Label
        label={item?.experience ? `Experience ${item?.experience} years` : null}
        type="bodyText3"
        style={{ m: { b: "-5px" } }}
      />
      <Label
        label={item.address}
        type="bodyText3"
        style={{ m: { b: "-5px" } }}
      />
    </Div>
  );
};

const FeatureItem = ({ label }) => {
  return (
    <Div
      border="1px solid"
      borderColor="grey"
      rounded="lg"
      d="flex"
      justify="center"
      align="center"
      m={{ b: "8px" }}
    >
      <Label
        label={label}
        type="title2"
        style={{
          m: "5px",
          textColor: Colors.primaryColor,
          textAlign: "center",
        }}
      />
    </Div>
  );
};

const FeatureSection = ({ item }) => {
  return (
    <Div m={{ t: "10px" }}>
      <Row>
        {item?.features?.map((item, i) => (
          <Col size="6" p={{ r: "3px", l: "8px" }} key={`feature-key-${i}`}>
            <FeatureItem label={item} />
          </Col>
        ))}
      </Row>
    </Div>
  );
};
const FooterSection = ({ openContactModal, item, index }) => {
  const globalState = useContext(store);
  const { dispatch } = globalState;

  return (
    <Div m={{ t: "10px" }}>
      <Row>
        <Col size="6" p={{ r: "3px", l: "8px" }}>
          <Button
            label="Get Contact Details"
            type="primary"
            onPress={() => {
              dispatch({ type: "currentService", payload: [item] });
              // console.log("index value", index)
              dispatch({ type: "nameOrder", payload: index + 1 });
              openContactModal(item?.party);
            }}
          />
        </Col>
        <Col size="6" p={{ r: "3px", l: "8px" }}>
          <Button
            label="Request Call Back"
            type="primary"
            onPress={(e) => {
              dispatch({ type: "currentService", payload: [item] });
              dispatch({ type: "nameOrder", payload: index + 1 });
              openContactModal(item?.party);
            }}
          />
        </Col>
      </Row>
    </Div>
  );
};

const ServiceItem = ({ item, openContactModal, index }) => {
  return (
    <Div className="shadow" m="10px" rounded="md" p="20px" bg="#fff">
      <HeaderSection {...{ item }} />
      <DetailSection {...{ item }} />
      <FeatureSection {...{ item }} />
      <FooterSection {...{ openContactModal, item, index }} />
    </Div>
  );
};

export default ServiceItem;

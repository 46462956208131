import React from 'react'
import { Button } from 'atomize'

import COLORS from '../../Themes/Colors'


export const NSButton = (props) => {
    const {loading, ...rest } = props;
    const getButtonIcon = () => {
        switch (props.iconposition || 'right') {
            case 'left': {
                return {
                    prefix: props.icon,
                }
            }
            default: return {
                suffix: props.icon
            }
        }
    }
    const getButtonProps = () => {
        switch (props.btntype || "primary") {
            case "secondary": {
                return {
                    bg: "white",
                    border: '0.5px solid',
                    borderColor: '#E6E6E6',
                    textColor: 'black',
                    shadow: "2",
                    type: 'button',
                    
                }
            }
            case "submit": {
                return {
                    type: 'submit',
                    bg: COLORS.servicePartnerPrimary
                }
            }
            default: return {
                bg: COLORS.servicePartnerPrimary
            }
        }
    }
    return (
        <Button
            shadow="2"
            hoverShadow="4"
            w={props.fullWidth ? '100%' : ''}
            d="flex"
            isLoading={loading || false}
            {...getButtonIcon()}
            {...getButtonProps()}
            {...rest}
        >
            <span style={{ width: '100%' }}>{props.children}</span>
        </Button>
    )
}
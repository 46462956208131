import React from "react";
import { Div, Row, Col, Text } from "atomize";
import { LinearProgress } from "@material-ui/core";

import { NSButton } from "../../components/ServicePartner/Button";
import Colors from "../../Themes/Colors";
import { NSCheckBox } from "../../components/ServicePartner/Checkbox";
import { store } from "./context/store";
import {
  createServiceProfileAsync,
  getAvailableClaimAdvisorLobAsync,
  getAvailableClaimAdvisorServicesAsync,
  getOrSaveClaimAdvisorLobAsync,
  getOrSaveClaimAdvisorServicesAsync,
} from "./Api/api";
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";
export const ClaimAdvisor = (props) => {
  const [selectedLob, setSelectedLob] = React.useState(() => []);

  const [serviceLobs, setServiceLobs] = React.useState(() => []);

  const [selectedCompany, setSelectedCompany] = React.useState(() => [1, 2]);
  const [serviceAdvisorServices, setServiceCompanies] = React.useState(
    () => []
  );
  const [fetchDetailsApiStatus, setFetchDetailsApiStatus] = React.useState(
    () => ({
      error: "",
      loading: false,
    })
  );
  const [saveDetailsApiStatus, setSaveDetailsApiStatus] = React.useState(
    () => ({
      error: "",
      loading: false,
    })
  );
  const { state } = React.useContext(store);

  React.useEffect(() => {
    async function getProfile() {
      const payload = {
        party: state.partyID,
      };
      setFetchDetailsApiStatus({ error: "", loading: true });
      setServiceLobs((prevState) => ({ ...prevState, loading: true }));

      const getSavedLobPromise = getOrSaveClaimAdvisorLobAsync(payload);
      const getSavedServicesPromise =
        getOrSaveClaimAdvisorServicesAsync(payload);

      const getAvailableLobPromise = getAvailableClaimAdvisorLobAsync();
      const getAvailableServicesPromise =
        getAvailableClaimAdvisorServicesAsync();
      Promise.all([
        getSavedLobPromise,
        getSavedServicesPromise,
        getAvailableLobPromise,
        getAvailableServicesPromise,
      ]).then((responses) => {
        if (
          !responses[0].isSuccess ||
          !responses[1].isSuccess ||
          !responses[2].isSuccess ||
          !responses[3].isSuccess
        ) {
          setFetchDetailsApiStatus({
            error: "Unable to fetch details",
            loading: false,
          });
          return;
        }
        setSelectedLob(responses[0].data);
        setSelectedCompany(responses[1].data);
        setServiceLobs(responses[2].data);
        setServiceCompanies(responses[3].data);
        setFetchDetailsApiStatus({
          error: "",
          loading: false,
        });
      });
    }
    getProfile();
  }, [state.partyID]);

  const onSubmit = (e) => {
    e.preventDefault();
    let validationError = false;
    if (!props.yearsExp) {
      validationError = true;
      setSaveDetailsApiStatus({
        error: "Please fill years of experience",
        loading: false,
      });
    } else if (typeof props.yearsExp != 'number' && isNaN(props.yearsExp)) {
      validationError = true;
      setSaveDetailsApiStatus({
        error: "Years of experience must be greater than 0 and less than 100.",
        loading: false,
      });
    } else if (props.yearsExp < 0 || props.yearsExp > 100) {
      validationError = true;
      setSaveDetailsApiStatus({
        error: "Years of experience must be greater than 0 and less than 100.",
        loading: false,
      });
    } else if (!props.selectedState) {
      validationError = true;
      setSaveDetailsApiStatus({
        error: "Please select state",
        loading: false,
      });
    } else if (!props.selectedCity) {
      validationError = true;
      setSaveDetailsApiStatus({
        error: "Please select city",
        loading: false,
      });
    } else if (selectedLob.length === 0) {
      validationError = true;
      setSaveDetailsApiStatus({
        error: "Please select line of businesses",
        loading: false,
      });
    } else if (selectedCompany.length === 0) {
      validationError = true;
      setSaveDetailsApiStatus({
        error: "Please select services provided.",
        loading: false,
      });
    }
    if (!validationError) {
      setSaveDetailsApiStatus({ error: "", loading: true });
      const serviceProfilePayload = {
        years_exp: props.yearsExp,
        party: state.partyID,
        city: props.selectedCity,
      };
      const saveProfileDetailsPromise = createServiceProfileAsync(
        serviceProfilePayload
      );
      const serviceLobPayload = {
        party: state.partyID,
        claimtype_ids: selectedLob,
      };
      const saveServiceLobPromise =
        getOrSaveClaimAdvisorLobAsync(serviceLobPayload);
      const servicesPayload = {
        party: state.partyID,
        claimservice_ids: selectedCompany,
      };
      const saveServicesPromise =
        getOrSaveClaimAdvisorServicesAsync(servicesPayload);

      Promise.all([
        saveProfileDetailsPromise,
        saveServiceLobPromise,
        saveServicesPromise,
      ])
        .then((responses) => {
          if (
            responses[0].isSuccess &&
            responses[1].isSuccess &&
            responses[2].isSuccess
          ) {
            setSaveDetailsApiStatus({ error: "", loading: false });
            props.onClickNext();
          }
        })
        .catch((err) => {
          setSaveDetailsApiStatus({
            error: "Unable to update. Try again.",
            loading: false,
          });
        });
    }
  };

  const onClickBack = () => {
    props.onClickBack();
  };

  const onClickLob = (e) => {
    const clickedLob = parseInt(e.currentTarget.id);
    if (selectedLob) {
      const selectedLobIndex = selectedLob.indexOf(clickedLob);
      if (selectedLobIndex === -1) {
        setSelectedLob((prevState) => [...prevState, clickedLob]);
      } else {
        selectedLob.splice(selectedLobIndex, 1);
        setSelectedLob([...selectedLob]);
      }
    }
  };

  const onClickService = (e) => {
    const clickedService = parseInt(e.currentTarget.id);
    if (selectedCompany) {
      const selectedServiceIndex = selectedCompany.indexOf(clickedService);
      if (selectedServiceIndex === -1) {
        setSelectedCompany((prevState) => [...prevState, clickedService]);
      } else {
        selectedCompany.splice(selectedServiceIndex, 1);
        setSelectedCompany([...selectedCompany]);
      }
    }
  };

  const getLineOfBusinessMenu = () => {
    return (
      <Row w={"100%"} p={{ y: "1em", x: "2em" }} textSize={"12px"}>
        {serviceLobs.map((lob, index) => {
          return (
            <Col
              key={lob.id.toString()}
              size={{ xs: 12, lg: 6 }}
              p={{ y: "0.7em" }}
            >
              <NSCheckBox
                id={lob.id.toString()}
                checked={selectedLob.indexOf(lob.id) !== -1}
                onChange={onClickLob}
              />
              {lob.name}
            </Col>
          );
        })}
      </Row>
    );
  };

  const getServicesMenu = () => {
    return (
      <Row w={"100%"} p={{ y: "1em", x: "2em" }} textSize={"12px"}>
        {serviceAdvisorServices.map((service, index) => {
          return (
            <Col
              key={service.id.toString()}
              size={{ xs: 12, lg: 6 }}
              p={{ y: "0.7em" }}
            >
              <NSCheckBox
                id={service.id.toString()}
                checked={selectedCompany.indexOf(service.id) !== -1}
                onChange={onClickService}
              />
              {service.name}
            </Col>
          );
        })}
      </Row>
    );
  };
  if (fetchDetailsApiStatus.error) {
    return (
      <Text
        textAlign="center"
        textSize="title"
        textColor={"red"}
        m={{ t: "0.8em" }}
      >
        {fetchDetailsApiStatus.error}
      </Text>
    );
  }
  if (fetchDetailsApiStatus.loading) {
    return <LinearProgress style={{ width: "100%" }} />;
  }

  return (
    <>
      <Col size={12}>
        <Text
          textSize={"subheader"}
          textColor={Colors.servicePartnerText}
          m={{ b: "1em" }}
        >
          Line of Business
        </Text>
        <Div d="flex" m={{ b: "3em" }} shadow={3}>
          {serviceLobs.loading ? (
            <LinearProgress style={{ width: "100%" }} />
          ) : (
            getLineOfBusinessMenu()
          )}
        </Div>
        <Text
          textSize={"subheader"}
          textColor={Colors.servicePartnerText}
          m={{ b: "0.2em" }}
        >
          Services provided
        </Text>
        <Div d="flex" m={{ b: "3em" }} shadow={3}>
          {serviceAdvisorServices.loading ? (
            <LinearProgress style={{ width: "100%" }} />
          ) : (
            getServicesMenu()
          )}
        </Div>
      </Col>
      <Col size={6}>
        <NSButton
          btntype="secondary"
          iconposition="left"
          // icon={<Icon name="LongLeft" size="16px" color="black" />}
          style={{ width: "100%", borderRadius: "3px" }}
          minW="100%"
          onClick={onClickBack}
        >
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div style={{ background: "#d5d8dc", padding: "7px", height: "40px", textAlign: "center", width: "33%", marginLeft: "-16px", borderRadius: "3px" }}> <ArrowLeftOutlined /> </div>
            <div style={{ marginRight: "20px" }}>
              Back</div>
          </div>
        </NSButton>
      </Col>
      <Col size={6}>
        <NSButton
          btntype="primary"
          onClick={onSubmit}
          style={{ width: "100%", borderRadius: "3px" }}
          // icon={<Icon name="LongRight" size="16px" color="white" />}
          minW="100%"
          loading={saveDetailsApiStatus.loading}
        >
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div style={{ marginLeft: "20px" }}>
              {" "}
              Next{" "}</div> <div style={{ background: "#3193ff", padding: "7px", height: "40px", textAlign: "center", width: "33%", marginRight: "-16px", borderRadius: "3px" }}> <ArrowRightOutlined /> </div>
          </div>
        </NSButton>
      </Col>
      <Text textSize="caption" textColor={"red"} m={{ t: "0.8em" }}>
        {saveDetailsApiStatus.error}
      </Text>
    </>
  );
};

import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import "./Slider.css";
import { Div } from "atomize";
const Slider = ({ images }) => (
  <Div rounded="sm">
    <Carousel
      renderThumbs={() => null}
      autoPlay={true}
      interval={3000}
      infiniteLoop={true}
    >
      {images.map((item, index) => (
        <div key={`sliderImagesDiv+${index}`}>
          <div>
            <img src={item.image} alt=""
              style={{ height: 300, objectFit: "cover", borderRadius: 6 }}
            />
          </div>
          <div className="slider-label-1">
            <span className="s-2-l-1">
              {item.label && item.label}{" "}
              {item.label2 && (
                <span className="s-2-l-2">
                  {item.label && "-"} {item.label2}
                </span>
              )}
            </span>
          </div>
          <div className="slider-label-2">
            <span className="s-2-l-3">{item.label3 && item.label3} </span>
          </div>
        </div>
      ))}
    </Carousel>
  </Div>
);

export default Slider;

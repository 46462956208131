import React, { createContext, useReducer } from "react";

const initialState = {
  lastStep: localStorage.getItem("lastStep") || 0,
  isLoggedIn: false,
  partyID: localStorage.getItem("party"),
  fullName: "",
  serviceCategory: localStorage.getItem("serviceCategory") || 2,
  phoneNumber: "",
};
const store = createContext(initialState);
const { Provider } = store;

const ServicePartnerStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state = initialState, action) => {
    switch (action.type) {
      case "registerSuccess":
        return {
          ...action.payload,
          lastStep: 0,
          partyID: undefined,
          serviceCategory: 2,
        };
      case "setServicePartnerSignupState":
        return {
          ...action.payload,
        };
      case "setServicePartnerLoggedInState":
        return { ...state, isLoggedIn: action.payload };
      case "setPartyID":
        return { ...state, partyID: action.payload };
      case "setLastStep":
        return { ...state, lastStep: action.payload };
      case "setServiceCategory":
        localStorage.setItem("serviceCategory", action.payload);
        return { ...state, serviceCategory: action.payload };

      default:
        throw new Error();
    }
  }, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, ServicePartnerStateProvider };

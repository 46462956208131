import React, { useState, useEffect, useContext } from "react";
import "./FilterDropdown.css";
import { Div, Row, Col, Image } from "atomize";
import { Label } from "../../components";
import Colors from "../../Themes/Colors";
import { SlideDown } from "react-slidedown";
import "react-slidedown/lib/slidedown.css";
import CheckBoxItem from "../CheckBox/CheckBox";
import Button from "../Button/Button";
import RadioBoxItem from "../RadioBox/RadioBox";
import { store } from "../../context/store";

function Dropdown({ title, items, id, filterType, buttonType, claimAndAsset }) {
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);
  const [currentClaims, setcurrentClaims] = useState([]);
  const [currentAssets, setCurrentAssets] = useState([]);
  const [currentServices, setCurrentServices] = useState([]);
  //const [selectedAssets, setSelectedAssets] = useState([]);
  const [selectedClaims, setselectedClaims] = useState([]);
  const [selectedHelps, setSelectedHepls] = useState([]);
  const globalState = useContext(store);
  const { dispatch } = globalState;

  useEffect(() => {
    // console.log({ id });
    window.addEventListener("click", function (e) {
      if (!document.getElementById(id)?.contains(e.target)) {
        setOpen(false);
      }
    });
  }, [id]);
  let arrowClass = "arrow-icon";
  if (open) {
    arrowClass = "arrow-icon arrow-active";
  }
  const [mLeft, setMLeft] = useState(0);

  // const SelectedItem = ({ click, label }) => (
  //   <Div
  //     p={{ y: "5px", r: "0px" }}
  //     d="flex"
  //     align="center"
  //     maxH="auto"
  //     minH="40px"
  //     minW="auto"
  //     maxW="auto"
  //     border={"1px solid"}
  //     rounded="sm"
  //     justify="space-between"
  //     borderColor={Colors.primaryColor}
  //     m={{ y: "10px" }}
  //     cursor="pointer"
  //     onClick={click}
  //   >
  //     <Label
  //       label={label}
  //       type="bodyText3"
  //       style={{ m: { l: "8px" }, minW: "600px", w: "auto", textAlign: "left" }}
  //     />
  //     {/* <Image
  //       src={require("../../assets/images/close.png")}
  //       maxW="20px"
  //       maxH="20px"
  //       cursor="pointer"
  //     /> */}
  //   </Div>
  // );

  // Claim selection
  const claimsSelected = (id, label) => {
    const index = currentClaims.indexOf(id);
    const labelIndex = selectedClaims.indexOf(label);

    if (index > -1) {
      const newList = currentClaims.filter((e) => e !== id);
      setcurrentClaims([...newList]);
    } else {
      currentClaims.push(id);
      setcurrentClaims([...currentClaims]);
    }
    if (labelIndex > -1) {
      const newList = selectedClaims.filter((e) => e !== label);
      setselectedClaims([...newList]);
      dispatch({ type: "selectedAvicerNames", payload: newList });
    } else {
      selectedClaims.push(label);
      setselectedClaims([...selectedClaims]);
      dispatch({ type: "selectedAvicerNames", payload: selectedClaims });
    }
  };

  // Claim Asset selection
  const assetSelected = (id, label) => {
    const index = globalState.state.selectedClaimAssets.indexOf(id);
    const labelIndex = globalState.state.selectedAdicerAssets.indexOf(label);

    if (index > -1) {
      const newList = globalState.state.selectedClaimAssets.filter(
        (e) => e !== id
      );
      setCurrentAssets([...newList]);
    } else {
      globalState.state.selectedClaimAssets.push(id);
      setCurrentAssets([...globalState.state.selectedClaimAssets]);
    }
    if (labelIndex > -1) {
      const newList = globalState.state.selectedAdicerAssets.filter(
        (e) => e !== label
      );
      //setSelectedAssets([...newList]);
      dispatch({ type: "selectedAdicerAssets", payload: [...newList] });
    } else {
      globalState.state.selectedAdicerAssets.push(label);
      //setSelectedAssets([...globalState.state.selectedAdicerAssets]);
      dispatch({
        type: "selectedAdicerAssets",
        payload: [...globalState.state.selectedAdicerAssets],
      });
    }
  };

  // service selection
  const serviceSelected = (id, label) => {
    const index = currentServices.indexOf(id);
    const labelIndex = selectedHelps.indexOf(label);

    if (index > -1) {
      const newList = currentServices.filter((e) => e !== id);
      setCurrentServices([...newList]);
    } else {
      currentServices.push(id);
      setCurrentServices([...currentServices]);
    }
    if (labelIndex > -1) {
      const newList = selectedHelps.filter((e) => e !== label);
      setSelectedHepls([...newList]);
      dispatch({ type: "selectedHelpNames", payload: [...newList] });
    } else {
      selectedHelps.push(label);
      setSelectedHepls([...selectedHelps]);
      dispatch({ type: "selectedHelpNames", payload: [...selectedHelps] });
    }
  };

  // Save the state of selected claims
  useEffect(() => {
    dispatch({ type: "selectedClaims", payload: currentClaims });
  }, //[currentClaims, dispatch]);
    [
      dispatch,
      currentClaims,
      // globalState.state.selectedClaims,
    ]);

  // Save the state of selected claims
  useEffect(() => {
    dispatch({ type: "selectedClaimAssets", payload: currentAssets });
  }, //[currentAssets, dispatch]);
    [
      dispatch,
      currentAssets,
      // globalState.state.selectedClaims,
    ]);

  // Save the help values
  useEffect(() => {
    dispatch({ type: "selectedHelps", payload: currentServices });
  }, //[currentServices, dispatch]);
    [currentServices, dispatch]);

  // Repopulate the selected array list based on the chage in claim select
  useEffect(() => {
    if (globalState.state.selectedAdicerAssets === undefined) {
      dispatch({
        type: "selectedAdicerAssets",
        payload: [],
      });
    }
    if (claimAndAsset) {
      let uiAssets = [];
      let uiAssetsName = [];
      for (let i of claimAndAsset?.[1]) {
        uiAssets.push(i.asset.id);
        uiAssetsName.push(i.asset.name);
      }
      // eslint-disable-next-line
      const filteredArray = globalState.state.selectedClaimAssets.filter(
        (value) => uiAssets.includes(value)
      );
      // eslint-disable-next-line
      const filteredAssetNameArray = globalState.state.selectedAdicerAssets?.filter(
        (value) => uiAssetsName.includes(value)
      );
      // dispatch({
      //   type: "selectedAdicerAssets",
      //   payload: filteredAssetNameArray,
      // });
      // setCurrentAssets([...filteredArray]);
      //setSelectedAssets([filteredAssetNameArray]);
    }
  }, //[claimAndAsset, dispatch, globalState.state.claimTypeAssetsArray, globalState.state.selectedAdicerAssets, globalState.state.selectedClaimAssets]);
    [globalState.state.claimTypeAssetsArray, claimAndAsset, globalState.state.selectedAdicerAssets, globalState.state.selectedClaimAssets, dispatch]);

  return (
    <Div id={id} w="100%" m={{ r: "10px" }}>
      <Div
        onClick={(e) => {
          const dropdown = document.getElementById(id).getBoundingClientRect();
          const sW = window.innerWidth;
          const mP = dropdown.left;
          const mW = 650;
          const tW = mP + mW;
          if (tW > sW) {
            const fLeft = tW - sW;
            setMLeft(fLeft + 30);
          } else {
            setMLeft(0);
          }

          toggle(!open);
        }}
        // maxW="300px"
        w="100%"
        d="flex"
        justify="space-between"
        p={{ x: "10px", y: "7px" }}
        align="center"
        border="1px solid"
        rounded="md"
        bg={open ? Colors.primaryColor : "transperent"}
        borderColor={open ? Colors.primaryColor : Colors.textSecondaryColor}
        cursor="pointer"
      >
        <Label
          label={title}
          type="title2"
          style={{
            textColor: open ? Colors.white : Colors.primaryColor,
            m: { x: "5px", y: "7px" },
          }}
        />
        <Image
          src={require("../../assets/images/arrow.png")}
          w="15px"
          height="15px"
          className={arrowClass}
        />
      </Div>
      {open && (
        <SlideDown className={"my-dropdown-slidedown"}>
          <Div
            className="dd-list"
            bg="#fff"
            p={{ x: "15px", y: "10px" }}
            border="1px solid"
            borderColor="#b5b5b5"
            maxW="650px"
            m={{ l: `-${mLeft}px` }}
          >
            {filterType === "insuranceCompany" && (
              <Div
                p={{ x: "5px" }}
                d="flex"
                cursor="pointer"
                align="center"
                maxH="45px"
                m={{ t: "12px", b: "20px" }}
                minH="40px"
                border="1px 0px 1px 1px"
                borderColor="#a0a3c8"
                className="input-container"
                maxW={{ md: "370px" }}
              >
                <input
                  className="location-input"
                  placeholder="Search companies"
                />
                <Image
                  src={require("../../assets/images/search.png")}
                  maxW="25px"
                  maxH="25px"
                  cursor="pointer"
                />
              </Div>
            )}
            {filterType === "ClaimType" && (
              <Row>
                {claimAndAsset?.[0]?.map((item, i) => (
                  <Col key={`key-${i}`} size="6">
                    {buttonType === "radio" ? (
                      <RadioBoxItem label={item?.name} />
                    ) : (
                      <CheckBoxItem
                        label={item.name}
                        action={claimsSelected}
                        selectedItems={
                          currentClaims.indexOf(item.id) > -1 ? true : false
                        }
                        id={item.id}
                      />
                    )}
                  </Col>
                ))}
              </Row>
            )}
            {filterType === "ClaimAsset" && (
              <Row>
                {items?.map((item, i) => (
                  <Col key={`key-${i}`} size="6">
                    {buttonType === "radio" ? (
                      <RadioBoxItem label={item.asset.name} />
                    ) : (
                      <CheckBoxItem
                        label={item.asset.name}
                        action={assetSelected}
                        selectedItems={
                          globalState.state.selectedClaimAssets.indexOf(item.asset.id) > -1
                            ? true
                            : false
                        }
                        id={item.asset.id}
                      />
                    )}
                  </Col>
                ))}
              </Row>
            )}
            {filterType === "ClaimHelp" && (
              <Row>
                {items.map((item, i) => (
                  <Col key={`key-${i}`} size="6">
                    {buttonType === "radio" ? (
                      <RadioBoxItem label={item.name} />
                    ) : (
                      item.name && (
                        <CheckBoxItem
                          label={item.name}
                          action={serviceSelected}
                          selectedItems={
                            currentServices.indexOf(item.id) > -1 ? true : false
                          }
                          id={item.id}
                        />
                      )
                    )}
                  </Col>
                ))}
              </Row>
            )}
            <span onClick={toggle}>
              <Button
                label="Apply filters"
                type="primary"
                textType="menu"
                style={{ w: "120px", m: { t: "5px" } }}
              />
            </span>
          </Div>
        </SlideDown>
      )}
    </Div>
  );
}

export default Dropdown;

import React from "react";
import { Div, Image } from "atomize";
import { Label, Button } from "../../../../components";
import "../../../../styles.css";
import "./AboutSection.css";

const DetailSection = () => (
  <Div w="100%" d="flex" flexDir="column" m={{ l: "15px" }}>
    <Label label="About Us." type="subHeader" style={{ m: { b: "15px" } }} />
    <Label
      label="Nsure360 is developed by Insuryze Innovation Labs, a cutting-edge insurTech venture studio promoted by industry veterans. With combined knowledge of insurance and technology we aim to develop products that adds value to our customers."
      type="bodyText3"
    />
    <Button
      label="Learn more"
      type="primary"
      style={{ maxW: "120px", m: { t: "20px" } }}
    />
  </Div>
);
const AboutSection = () => {
  return (
    <Div
      d="flex"
      flexDir={{ xs: "column", sm: "column", md: "row" }}
      justify="space-between"
      className="screen-padding"
      m={{ t: { xs: "20px", sm: "20px", md: "55px" } }}
      w="100%"
      align="center"
      p={{ y: "20px" }}
    >
      <Div
        d="flex"
        justify="start"
        flexDir={{ xs: "column", sm: "column", md: "row" }}
        align="center"
        w="100%"
        m={{ b: "20px" }}
      >
        <Image
          src={require("../../../../assets/images/sec5.jpg")}
          maxW={{ xs: "250px", sm: "300px", md: "400px", lg: "450px" }}
        />
        <DetailSection />
      </Div>
    </Div>
  );
};

export default AboutSection;

import React from "react";
import { Row, Col, Input, Text } from "atomize";
import Colors from "../../../Themes/Colors";

export const AgencyLicenceDetails = ({
  licenceNumber = "",
  regAuthority = "",
  issueDate = "",
  expDate = "",
}) => {
  return (
    <Row>
      <Col size={12} p={{ t: "2em", b: "1em" }}>
        <Text
          textSize={{ xs: "subheader", lg: "subheader" }}
          textColor={Colors.servicePartnerPrimary}
        >
          Agency Licence Details
        </Text>
      </Col>
      <Col size={4} p={{ y: "0.5em" }}>
        Agency Licence Number
      </Col>
      <Col size={8} p={{ y: "0.5em" }}>
        <Input
          value={licenceNumber}
          placeholder={"Licence Number"}
          h={"1.8rem"}
          disabled={true}
        />
      </Col>
      <Col size={4} p={{ y: "0.5em" }}>
        Registration Authority
      </Col>
      <Col size={8} p={{ y: "0.5em" }}>
        <Input
          value={regAuthority}
          placeholder={"Registration Authority"}
          h={"1.8rem"}
          disabled={true}
        />
      </Col>
      <Col size={4} p={{ y: "0.5em" }}>
        Date of Issue
      </Col>
      <Col size={8} p={{ y: "0.5em" }}>
        <Input
          value={issueDate}
          placeholder={"Date of issue"}
          h={"1.8rem"}
          disabled={true}
        />
      </Col>
      <Col size={4} p={{ y: "0.5em" }}>
        Expiry Date
      </Col>
      <Col size={8} p={{ y: "0.5em" }}>
        <Input
          value={expDate}
          placeholder={"Expiry date"}
          h={"1.8rem"}
          disabled={true}
        />
      </Col>
    </Row>
  );
};

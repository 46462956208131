import React from "react";
import { Div, Image } from "atomize";
import { Label } from "..";
import Colors from "../../Themes/Colors";

const FilterButton = ({ click }) => (
  <Div
    p={{ y: "5px", x: "20px" }}
    d={{ sm: "flex", xs: "flex", md: "none" }}
    align="center"
    maxH="45px"
    minH="40px"
    border={"1px solid"}
    rounded="sm"
    justify="space-between"
    borderColor={Colors.primaryColor}
    m={{ y: "20px" }}
    cursor="pointer"
    onClick={click}
  >
    <Label
      label="Filter the results"
      type="bodyText3"
      style={{ m: { l: "8px" }, w: "100%", textAlign: "center" }}
    />
    <Image
      src={require("../../assets/images/filter.png")}
      maxW="25px"
      maxH="25px"
      cursor="pointer"
    />
  </Div>
);

export default FilterButton;

import React from "react";
import { Div } from "atomize";

import { HeaderSection } from "./Sections/HeaderSection";
import { FeatureSection } from "./Sections/FeatureSection";
import { SliderSection } from "./Sections/SliderSection";
import { DetailSection } from "./Sections/DetailSection";
import { AboutSection } from "./Sections/AboutSection";
import { ContactSection } from "./Sections/ContactSection";

const Home = () => {
  return (
    <Div>
      <HeaderSection />
      <FeatureSection />
      <SliderSection />
      <DetailSection />
      <AboutSection />
      <ContactSection />
    </Div>
  );
};

export default Home;

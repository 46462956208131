import React /*, { useEffect, useState }*/ from "react";
import { Div, Checkbox } from "atomize";
import { Label } from "..";
import Colors from "../../Themes/Colors";
//import { faCloudShowersHeavy } from "@fortawesome/free-solid-svg-icons";

const CheckBoxItem = ({ label, type, action, selectedItems, id }) => {
  // const [status, setButtonStatus] = useState(false);
  // const [buttonstatus, setStatus] = useState(faCloudShowersHeavy);
  const borderColor = type === "contact" ? Colors.white : Colors.primaryColor;
  const inactiveColor =
    type === "contact" ? Colors.primaryColor : Colors.textSecondaryColor;
  const labelType = type === "contact" ? "bodyText3" : "bodyText3";
  const textColor =
    type === "contact" ? Colors.primaryColor : Colors.textMainColor;
  const x = type === "contact" ? "0px" : "10px";
  const maxH = type === "contact" ? "22px" : "100px";
  const minH = type === "contact" ? "22px" : "40px";

  // useEffect(() => {
  // }, [status, action, selectedItems, id]);

  return (
    <Div
      p={{ x, y: "3px" }}
      d="flex"
      border="1px solid"
      borderColor={borderColor}
      rounded="sm"
      cursor="pointer"
      align="center"
      maxH={maxH}
      minH={minH}
      m={{ y: "7.5px" }}
      onClick={() => {
        action(id, label);
      }}
      id={id}
    >
      <Checkbox
        checked={selectedItems}
        inactiveColor={inactiveColor}
        activeColor={Colors.primaryColor}
        size="20px"
        onChange={() => { }}
        id={id}
      />
      <Label
        label={label}
        type={labelType}
        style={{ m: { l: "5px" }, textColor }}
      />
    </Div>
  );
};

export default CheckBoxItem;

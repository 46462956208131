import React, { useState } from "react";
import { Div, Image } from "atomize";
import { Label, Button } from "../../../components";
import "../../../styles.css";
import "./ContactSection.css";
import { validateEmail } from '../../ServicePartner/Validations'
import axios from "../../../utils/axios";
const DetailSection = () => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")

  function clearMessage() {
    setErrorMessage("")
    setSuccessMessage("")
  }

  const postDetails = async () => {
    await axios
      .post("/api/corporates/add/", {
        name: name,
        email: email,
        phone: phone
      }).then((response) => {
        setSuccessMessage("Subscribed successfully")
        setTimeout(clearMessage, 3000)
      }
      ).catch((error) => {
        if (name.length === 0) {
          setErrorMessage("Name required")
          setTimeout(clearMessage, 3000)
        }
        else if (phone.length < 9 || phone.length > 12) {
          setErrorMessage("Invalid Number")
          setTimeout(clearMessage, 3000)
        }
        else if (!validateEmail(email)) {
          setErrorMessage("Invalid email")
          setTimeout(clearMessage, 3000)
        }
        else {
          setErrorMessage("Email already registered")
          setTimeout(clearMessage, 3000)
        }
      })
  }

  return (
    <Div w="100%" d="flex" flexDir="column" justify="center" className="md-1">
      <Label label="Contact." type="subHeader" style={{ m: { b: "10px" } }} />
      <Div
        d="flex"
        m={{ t: "18px" }}
        maxW="100%"
        flexDir={{ xs: "column", sm: "column", md: "row" }}
      >
        <input type="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Name"
          style={{ border: "1px solid gray", borderRadius: "7px", marginRight: "3px", paddingLeft: "5px" }} />
        <input type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)} placeholder="Email"
          style={{ border: "1px solid gray", borderRadius: "7px", marginRight: "3px", paddingLeft: "10px" }} />
        <input type="tel"
          maxLength="12"
          minLength="9"
          value={phone}
          onChange={(e) => setPhone(e.target.value)} placeholder="Phone Number"
          style={{ border: "1px solid gray", borderRadius: "7px", marginRight: "3px", paddingLeft: "10px" }} />
        <Button
          label="Submit"
          type="primary"
          style={{ m: { l: "5px" }, maxW: "110px" }}
          onPress={postDetails}
        />
      </Div>
      <p style={{ color: "#7cadf1", fontSize: ".9rem" }}>{successMessage}</p>
      <p style={{ color: "red", fontSize: ".9rem" }}>{errorMessage}</p>
    </Div>
  );
}
const DetailMobileSection = () => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")


  function clearMessage() {
    setErrorMessage("")
    setSuccessMessage("")
  }

  const postDetails = async () => {
    await axios.post("/api/corporates/add/", {
      email: email,
      name: name,
      phone: phone
    }).then((response) => {
      setSuccessMessage("Subscribed successfully")
      setTimeout(clearMessage, 3000)
    }
    ).catch((error) => {
      if (name.length === 0) {
        setErrorMessage("Name required")
        setTimeout(clearMessage, 3000)
      }
      else if (phone.length < 9 || phone.length > 12) {
        setErrorMessage("Invalid Number")
        setTimeout(clearMessage, 3000)
      }
      else if (!validateEmail(email)) {
        setErrorMessage("Invalid email")
        setTimeout(clearMessage, 3000)
      }
      else {
        setErrorMessage("Email already registered")
        setTimeout(clearMessage, 3000)
      }
    })
  }
  return (
    <Div w="100%" d="flex" flexDir="column" className="sm-1">
      <Label
        label="Connect with us to get updates on future offerings."
        type="subHeader"
        style={{ m: { b: "10px" } }}
      />
      <Div d="flex">
        <Div
          d="flex"
          justify="center"
          align="center"
          w="100%"
          maxW={{ xs: "150px", sm: "150px" }}
        >
          <Image
            src={require("../../../assets/images/sec6.png")}
            maxW={{ xs: "100px", sm: "100px" }}
          />
        </Div>
        <Div
          d="flex"
          m={{ t: "18px" }}
          w="100%"
          maxW="600px"
          h="100%"
          flexDir={{ xs: "column", sm: "column", md: "row" }}
          justify="space-between"
          minH="200px"
        >
          <input type="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Name"
            style={{ border: "1px solid gray", borderRadius: "7px", marginRight: "3px", paddingLeft: "5px" }} />
          <input type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)} placeholder="Email"
            style={{ border: "1px solid gray", borderRadius: "7px", marginRight: "3px", paddingLeft: "10px" }} />
          <input type="number"
            value={phone}
            onChange={(e) => setPhone(e.target.value)} placeholder="Phone Number"
            style={{ border: "1px solid gray", borderRadius: "7px", marginRight: "3px", paddingLeft: "10px" }} />
          <p style={{ color: "#7cadf1", fontSize: ".9rem" }}>{successMessage}</p>
          <p style={{ color: "red", fontSize: ".9rem" }}>{errorMessage}</p>
          <Button label="Submit" type="primary" style={{ maxW: "110px" }} onPress={postDetails} />
        </Div>
      </Div>
    </Div>
  )
};

const ContactSection = () => {
  return (
    <Div
      d="flex"
      flexDir={{ xs: "column", sm: "column", md: "row" }}
      justify="space-between"
      m={{ t: { xs: "20px", sm: "20px", md: "75px" } }}
      w="100%"
      bg="#f7f7f7"
      p={{ x: { xs: "20px", sm: "20px", md: "50px" }, y: "20px", r: "40px" }}
      align="center"
    >
      <DetailSection />
      <DetailMobileSection />
      <Div
        d="flex"
        justify="center"
        align="center"
        w="100%"
        className="md-1"
        maxW={{ lg: "250px", md: "200px", xl: "250px" }}
        m={{ l: "20px" }}
      >
        <Image
          src={require("../../../assets/images/sec6.png")}
          maxW={{ lg: "200px", md: "150px", xl: "200px" }}
        />
      </Div>
    </Div>
  );
};

export default ContactSection;

import React from "react";
import { Div, Image } from "atomize";
import { Label } from "../../../../components";
import "../../../../styles.css";
import "./FeatureSection.css";

const FeatureItem = ({ title, label, image }) => (
  <Div d="flex" m={{ l: "10px", b: "20px" }} maxW="450px">
    <Image
      src={require(`../../../../assets/images/${image}`)}
      maxW={{ xs: "55px", sm: "55px" }}
      maxH={{ xs: "62px", sm: "62px" }}
    />
    <Div m={{ l: "10px" }}>
      <Label
        label={title}
        type="title2"
        style={{
          className: "label",
          m: { b: "2px" },
        }}
      />
      <Label
        label={label}
        type="bodyText3"
        style={{
          className: "label",
        }}
      />
    </Div>
  </Div>
);

const DetailSection = () => (
  <Div w="100%" d="flex" flexDir={{ xs: "column", sm: "column", md: "row" }}>
    <FeatureItem
      label="Enter the parameter of your requirements and Nsure360 will collect you with top experts."
      title="Enter Your Requirement"
      image="sec3a.png"
    />
    <FeatureItem
      label="Select with confidence from the shortlisted experts based on your needs."
      title="Select the best match"
      image="sec3b.png"
    />
    <FeatureItem
      label="View contact details or connect immediately with the expert thru phone, SMS or email."
      title="Connect Instantly"
      image="sec3c.png"
    />
  </Div>
);
const FeatureSection = () => {
  return (
    <Div
      d="flex"
      flexDir={{ xs: "column", sm: "column", md: "row" }}
      justify="space-between"
      className="screen-padding"
      m={{ t: { xs: "20px", sm: "20px", md: "75px" } }}
      w="100%"
      align="center"
      bg="#f7f7f7"
      p={{ y: "20px" }}
    >
      <Div
        d="flex"
        justify="start"
        align="center"
        w="100%"
        m={{ b: "20px" }}
        maxW={{ md: "230px" }}
      >
        <Image
          src={require("../../../../assets/images/sec2.png")}
          maxW={{ xs: "120px", sm: "150px", md: "200px" }}
        />
        <Label
          label="Hire insurance experts from the comfort of your home in three easy steps."
          type="subHeader"
          style={{
            m: { l: "10px", b: "20px" },
            className: "sm-1",
          }}
        />
      </Div>
      <Div>
        <Label
          label="Hire insurance experts from the comfort of your home in three easy steps."
          type="subHeader"
          style={{
            m: { l: "10px", b: "20px" },
            className: "md-1",
            maxW: "750px",
          }}
        />
        <DetailSection />
      </Div>
    </Div>
  );
};

export default FeatureSection;

import React from "react";
import { Text } from "atomize";
import Color from "../../Themes/Colors";
import "./Label.css";
const customStyle = {
  header: {
    textSize: "30px",
    fontFamily: "Montserrat-Bold",
    textColor: Color.textMainColor,
    className: "header",
  },
  subHeader: {
    textSize: "25px",
    fontFamily: "Montserrat-Bold",
    textColor: Color.textMainColor,
    className: "sub-header",
  },
  title: {
    textSize: "15.2px",
    fontFamily: "Montserrat-Bold",
    textColor: Color.textMainColor,
  },
  title2: {
    textSize: "12.5px",
    fontFamily: "Montserrat-Bold",
    textColor: Color.textMainColor,
  },
  bodyText1: {
    textSize: "18px",
    fontFamily: "Montserrat-Regular",
    textColor: Color.textSecondaryColor,
    className: "body-text-1",
  },
  bodyText2: {
    textSize: "14.2px",
    fontFamily: "Montserrat-Regular",
    textColor: Color.textSecondaryColor,
  },
  bodyText3: {
    textSize: "14px",
    fontFamily: "Montserrat-Medium",
    textColor: Color.textSecondaryColor,
    className: "body-text-3",
  },

  buttonText: {
    textSize: "11.5px",
    fontFamily: "Montserrat-Bold",
    textColor: Color.textSecondaryColor,
  },

  buttonText2: {
    textSize: "13.5px",
    fontFamily: "Montserrat-Regular",
    textColor: Color.white,
  },
};

const Label = ({ type, label, style }) => {
  return (
    <Text {...customStyle[type]} {...style}>
      {label}
    </Text>
  );
};

export default Label;

//Request paths for APIS
import paths from "../constants/routes.json";
const requests = {
  fetchPolicyTypes: paths.POLICY_TYPE,
  fetchAssets: paths.FETCH_ASSETS,
  fetchClaims: paths.FETCH_CLAIMS,
  fetchAgentsWithTypes: paths.FETCH_AGENTS_WITH_TYPE,
  fetchAgentsWithTypeAsset: paths.FETCH_AGENTS_WITH_TYPE_ASSET,
  fetchAgetsByTypeCityCompany: paths.FETCH_AGENTS_WITH_TYPE_CITY_COMPANIES,
  fetchLocationWithPin: paths.FETCH_LOCATION_WITH_PIN,
  fetchLocationWithCity: paths.FETCH_LOCATION_WITH_CITY,
  fetchLocalityWithPin: paths.FETCH_LOCALITY_WITH_PIN,
  fetchLocalityWithCity: paths.FETCH_LOCALITY_WITH_CITY,
  fetchAgentsWithLocation: paths.FETCH_AGENTS_WITH_LOCATION,
  fetchCityDetails: paths.FETCH_CITY_DETAILS,
  fetchLocalityDetails: paths.FETCH_LOCALITY_DETAILS,
  fetchCompanyList: paths.FETCH_COMPANY_LIST,
  fetchAgentsWithCompany: paths.FETCH_AGENTS_COMPANY,
  fetchAdvisorWithLocation: paths.FETCH_ADVISOR_WITH_LOCATION,
  fetchClaimTypes: paths.FETCH_CLAIM_TYPES,
  fetchAdvisorClaim: paths.FETCH_ADVISOR_CLAIM_LOCATION,
  fetchClaimAssets: paths.FETCH_CLAIM_TYPE_ASSETS,
  fetchAdvisorsAssets: paths.FETCH_ADVISOR_CLAIM_LOCATION_ASSETS,
  customerRegistration: paths.CUSTOMER_REGISTARTION,
  otpValidation: paths.VALIDATE_OTP,
  enquiryClaimPost: paths.ENQUIRY_CLAIM_POST_DATA,
  enquiryInsurancePost: paths.ENQUIRY_INSURANCE_POST_DATA,
  getServiceProvider: paths.PROVIDER_CONTACT_DETAILS,
  fetchClaimService: paths.FETCH_CLAIM_SERVICE,
  fetchAdvisorsWithServices: paths.FETCH_ADVISORS_WITH_SERVICE,
  postCustomerAgreement: paths.POST_CUSTOMER_AGREEMENT,

};

export default requests;

import React from "react";
import { Div, Row, Col, Text, Image } from "atomize";
//import { useFormik, Field } from "formik";
import { MenuItem, LinearProgress } from "@material-ui/core";

import { NSButton } from "../../components/ServicePartner/Button";
import { NSUpload } from "../../components/ServicePartner/Upload";
import { InputText } from "../../components/ServicePartner/InputText";
import { InputSelect } from "../../components/ServicePartner/InputSelect";
import Colors from "../../Themes/Colors";
import { NSBanner } from "../../components/ServicePartner/Banner";
import { store } from "./context/store";
import {
  MEDIA_URL,
  getAvailableKycDocuments,
  getOrSaveKycDetails,
} from "./Api/api";
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";
export const KYCDetails = (props) => {
  const [kycDetails, setKycDetails] = React.useState(() => ({
    number: "",
    document_type: undefined,
  }));
  const [availableDocuments, setAvailableDocuments] = React.useState(() => ({
    data: [],
    loading: false,
    error: "",
  }));
  const [fetchDetailsApiStatus, setFetchDetailsApiStatus] = React.useState(
    () => ({
      error: "",
      loading: false,
    })
  );
  const [saveDetailsApiStatus, setSaveDetailsApiStatus] = React.useState(
    () => ({
      error: "",
      loading: false,
    })
  );
  const [uploadedFile, setUploadedFile] = React.useState(() => undefined);

  const { state } = React.useContext(store);

  React.useEffect(() => {
    setFetchDetailsApiStatus({
      error: "",
      loading: true,
    });
    const payload = new FormData();
    payload.append("party", state.partyID);
    async function getProfile() {
      const getKyc = await getOrSaveKycDetails(payload);
      if (getKyc.isSuccess) {
        if (getKyc.data.length > 0) {
          setKycDetails(getKyc.data[0]);
          if (getKyc.data[0].name) {
            setUploadedFile(`${MEDIA_URL}${getKyc.data[0].name}`);
          }
        }

        setFetchDetailsApiStatus({
          error: "",
          loading: false,
        });
      } else {
        setFetchDetailsApiStatus({
          error: "Unable to fetch documents",
          loading: false,
        });
      }
      const getAvailableKyc = await getAvailableKycDocuments();
      if (getAvailableKyc.isSuccess) {
        setAvailableDocuments({
          data: getAvailableKyc.data,
          error: "",
          loading: false,
        });
      } else {
        setAvailableDocuments({
          data: [],
          error: "Unable to fetch documents",
          loading: false,
        });
      }
    }
    getProfile();
  }, [state.partyID]);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!kycDetails.document_type) {
      setSaveDetailsApiStatus({
        error: "Please select a document",
        loading: false,
      });
      return;
    }
    if (!kycDetails.number) {
      setSaveDetailsApiStatus({
        error: "Please fill document number",
        loading: false,
      });
      return;
    }
    if (!uploadedFile) {
      setSaveDetailsApiStatus({
        error: "Please upload kyc document",
        loading: false,
      });
      return;
    }
    setSaveDetailsApiStatus({
      error: "",
      loading: true,
    });
    const payload = new FormData();
    payload.append("party", state.partyID);
    payload.append("number", kycDetails.number);
    payload.append("document_type", kycDetails.document_type);
    typeof uploadedFile === "object" && payload.append("name", uploadedFile);
    const getKyc = await getOrSaveKycDetails(payload);
    if (getKyc.isSuccess) {
      props.onClickNext();
    } else {
      setSaveDetailsApiStatus({
        error: "Unable to update details. Try again",
        loading: false,
      });
    }
  };

  const onClickBack = () => {
    props.onClickBack();
  };

  const onChangeDocType = async (e) => {
    setKycDetails({ ...kycDetails, document_type: e.target.value });
  };
  const onChangeDocNumber = async (e) => {
    setKycDetails({ ...kycDetails, number: e.target.value });
  };

  const getAvailableDocuments = () => {
    return availableDocuments.data.map((aDocument) => {
      return (
        <MenuItem key={`state-options-${aDocument.id}`} value={aDocument.id}>
          {aDocument.name}
        </MenuItem>
      );
    });
  };
  const onChangeFile = (file) => {
    setUploadedFile(file);
  };

  const getKycDocument = () => {
    switch (typeof uploadedFile) {
      case "string":
        return uploadedFile;
      case "object":
        return URL.createObjectURL(uploadedFile);
      default:
        return "";
    }
  };

  if (fetchDetailsApiStatus.error) {
    return (
      <Text
        textAlign="center"
        textSize="title"
        textColor={"red"}
        m={{ t: "0.8em" }}
      >
        {fetchDetailsApiStatus.error}
      </Text>
    );
  }
  if (fetchDetailsApiStatus.loading) {
    return <LinearProgress />;
  }

  return (
    <Row>
      <Col size={12}>
        <NSBanner />
      </Col>
      <Col size={{ xs: 12, lg: 6 }} order={{ xs: 2, lg: 1 }}>
        <Div p={{ x: "1rem" }}>
          <Text
            textSize={{ xs: "heading", lg: "heading" }}
            textColor={Colors.servicePartnerText}
          >
            KYC Information
          </Text>
          <form onSubmit={onSubmit}>
            <Row>
              <Col size={12} p={{ b: "2em" }}>
                {availableDocuments.loading ? (
                  <LinearProgress />
                ) : (
                  <InputSelect
                    value={kycDetails.document_type}
                    id="state"
                    placeholder="Document Type"
                    options={getAvailableDocuments()}
                    onChange={onChangeDocType}
                  />
                )}
                <InputText
                  value={kycDetails.number}
                  id="firstName"
                  placeholder="Document Number"
                  onChange={onChangeDocNumber}
                //   error={formik.touched.firstName && formik.errors.firstName}
                />
                <NSUpload
                  onChange={onChangeFile}
                  defaultFile={getKycDocument()}
                  label={"Upload KYC"}
                  fileLabel={"KYC document"}
                />
              </Col>
              <Col size={6}>
                <NSButton
                  btntype="secondary"
                  iconposition="left"
                  // icon={<Icon name="LongLeft" size="16px" color="black" />}
                  style={{ width: "100%", borderRadius: "3px" }}
                  minW="100%"
                  onClick={onClickBack}
                >
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div style={{ background: "#d5d8dc", padding: "7px", height: "40px", textAlign: "center", width: "33%", marginLeft: "-16px", borderRadius: "3px" }}> <ArrowLeftOutlined /> </div>
                    <div style={{ marginRight: "20px" }}>
                      Back</div>
                  </div>
                </NSButton>
              </Col>
              <Col size={6}>
                <NSButton
                  btntype="submit"
                  style={{ width: "100%", borderRadius: "3px" }}
                  // icon={<Icon name="LongRight" size="16px" color="white" />}
                  minW="100%"
                  loading={saveDetailsApiStatus.loading}
                >
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div style={{ marginLeft: "20px" }}>
                      {" "}
                      Next{" "}</div> <div style={{ background: "#3193ff", padding: "7px", height: "40px", textAlign: "center", width: "33%", marginRight: "-16px", borderRadius: "3px" }}> <ArrowRightOutlined /> </div>
                  </div>
                </NSButton>
              </Col>
              <Text textSize="caption" textColor={"red"} m={{ t: "0.8em" }}>
                {saveDetailsApiStatus.error}
              </Text>
            </Row>
          </form>
        </Div>
      </Col>
      <Col
        m={{ xs: 0, lg: { t: "-10em" } }}
        size={{ xs: 12, lg: 6 }}
        order={{ xs: 1, lg: 2 }}
      >
        <Div p="1rem">
          <Image src={require("../../assets/images/Frame67.png")} />
        </Div>
      </Col>
    </Row>
  );
};

export const policies = [
  {
    id: "1",
    name: "Fire Policy",
  },
  {
    id: "2",
    name: "Life Policy",
  },
  {
    id: "3",
    name: "Motor Policy",
  },
  {
    id: "4",
    name: "Pension Plan",
  },
  {
    id: "5",
    name: "Engineering Policy",
  },
  {
    id: "6",
    name: "Othe General Insurance Policy",
  },
  {
    id: "7",
    name: "Health Policy",
  },
];

export const insureItems = [
  {
    id: "1",
    name: "Residence & Household Articles",
  },
  {
    id: "2",
    name: "Commercial Vehicle",
  },
  {
    id: "3",
    name: "Office/Factory/Godown",
  },
  {
    id: "4",
    name: "Personal Vehicle",
  },
  {
    id: "5",
    name: "Stocks",
  },
  {
    id: "6",
    name: "Self/Family Member",
  },
  {
    id: "7",
    name: "Plant & Machineries",
  },
  {
    id: "8",
    name: "Employee",
  },
  {
    id: "9",
    name: "Furniture & Fixtures",
  },
];

export const insuraceCompanies = [
  {
    id: "1",
    name: "Insurance Company 01",
  },
  {
    id: "2",
    name: "Insurance Company 02",
  },
  {
    id: "3",
    name: "Insurance Company 03",
  },
  {
    id: "4",
    name: "Insurance Company 04",
  },
  {
    id: "5",
    name: "Insurance Company 05",
  },
  {
    id: "6",
    name: "Insurance Company 06",
  },
];

import React, { useEffect, useState } from "react";
import { Div, Image, Row, Col } from "atomize";
import "./ServiceItemDetail.css";
import { Label } from "..";
import Colors from "../../Themes/Colors";
import { store } from "../../context/store";
import { useHistory } from "react-router-dom";
import constantValues from "../../constants/others.json";
import { CgSpinner } from "react-icons/cg";

const defaulAvatar = require("../../assets/images/avatar.png");

// const getBackPath = ({ path }) => {
//   switch (true) {
//     case path.includes("/service/claimadvice/detail"): {
//       return "/service/claimadvice";
//     }
//     case path.includes("/service/buyinsurance/detail"): {
//       return "/service/buyinsurance";
//     }
//     case path.includes("/service/claimadvice"): {
//       return "/";
//     }
//     case path.includes("/service/buyinsurance"): {
//       return "/";
//     }
//     case path.includes("/servicepartner"): {
//       return "/";
//     }
//     case path.includes("/coporatesolutions"): {
//       return "/";
//     }

//     default:
//       return "/";
//   }
// };

// const starImage = (item) => {
//   switch (item) {
//     case 1:
//       return require("../../assets/images/one-star.png");
//     case 2:
//       return require("../../assets/images/two-star.png");
//     case 3:
//       return require("../../assets/images/three-star.png");
//     case 4:
//       return require("../../assets/images/four-star.png");
//     case 5:
//       return require("../../assets/images/five-star.png");
//     default:
//       return require("../../assets/images/empty-star.png");
//   }
// };
const HeaderSection = (props) => {
  const globalState = React.useContext(store);
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [photoPath, setPhotoPath] = useState("");
  const history = useHistory();

  useEffect(() => {
    setFirstName(globalState?.state?.currentService?.[0]?.first_name);
    setMiddleName(globalState?.state?.currentService?.[0]?.middle_name);
    setLastName(globalState?.state?.currentService?.[0]?.last_name);
    setPhotoPath(globalState?.state?.currentService?.[0]?.photo);
  }, [history.location, globalState.state.currentService]);

  return (
    <Div d="flex" justify="space-between">
      {/* <Image src={`http://142.93.192.140:8004${photoPath}`} maxW="60px" /> */}
      <Image
        src={
          photoPath
            ? `${process.env.REACT_APP_MEDIA_URL}${photoPath}`
            : defaulAvatar
        }
        maxW="60px"
      />
      {/* <Image src={`${process.env.REACT_APP_MEDIA_URL}${photoPath}`} maxW="60px"/> */}
      <Div d="flex" flexDir="column" align="flex-end">
        <Div d="flex" justify="center" align="center">
          <Image
            // src={`http://142.93.192.140:8004${globalState?.state?.currentService?.[0]?.photo}`}
            src={require("../../assets/images/correct.png")}
            maxW="18px"
            maxH="18px"
          />
          <Label
            label={`${firstName} ${middleName} ${lastName}`}
            style={{
              textColor: Colors.primaryColor,
              fontFamily: "Montserrat-Bold",
              textSize: "22px",
              m: { l: "10px" },
            }}
          />
        </Div>
        {/* <Image src={starImage(item.star)} maxW="110px" m={{ t: "10px" }} /> */}
        {/* <span className="service-2-l-2">
          {item.reviewedCustomer}{" "}
          <span className="service-2-l-1">{" Customers"}</span>
        </span> */}
      </Div>
    </Div>
  );
};

const DetailSection = ({ item }) => {
  const history = useHistory();
  // console.log({ location: history.location });
  const currentLocation = history.location.pathname;
  const [category, setCategory] = useState("");
  const [serviceLocation, setServiceLocation] = useState("");
  const path = currentLocation.slice(0, 21);
  useEffect(() => {
    setCategory(
      constantValues.BUY_INSURANCE_PATH === path
        ? constantValues.INSURANCE_AGENT
        : constantValues.CLAIM_ADVISOR
    );
    setServiceLocation(item.address);
  }, [history.location, item.address, path]);

  const scrollTo = () => {
    var elmnt = document.getElementById("content");
    elmnt.scrollIntoView();
  };

  useEffect(() => {
    // window.scroll({top: 0, left: 0, behavior: 'smooth' });
    scrollTo();
  }, [currentLocation]);

  return (
    <Div d="flex" flexDir="column" align="flex-start">
      <Label label={category} type="title2" style={{ m: { t: "15px" } }} />
      {/* <Label
        label={item.company}
        type="bodyText3"
        style={{ m: { b: "-5px" } }}
      /> */}
      {/* <Label
        label={item.experience}
        type="bodyText3"
        style={{ m: { b: "-5px" } }}
      /> */}
      <Label
        label={serviceLocation}
        type="bodyText3"
        style={{ m: { b: "-5px" } }}
      />
    </Div>
  );
};

const FeatureItem = ({ label }) => {
  return (
    <Div
      border="1px solid"
      borderColor="grey"
      rounded="lg"
      d="flex"
      justify="center"
      align="center"
      m={{ b: "8px" }}
    >
      <Label
        label={label}
        type="title2"
        style={{
          m: "5px",
          textColor: Colors.primaryColor,
          textAlign: "center",
        }}
      />
    </Div>
  );
};

const FeatureSection = ({ item }) => {
  const globalState = React.useContext(store);
  const [currentService, setService] = useState([]);
  const history = useHistory();
  const currentLocation = history.location.pathname;
  const path = currentLocation.slice(0, 21);

  useEffect(() => {
    setService(globalState?.state?.currentService?.[0]?.services);
  }, [history.location, globalState.state.currentService]);

  useEffect(() => {
    const changePath = () => {
      const currentLocation = history.location.pathname;
      if (!globalState?.state?.currentService?.[0]?.services) {
        const path = currentLocation.slice(0, 21);
        if (constantValues.BUY_INSURANCE_PATH === path) {
          history.push(constantValues.BUY_INSURANCE_PATH);
        } else {
          history.push(constantValues.CLAIM_ADVISOR_PATH);
        }

        // history.push("/service/buyinsurance");
      }
    };
    changePath();
  }, [globalState, history]);

  return (
    <Div m={{ t: "10px" }}>
      <Row>
        {currentService.map((item) => (
          <Col size="12" p={{ r: "3px", l: "8px" }}>
            {constantValues.BUY_INSURANCE_PATH === path ? (
              <FeatureItem label={item?.lob_type?.name} />
            ) : (
              <FeatureItem label={item?.claimtype?.name} />
            )}
          </Col>
        ))}
      </Row>
    </Div>
  );
};

const ContactItem = ({ border, title, icon, value }) => {
  return (
    <Div
      d="flex"
      align="center"
      p={{ x: "14px", y: "10px" }}
      bg={Colors.primaryColor}
      w="350px"
      className={border && "border-bottom"}
    >
      <Image src={icon} maxW="35px" />
      <Div m={{ l: "10px" }}>
        <Label label={title} type="bodyText2" style={{ textColor: "#000000" }} />
        <Label label={value} type="title2" style={{ textColor: "#fff" }} />
      </Div>
    </Div>
  );
};

const FooterSection = ({ openContactModal }) => {
  const globalState = React.useContext(store);
  return (
    <Div d="flex" justify={{ md: "space-between", xs: "", sm: "", lg: "space-between" }} h="100%">
      <Row
        bg={Colors.primaryColor}
        maxW={{ sm: "100%", xs: "100%", md: "302px", lg: "500px" }}
      >
        {/* <Row size={{ md: "12", xs: "6", sm: "6" }} p={{ r: "3px", l: "8px" }}> */}
        <ContactItem
          // border={true}
          icon={require("../../assets/images/email.png")}
          title="Email"
          value={
            globalState?.state?.providerDetails?.email_address?.[0]?.email
          }
        />
        {/* </Row> */}
        {/* <Row size={{ md: "12", xs: "6", sm: "6" }} p={{ r: "3px", l: "8px" }}> */}
        <ContactItem
          // border={true}
          icon={require("../../assets/images/whatsapp.png")}
          title="Whatsapp"
          value={
            globalState?.state?.providerDetails?.phone_numbers?.[0]?.phone
          }
        />
        {/* </Row> */}
        {/* <Row size={{ md: "12", xs: "6", sm: "6" }} p={{ r: "3px", l: "8px" }}> */}
        <ContactItem
          // border={true}
          icon={require("../../assets/images/sms.png")}
          title="Message"
          value={
            globalState?.state?.providerDetails?.phone_numbers?.[0]?.phone
          }
        />
        {/* </Row> */}
        {/* <Col size={{ md: "12", xs: "6", sm: "6" }} p={{ r: "3px", l: "8px" }}> */}
        <ContactItem
          // border={true}
          icon={require("../../assets/images/phone.png")}
          title="Call"
          value={
            globalState?.state?.providerDetails?.phone_numbers?.[0]?.phone
          }
        />
        {/* </Col> */}
      </Row>
    </Div>
  );
};

const ServiceItemDetail = ({ item, openContactModal }) => {
  const [showCard, setShowCard] = useState(false);
  useEffect(() => {
    setShowCard(false);
    setTimeout(function () {
      setShowCard(true);
    }, 1500);
  }, [item]);
  return showCard ? (
    <Row
      className="shadow"
      m="0"
      rounded="md"
      d="flex"
      bg="#fff"
      w="100%"
      maxW="1000px"
      p={{ l: { md: "20px", xs: "0", sm: "0" } }}
      id="content"
    >
      <Col
        size={{ md: "5", lg: "4", xl: "4", xs: "12", sm: "12" }}
        p={{
          y: { md: "20px", sm: "0", xs: "0" },
          x: { md: "0", sm: "20px", xs: "20px" },
        }}
      >
        <Div
          className="item-border"
          p={{ x: "20px", b: "20px", t: "20px" }}
          h="100%"
        >
          <HeaderSection {...{ item }} />
          <DetailSection {...{ item }} />
        </Div>
      </Col>
      <Col
        size={{ md: "4", xs: "12", sm: "12" }}
        p={{
          y: { md: "20px", sm: "0", xs: "0" },
          x: { md: "0", sm: "20px", xs: "20px" },
        }}
      >
        <Div
          className="item-border"
          h="100%"
          p={{
            x: { md: "15px", lg: "35px", xl: "35px" },
            y: { sm: "10px", xs: "10px", md: "0" },
          }}
          d="flex"
          justify="center"
          align="center"
        >
          <FeatureSection {...{ item }} />
        </Div>
      </Col>
      <Col
        size={{ md: "3", xs: "12", sm: "12" }}
        bg={{ md: "#fff", sm: Colors.primaryColor, xs: Colors.primaryColor }}
      >
        <FooterSection {...{ openContactModal }} />
      </Col>
    </Row>
  ) : (
    <div
      style={{
        textAlign: "center",
        paddingTop: "80px",
        paddingBottom: "80px",
      }}
    >
      <CgSpinner
        icon="spinner"
        className="spinner"
        style={{ color: Colors.primaryColor }}
      />
    </div>
  );
};

export default ServiceItemDetail;

import React from "react";
import { Div, Row, Col, Text, Image } from "atomize";
import { LinearProgress } from "@material-ui/core";

import { NSButton } from "../../components/ServicePartner/Button";
import { InputText } from "../../components/ServicePartner/InputText";
import Colors from "../../Themes/Colors";
import { NSBanner } from "../../components/ServicePartner/Banner";
import { store } from "./context/store";
import {
  getOrSaveContactEmail,
  getOrSaveWhatsAppNumber,
  getOrSavePhoneNumberAsync,
} from "./Api/api";
import { validateEmail } from "./Validations";
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";
export const ContactDetails = (props) => {
  const [whatsAppNumber, setWhatsAppNumber] = React.useState(() => "");
  const [contactNumber, setContactNumber] = React.useState(() => "");
  const [dummyNumber, setDummyNumber] = React.useState(() => "");
  const [email, setEmail] = React.useState(() => "");
  //const [emailError, setEmailError] = React.useState(() => "");
  const [fetchDetailsApiStatus, setFetchDetailsApiStatus] = React.useState(
    () => ({
      error: "",
      loading: false,
    })
  );
  const [saveDetailsApiStatus, setSaveDetailsApiStatus] = React.useState(
    () => ({
      error: "",
      loading: false,
    })
  );

  const { state } = React.useContext(store);

  React.useEffect(() => {
    setFetchDetailsApiStatus({ error: "", loading: true });
    const payload = { party: state.partyID };
    function getAgencyDetails() {
      const whatsAppNumberResponse = getOrSaveWhatsAppNumber(payload);
      const emailResponse = getOrSaveContactEmail(payload);
      const phoneNumberResponse = getOrSavePhoneNumberAsync(payload);
      Promise.all([
        whatsAppNumberResponse,
        emailResponse,
        phoneNumberResponse,
      ]).then((responses) => {
        if (responses[0].isSuccess) {
          setWhatsAppNumber(responses[0].data[0]?.number);
        }
        if (responses[1].isSuccess) {
          setEmail(responses[1].data?.email);
        }
        if (responses[2].isSuccess && responses[2].data?.length) {
          setContactNumber(responses[2].data[0].phone);
          setWhatsAppNumber(responses[2].data[0].phone)
        }
        if (!responses[0].isSuccess || !responses[1].isSuccess) {
          setFetchDetailsApiStatus({
            error: "Unable to fetch details",
            loading: false,
          });
        }
        setFetchDetailsApiStatus({
          error: "",
          loading: false,
        });
      });
    }
    getAgencyDetails();
  }, [state.partyID]);

  const onSubmit = (e) => {
    e.preventDefault();
    setSaveDetailsApiStatus({
      error: "",
      loading: true,
    });
    if (!validateEmail(email)) {
      setSaveDetailsApiStatus({
        error: "Enter a valid email",
        loading: false,
      });
      return;
    }
    if (isNaN(whatsAppNumber) || whatsAppNumber.length !== 10) {
      setSaveDetailsApiStatus({
        error: "Please enter your 10 digit WhatsApp number.",
        loading: false,
      });
      return;
    }
    setSaveDetailsApiStatus({ error: "", loading: true });
    const whatsApppayload = {
      party: state.partyID,
      number: whatsAppNumber,
      country: 1,
    };
    const whatsAppNumberResponse = getOrSaveWhatsAppNumber(whatsApppayload);
    const emailPayload = {
      party: state.partyID,
      email: email,
    };
    const emailResponse = getOrSaveContactEmail(emailPayload);
    Promise.all([whatsAppNumberResponse, emailResponse]).then((responses) => {
      if (responses[0].isSuccess) {
        setWhatsAppNumber(responses[0].data?.number);
      } else {
        setSaveDetailsApiStatus({
          error: "WhatsApp number is already assigned with a different account.",
          loading: false,
        });
        return;
      }
      if (responses[1].isSuccess) {
        setEmail(responses[1].data?.email);
      } else {
        setSaveDetailsApiStatus({
          error: "Email address already existing.",
          loading: false,
        });
        return;
      }
      setSaveDetailsApiStatus({
        error: "",
        loading: false,
      });
      props.onClickNext();
    });
  };
  const onClickBack = () => {
    props.onClickBack();
  };

  const onChangeWhatsapp = (e) => {
    setWhatsAppNumber(e.currentTarget.value);
    setDummyNumber(e.currentTarget.value)
  };
  const onChangeContact = (e) => {
    setContactNumber(e.currentTarget.value);
  };
  const onChangeEmail = (e) => {
    setEmail(e.currentTarget.value);
  };

  if (fetchDetailsApiStatus.error) {
    return (
      <Text
        textAlign="center"
        textSize="title"
        textColor={"red"}
        m={{ t: "0.8em" }}
      >
        {fetchDetailsApiStatus.error}
      </Text>
    );
  }

  if (fetchDetailsApiStatus.loading) {
    return <LinearProgress />;
  }

  return (
    <Row>
      <Col size={12}>
        <NSBanner />
      </Col>
      <Col size={{ xs: 12, lg: 6 }} order={{ xs: 2, lg: 1 }}>
        <Div p={{ x: "1rem" }}>
          <Text
            textSize={{ xs: "heading", lg: "heading" }}
            textColor={Colors.servicePartnerText}
          >
            Contact Details
          </Text>
          <form onSubmit={onSubmit}>
            <Row>
              <Col size={12}>
                <InputText
                  value={contactNumber}
                  id="contactNumber"
                  placeholder="Mobile Number"
                  onChange={onChangeContact}
                  disabled={true}
                //   error={formik.touched.firstName && formik.errors.firstName}
                />
                <InputText
                  value={whatsAppNumber ? whatsAppNumber : dummyNumber}
                  id="whatsappNumber"
                  placeholder="WhatsApp Number"
                  onChange={onChangeWhatsapp}
                //onF
                // error={formik.touched.firstName && formik.errors.firstName}
                />
                <InputText
                  value={email}
                  id="email"
                  placeholder="E-Mail ID"
                  onChange={onChangeEmail}
                //   error={formik.touched.firstName && formik.errors.firstName}
                />
              </Col>
              <Col size={6}>
                <NSButton
                  style={{ width: "100%", borderRadius: "3px" }}
                  btntype="secondary"
                  iconposition="left"
                  // icon={<Icon name="LongLeft" size="16px" color="black" />}
                  minW="100%"
                  onClick={onClickBack}
                >
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div style={{ background: "#d5d8dc", padding: "7px", height: "40px", textAlign: "center", width: "33%", marginLeft: "-16px", borderRadius: "3px" }}> <ArrowLeftOutlined /> </div>
                    <div style={{ marginRight: "20px" }}>
                      Back</div>
                  </div>
                </NSButton>
              </Col>

              <Col size={6}>
                <NSButton
                  btntype="submit"
                  style={{ width: "100%", borderRadius: "3px" }}
                  // icon={<Icon name="LongRight" size="16px" color="white" />}
                  minW="100%"
                  loading={saveDetailsApiStatus.loading}
                >
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div style={{ marginLeft: "20px" }}>
                      {" "}
                      Next{" "}</div> <div style={{ background: "#3193ff", padding: "7px", height: "40px", textAlign: "center", width: "33%", marginRight: "-16px", borderRadius: "3px" }}> <ArrowRightOutlined /> </div>
                  </div>
                </NSButton>
              </Col>
              <Text textSize="caption" textColor={"red"} m={{ t: "0.8em" }}>
                {saveDetailsApiStatus.error}
              </Text>
            </Row>
          </form>
        </Div>
      </Col>
      <Col
        m={{ xs: 0, lg: { t: "-10em" } }}
        size={{ xs: 12, lg: 6 }}
        order={{ xs: 1, lg: 2 }}
      >
        <Div p="1rem">
          <Image src={require("../../assets/images/Frame66.png")} />
        </Div>
      </Col>
    </Row>
  );
};

export default {
  primaryColor: "#7CADF1",
  secondaryColor: "#303688",
  white: "#fff",
  lightGrey: "#f6f6f6",
  textMainColor: "#242424",
  textSecondaryColor: "#444445",
  orange: "#ee721f",
  yellow: "#ffc107",
  servicePartnerPrimary: '#2575EF',
  servicePartnerText: '#54595E',
  servicePartnerBtnGreen: '#DCF7E3',
  servicePartnerBtnGreenHover: "#B3F5C4",
  servicePartnerBtnGreenText: "#159697",
  servicePartnerGray: "gray",
  servicePartnerBanner: "#72A7F3"
};

import React, { createContext, useReducer } from "react";

const initialState = {
  selectedPolicy: null,
  selectedPolicyIdx: -1,
  selectedAsset: null,
  selectedLocation: "",
  selectedLocationId: null,
  selectedLocationPincode: null,
  selectedCompanies: [],
  selectedCompanyNames: [],
  selectedClaims: [],
  selectedClaimIndices: [],
  selectedClaimAssets: [],
  selectedServices: [],
  claimTypeAssetsArray: [],
  providerDetails: [],
  currentService: [],
  selectedHelps: [],
  nameOrder: 0,
  selectedPolicyName: "",
  selectedAssetName: [],
  selectedAvicerNames: [],
  selectedAssetNameInsurance: "",
  policyTypeReset: false,
  companiesCleared: false,
  backupAgents: [],
  backupAdvisors: [],
  policyTypes: [],
  claimTypes: [],
  prevSearchStr: "",
  relatedLocations: []
};
const store = createContext(initialState);
const { Provider } = store;

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state = initialState, action) => {
    //console.log("From Dispatch ", action.type, action.payload);
    switch (action.type) {
      case "selectedPolicy":
        return {
          ...state,
          selectedPolicy: action.payload,
        };
      case "selectedPolicyIdx":
        return {
          ...state,
          selectedPolicyIdx: action.payload,
        };
      case "selectedAsset":
        return {
          ...state,
          selectedAsset: action.payload,
        };
      case "selectedLocation":
        return {
          ...state,
          selectedLocation: action.payload,
        };
      case "selectedLocationId":
        return {
          ...state,
          selectedLocationId: action.payload,
        };
      case "selectedLocationPincode":
        return {
          ...state,
          selectedLocationPincode: action.payload,
        };
      case "selectedCompanies":
        return {
          ...state,
          selectedCompanies: action.payload,
        };
      case "selectedCompanyNames":
        return {
          ...state,
          selectedCompanyNames: action.payload,
        };
      case "selectedClaims":
        return {
          ...state,
          selectedClaims: action.payload,
        };
      case "selectedClaimAssets":
        return {
          ...state,
          selectedClaimAssets: action.payload,
        };
      case "selectedServices":
        return {
          ...state,
          selectedServices: action.payload,
        };
      case "claimTypeAssetsArray":
        return {
          ...state,
          claimTypeAssetsArray: action.payload,
        };
      case "providerDetails":
        return {
          ...state,
          providerDetails: action.payload,
        };
      case "currentService":
        return {
          ...state,
          currentService: action.payload,
        };
      case "selectedHelps":
        return {
          ...state,
          selectedHelps: action.payload,
        };
      case "nameOrder":
        return {
          ...state,
          nameOrder: action.payload,
        };
      case "selectedPolicyName":
        return {
          ...state,
          selectedPolicyName: action.payload,
        };
      case "selectedAssetName":
        return {
          ...state,
          selectedAssetName: action.payload,
        };
      case "selectedAvicerNames":
        return {
          ...state,
          selectedAvicerNames: action.payload,
        };
      case "selectedAdicerAssets":
        return {
          ...state,
          selectedAdicerAssets: action.payload,
        };
      case "selectedHelpNames":
        return {
          ...state,
          selectedHelpNames: action.payload,
        };
      case "selectedAssetNameInsurance":
        return {
          ...state,
          selectedAssetNameInsurance: action.payload,
        };
      case "policyTypeReset":
        return {
          ...state,
          policyTypeReset: action.payload,
        };
      case "companiesCleared":
        return {
          ...state,
          companiesCleared: action.payload,
        };
      case "backupAgents":
        return {
          ...state,
          backupAgents: action.payload,
        };
      case "backupAdvisors":
        return {
          ...state,
          backupAdvisors: action.payload,
        };
      case "policyTypes":
        return {
          ...state,
          policyTypes: action.payload,
        };
      case "claimTypes":
        return {
          ...state,
          claimTypes: action.payload,
        };
      case "prevSearchStr":
        return {
          ...state,
          prevSearchStr: action.payload,
        };
      case "relatedLocations":
        return {
          ...state,
          relatedLocations: action.payload,
        };
      default:
        throw new Error();
    }
  }, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, StateProvider };

// import React, { useState, useEffect } from "react";
import React from "react";
import { Div, Icon } from "atomize";
import { Slider, Label } from "../../components";
import Colors from "../../Themes/Colors";
import ContactSection from "./ContactSection/ContactSection";
import { BrowserView } from "react-device-detect";
import ToolbarForForm from "../../components/Toolbar/ToolbarForForm";

const images = [
  {
    image: require("../../assets/images/sec4.jpg"),
    label2: "Corporate Solutions",
  },
];
const CorporateSolutions = () => {
  return (
    <>
      <ToolbarForForm />
      <BrowserView>
        <Div className="screen-padding" p={{ y: "20px" }}>
          <Div m={{ y: "20px" }}>
            <Slider images={images} />
          </Div>
        </Div>
      </BrowserView>
      <Div className="screen-padding">
        <Div p={{ x: { xs: "25px", sm: "25px", md: "55px" }, t: "20px" }}>
          <Label
            label="Services"
            type="bodyText3"
            style={{ textSize: "25px", fontFamily: "Montserrat-Regular" }}
          />
          <Div d="flex" align="flex-start" m={{ y: "40px" }}>
            <Icon name="Status" color={Colors.primaryColor} size="20px" />
            <Label
              label="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sociis natoque penatibus et magnis dis. Proin fermentum leo vel orci porta non pulvinar neque. Ultrices mi tempus imperdiet nulla. Diam donec adipiscing tristique risus. Felis imperdiet proin fermentum leo vel orci porta non. Nisi quis eleifend quam adipiscing. Adipiscing bibendum est ultricies integer quis"
              type="bodyText3"
              style={{ m: { t: "-3px", l: "10px" } }}
            />
          </Div>
          <Div d="flex" align="flex-start" m={{ y: "40px" }}>
            <Icon name="Status" color={Colors.primaryColor} size="20px" />
            <Label
              label="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sociis natoque penatibus et magnis dis. Proin fermentum leo vel orci porta non pulvinar neque. Ultrices mi tempus imperdiet nulla. Diam donec adipiscing tristique risus. Felis imperdiet proin fermentum leo vel orci porta non. Nisi quis eleifend quam adipiscing. Adipiscing bibendum est ultricies integer quis"
              type="bodyText3"
              style={{ m: { t: "-3px", l: "10px" } }}
            />
          </Div>
        </Div>
        <ContactSection />
      </Div>

    </>
  );
};

export default CorporateSolutions;
